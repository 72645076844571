import React from "react";
import { Typography, TextField } from "@material-ui/core";

export default function Assinatura(props) {
  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Assinatura e carimbo do responsável:
      </Typography>
      <div className="row">
        <div className="col-md-12">
          <TextField
            fullWidth
            margin="none"
            variant="outlined"
            multiline
            rows={6}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
