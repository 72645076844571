/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { object, string, number, boolean } from "yup";

import leftDominance from "./../../../assets/img/LeftDominance.png";
import rightDominance from "./../../../assets/img/RightDominance.png";

export default function CateterismoDialog({
  open,
  data,
  onInsert,
  onUpdate,
  onClose,
  congelado,
}) {
  const [errors, setErrors] = useState(null);
  const [id, setId] = useState(null);
  const [localOptions, setLocalOptions] = useState([]);
  const [local, setLocal] = useState("");
  const [ladoOptions, setLadoOptions] = useState([]);
  const [lado, setLado] = useState("");
  const [porcentagem, setPorcentagem] = useState(null);
  const [excentrica, setExcentrica] = useState(false);
  const [longaMaior20, setLongaMaior20] = useState(false);
  const [calcificada, setCalcificada] = useState(false);
  const [ulcerada, setUlcerada] = useState(false);
  const [trombo, setTrombo] = useState(false);
  const [bifurcacao, setBifurcacao] = useState(false);
  const [medina, setMedina] = useState("");
  const [trifurcacao, setTrifurcacao] = useState(false);
  const [anguloRamoLateral, setAnguloRamoLateral] = useState(null);
  const [tortuosidade, setTortuosidade] = useState(false);
  const [oclusaoCronica, setOclusaoCronica] = useState(false);
  const [fluxoTimi, setFluxoTimi] = useState(null);
  const [capaProximalAmbigua, setCapaProximalAmbigua] = useState(false);
  const [comprimentoMaior20, setComprimentoMaior20] = useState(false);
  const [tortuosidadeMaior45, setTortuosidadeMaior45] = useState(false);
  const [leitoDistalDoente, setLeitoDistalDoente] = useState(false);
  const [calcificacao, setCalcificacao] = useState(false);
  const [tentativaPrevia, setTentativaPrevia] = useState(false);
  const [posDilatacao, setPosDilatacao] = useState(false);
  const [tecnicaBifurcacaoOptions, setTecnicaBifurcacaoOptions] = useState([]);
  const [tecnicaBifurcacao, setTecnicaBifurcacao] = useState("");
  const [outraTecnicaBifurcacao, setOutraTecnicaBifurcacao] = useState("");
  const [pot, setPot] = useState(false);
  const [
    impossibilidadeCruzarRamoLateral,
    setImpossibilidadeCruzarRamoLateral,
  ] = useState(false);
  const [complicacaoOptions, setComplicacaoOptions] = useState([]);
  const [complicacao, setComplicacao] = useState("");
  const [descrevaComplicacao, setDescrevaComplicacao] = useState("");

  const validateSchema = object({
    lado: string().required(),
    local: string().required(),
    porcentagem: number().nullable(),
    excentrica: boolean().required(),
    longaMaior20: boolean().required(),
    calcificada: boolean().required(),
    ulcerada: boolean().required(),
    trombo: boolean().required(),
    bifurcacao: boolean().required(),
    medina: string().nullable(),
    trifurcacao: boolean().required(),
    anguloRamoLateral: number()
      .integer()
      .nullable(),
    tortuosidade: boolean().required(),
    oclusaoCronica: boolean().required(),
    fluxoTimi: number()
      .integer()
      .nullable(),
    capaProximalAmbigua: string().nullable(),
    comprimentoMaior20: string().nullable(),
    tortuosidadeMaior45: string().nullable(),
    leitoDistalDoente: string().nullable(),
    calcificacao: string().nullable(),
    tentativaPrevia: string().nullable(),
    posDilatacao: string().nullable(),
    tecnicaBifurcacao: string().when("bifurcacao", {
      is: true,
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    }),
    outraTecnicaBifurcacao: string().when("tecnicaBifurcacao", {
      is: tecnicaBifurcacao => tecnicaBifurcacao && tecnicaBifurcacao === "10",
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    }),
    pot: string().nullable(),
    impossibilidadeCruzarRamoLateral: boolean().required(),
    complicacao: string().when("bifurcacao", {
      is: true,
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    }),
    descrevaComplicacao: string().when("complicacao", {
      is: complicacao => complicacao && complicacao === "7",
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    }),
  });

  const handleConfirm = type => {
    const cateterismo = {
      id: id,
      lado: lado,
      local: local,
      porcentagem: porcentagem,
      excentrica: excentrica,
      longaMaior20: longaMaior20,
      calcificada: calcificada,
      ulcerada: ulcerada,
      trombo: trombo,
      bifurcacao: bifurcacao,
      medina: medina,
      trifurcacao: trifurcacao,
      anguloRamoLateral: anguloRamoLateral,
      tortuosidade: tortuosidade,
      oclusaoCronica: oclusaoCronica,
      fluxoTimi: fluxoTimi,
      capaProximalAmbigua: capaProximalAmbigua,
      comprimentoMaior20: comprimentoMaior20,
      tortuosidadeMaior45: tortuosidadeMaior45,
      leitoDistalDoente: leitoDistalDoente,
      calcificacao: calcificacao,
      tentativaPrevia: tentativaPrevia,
      posDilatacao: posDilatacao,
      tecnicaBifurcacao: tecnicaBifurcacao,
      outraTecnicaBifurcacao: outraTecnicaBifurcacao,
      pot: pot,
      impossibilidadeCruzarRamoLateral: impossibilidadeCruzarRamoLateral,
      complicacao: complicacao,
      descrevaComplicacao: descrevaComplicacao,
    };

    validateSchema
      .validate(cateterismo, { abortEarly: false })
      .then(() => {
        if (type === "insert") {
          onInsert(cateterismo);
        } else if (type === "update") {
          onUpdate(cateterismo);
        }
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  useEffect(() => {
    setErrors([]);
    setId(data?.id || uuidv4());
    setLado(data?.lado || "");
    setLocal(data?.local || "");
    setPorcentagem(data?.porcentagem || null);
    setExcentrica(data?.excentrica || false);
    setLongaMaior20(data?.longaMaior20 || false);
    setCalcificada(data?.calcificada || false);
    setUlcerada(data?.ulcerada || false);
    setTrombo(data?.trombo || false);
    setBifurcacao(data?.bifurcacao || false);
    setMedina(data?.medina || "");
    setTrifurcacao(data?.trifurcacao || false);
    setAnguloRamoLateral(data?.anguloRamoLateral || null);
    setTortuosidade(data?.tortuosidade || false);
    setOclusaoCronica(data?.oclusaoCronica || false);
    setFluxoTimi(data?.fluxoTimi || null);
    setCapaProximalAmbigua(data?.capaProximalAmbigua || false);
    setComprimentoMaior20(data?.comprimentoMaior20 || false);
    setTortuosidadeMaior45(data?.tortuosidadeMaior45 || false);
    setLeitoDistalDoente(data?.leitoDistalDoente || false);
    setCalcificacao(data?.calcificacao || false);
    setTentativaPrevia(data?.tentativaPrevia || false);
    setPosDilatacao(data?.posDilatacao || false);
    setTecnicaBifurcacao(data?.tecnicaBifurcacao || "");
    setOutraTecnicaBifurcacao(data?.outraTecnicaBifurcacao || "");
    setPot(data?.pot || false);
    setImpossibilidadeCruzarRamoLateral(
      data?.impossibilidadeCruzarRamoLateral || false,
    );
    setComplicacao(data?.complicacao || "");
    setDescrevaComplicacao(data?.descrevaComplicacao || "");
  }, [data, open]);

  useEffect(() => {
    setLocalOptions([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "9a",
      "10",
      "10a",
      "11",
      "12",
      "12a",
      "12b",
      "13",
      "14",
      "14a",
      "14b",
      "15",
      "16",
      "16a",
      "16b",
      "16c",
    ]);

    setLadoOptions([
      { value: "D", label: "Direito" },
      { value: "E", label: "Esquerdo" },
    ]);

    setTecnicaBifurcacaoOptions([
      { value: 1, label: "Provisional apenas 1 stent e balão" },
      { value: 2, label: "2 stents Bailout" },
      { value: 3, label: "Crush" },
      { value: 4, label: "Mini crush" },
      { value: 5, label: "Culotte" },
      { value: 6, label: "T stent" },
      { value: 7, label: "TAP" },
      { value: 8, label: "DK crush" },
      { value: 9, label: "V stent" },
      { value: 10, label: "Outra" },
    ]);

    setComplicacaoOptions([
      { value: 1, label: "Não houve complicação" },
      { value: 2, label: "Dissecção" },
      { value: 3, label: "Perfuração" },
      { value: 4, label: "Slow Reflow" },
      { value: 5, label: "No Reflow" },
      { value: 6, label: "Oclusão de Ramo Lateral" },
      { value: 7, label: "Outra" },
    ]);
  }, []);

  const validateIfHasError = (fieldName, value) => {
    if (errors?.[fieldName]) {
      validateSchema.validateAt(fieldName, { [fieldName]: value }).then(() => {
        const newErrors = errors;
        delete newErrors[fieldName];

        setErrors(newErrors);
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      scroll="body"
    >
      <DialogTitle>Adicionar cateterismo</DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-6">
            <img
              src={leftDominance}
              alt="Domínio esquerdo"
              className="rounded mx-auto d-block"
            />
          </div>
          <div className="col-md-6">
            <img
              src={rightDominance}
              alt="Domínio direito"
              className="rounded mx-auto d-block"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              select
              required
              label="Lado"
              value={lado}
              margin="normal"
              fullWidth
              disabled={congelado}
              size="small"
              onChange={event => {
                validateIfHasError("lado", event.target.value);
                setLado(event.target.value);
              }}
              error={errors?.lado ? true : false}
              helperText={errors?.lado}
            >
              {ladoOptions.map((lado, i) => {
                return (
                  <MenuItem key={i} value={lado.value}>
                    {lado.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="col-md-4">
            <TextField
              select
              required
              label="Vaso"
              value={local}
              margin="normal"
              fullWidth
              disabled={congelado}
              size="small"
              onChange={event => {
                validateIfHasError("local", event.target.value);
                setLocal(event.target.value);
              }}
              error={errors?.local ? true : false}
              helperText={errors?.local}
            >
              {localOptions.map((local, i) => {
                return (
                  <MenuItem key={i} value={local}>
                    {local}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="col-md-4">
            <TextField
              label="Porcentagem"
              margin="normal"
              type="number"
              defaultValue={porcentagem}
              fullWidth
              disabled={congelado}
              size="small"
              onChange={event => {
                validateIfHasError("porcentagem", event.target.value);
                setPorcentagem(event.target.value);
              }}
              error={errors?.porcentagem ? true : false}
              helperText={errors?.porcentagem}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Ângulo ramo lateral"
              type="number"
              margin="normal"
              defaultValue={anguloRamoLateral}
              fullWidth
              disabled={congelado}
              size="small"
              onChange={event => {
                validateIfHasError("anguloRamoLateral", event.target.value);
                setAnguloRamoLateral(event.target.value);
              }}
              error={errors?.anguloRamoLateral ? true : false}
              helperText={errors?.anguloRamoLateral}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Medina"
              margin="normal"
              fullWidth
              disabled={congelado}
              value={medina}
              size="small"
              onChange={event => {
                validateIfHasError("medina", event.target.value);
                setMedina(event.target.value);
              }}
              error={errors?.medina ? true : false}
              helperText={errors?.medina}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Fluxo TIMI"
              type="number"
              margin="normal"
              disabled={congelado}
              defaultValue={fluxoTimi}
              fullWidth
              size="small"
              onChange={event => {
                validateIfHasError("fluxoTimi", event.target.value);
                setFluxoTimi(event.target.value);
              }}
              error={errors?.fluxoTimi ? true : false}
              helperText={errors?.fluxoTimi}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={longaMaior20}
                  color="primary"
                  onChange={event => {
                    setLongaMaior20(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Longa > 20"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={calcificada}
                  color="primary"
                  onChange={event => {
                    setCalcificada(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Calcificada"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={ulcerada}
                  color="primary"
                  onChange={event => {
                    setUlcerada(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Ulcerada"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={trombo}
                  color="primary"
                  onChange={event => {
                    setTrombo(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Trombo"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={oclusaoCronica}
                  color="primary"
                  onChange={event => {
                    setOclusaoCronica(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Oclusão crônica"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={bifurcacao}
                  color="primary"
                  onChange={event => {
                    setBifurcacao(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Bifurcação"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={trifurcacao}
                  color="primary"
                  onChange={event => {
                    setTrifurcacao(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Trifurcação"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={tortuosidade}
                  color="primary"
                  onChange={event => {
                    setTortuosidade(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Tortuosidade"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <Switch
                  checked={excentrica}
                  color="primary"
                  onChange={event => {
                    setExcentrica(event.target.checked);
                  }}
                  disabled={congelado}
                />
              }
              labelPlacement="end"
              label="Excêntrica"
            />
          </div>
        </div>
        {oclusaoCronica && (
          <>
            <Typography gutterBottom variant="h6" className="mt-10 mb-2">
              Classificação J-CTO
            </Typography>

            <div className="row">
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={capaProximalAmbigua}
                      color="primary"
                      onChange={event => {
                        setCapaProximalAmbigua(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Capa proximal ambígua"
                />
              </div>
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={comprimentoMaior20}
                      color="primary"
                      onChange={event => {
                        setComprimentoMaior20(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Comprimento maior que 20mm"
                />
              </div>
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={tortuosidadeMaior45}
                      color="primary"
                      onChange={event => {
                        setTortuosidadeMaior45(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Tortuosidade > 45°"
                />
              </div>
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={leitoDistalDoente}
                      color="primary"
                      onChange={event => {
                        setLeitoDistalDoente(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Leito distal doente"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={calcificacao}
                      color="primary"
                      onChange={event => {
                        setCalcificacao(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Calcificação"
                />
              </div>
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={tentativaPrevia}
                      color="primary"
                      onChange={event => {
                        setTentativaPrevia(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Tentativa prévia"
                />
              </div>
              <div className="col-md-3 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={posDilatacao}
                      color="primary"
                      onChange={event => {
                        setPosDilatacao(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Pós-Dilatação"
                />
              </div>
            </div>
          </>
        )}
        {bifurcacao && (
          <>
            <Typography gutterBottom variant="h6" className="mt-10 mb-2">
              Técnica de bifurcação
            </Typography>
            <div className="row">
              <div className="col-md-4 py-2">
                <TextField
                  select
                  label="Técnica bifurcação"
                  value={tecnicaBifurcacao}
                  margin="normal"
                  required
                  disabled={congelado}
                  fullWidth
                  size="small"
                  onChange={event => {
                    validateIfHasError("tecnicaBifurcacao", event.target.value);
                    setTecnicaBifurcacao(event.target.value);
                  }}
                  error={errors?.tecnicaBifurcacao ? true : false}
                  helperText={errors?.tecnicaBifurcacao}
                >
                  {tecnicaBifurcacaoOptions.map((tecnicaBifurcacao, i) => {
                    return (
                      <MenuItem key={i} value={tecnicaBifurcacao.value}>
                        {tecnicaBifurcacao.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
              <div className="col-md-4 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={pot}
                      color="primary"
                      onChange={event => {
                        setPot(event.target.checked);
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="POT"
                />
              </div>
              <div className="col-md-4 py-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={impossibilidadeCruzarRamoLateral}
                      color="primary"
                      onChange={event => {
                        setImpossibilidadeCruzarRamoLateral(
                          event.target.checked,
                        );
                      }}
                      disabled={congelado}
                    />
                  }
                  labelPlacement="end"
                  label="Houve impossibilidade de cruzar o guia/balão/stent para o ramo lateral?"
                />
              </div>
            </div>
            {tecnicaBifurcacao === 10 && (
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    label="Descreva a técnica"
                    margin="normal"
                    fullWidth
                    disabled={congelado}
                    multiline
                    rows={4}
                    required
                    value={outraTecnicaBifurcacao}
                    size="small"
                    variant="outlined"
                    onChange={event => {
                      validateIfHasError(
                        "outraTecnicaBifurcacao",
                        event.target.value,
                      );
                      setOutraTecnicaBifurcacao(event.target.value);
                    }}
                    error={errors?.outraTecnicaBifurcacao ? true : false}
                    helperText={errors?.outraTecnicaBifurcacao}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <TextField
                  select
                  label="Complicação"
                  value={complicacao}
                  required
                  disabled={congelado}
                  margin="normal"
                  fullWidth
                  size="small"
                  onChange={event => {
                    validateIfHasError("complicacao", event.target.value);
                    setComplicacao(event.target.value);
                  }}
                  error={errors?.complicacao ? true : false}
                  helperText={errors?.complicacao}
                >
                  {complicacaoOptions.map((complicacao, i) => {
                    return (
                      <MenuItem key={i} value={complicacao.value}>
                        {complicacao.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
            {complicacao === 7 && (
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    label="Descreva a complicação"
                    margin="normal"
                    fullWidth
                    multiline
                    disabled={congelado}
                    value={descrevaComplicacao}
                    rows={4}
                    size="small"
                    variant="outlined"
                    onChange={event => {
                      validateIfHasError(
                        "descrevaComplicacao",
                        event.target.value,
                      );
                      setDescrevaComplicacao(event.target.value);
                    }}
                    error={errors?.descrevaComplicacao ? true : false}
                    helperText={errors?.descrevaComplicacao}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          {congelado ? `Fechar` : `Cancelar`}
        </Button>
        {data ? (
          <Button
            onClick={() => {
              handleConfirm("update");
            }}
            variant="contained"
            color="primary"
            disabled={congelado}
          >
            Atualizar
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleConfirm("insert");
            }}
            variant="contained"
            color="primary"
          >
            Inserir
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
