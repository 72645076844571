import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import FastField from "../../../components/FastField";

export default function Atividades(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Atividades
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Descrição da atividade</TableCell>
                  <TableCell>Horário</TableCell>
                  <TableCell>Observações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Horário da senha no pronto socorro</TableCell>
                  <TableCell>
                    <FastField
                      name="horarioSenhaPs"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsSenhaPs"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Horário do ECG</TableCell>
                  <TableCell>
                    <FastField
                      name="horarioEcg"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsEcg"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Horário do acionamento da equipe de hemodinâmica
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="horarioEquipeHemodinamica"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsEquipeHemodinamica"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Horário da chegada dos técnicos de enfermagem na HMD
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="horarioChegadaTecnicos"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsChegadaTecnicos"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Horário da chegada do médico hemodinamicista
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="horarioChegadaMedico"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsChegadaMedico"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Horário da chegada do paciente na hemodinâmica
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="horarioChegadaPaciente"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsChegadaPaciente"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Horário da inserção do balão</TableCell>
                  <TableCell>
                    <FastField
                      name="horarioInsercaoBalao"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsInsercaoBalao"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Horário do término do procedimento</TableCell>
                  <TableCell>
                    <FastField
                      name="horarioTerminoProc"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsTerminoProc"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Horário da saída do paciente da hemodinâmica
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="horarioSaidaPaciente"
                      disabled={formCongelado}
                      type="time"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FastField
                      name="obsSaidaPaciente"
                      disabled={formCongelado}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
