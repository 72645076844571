import React from "react";
import { Switch } from "@material-ui/core";
import { useField } from "formik";

export default function SwitchField(props) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props.name);

  return (
    <Switch
      {...props}
      checked={meta.value}
      onChange={(_, value) => helpers.setValue(value)}
    />
  );
}
