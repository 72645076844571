import React from "react";
import { Typography } from "@material-ui/core";

import FastField from "../../../components/FastField";

export default function Anotacoes(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Anotações
      </Typography>
      <div className="row">
        <div className="col-md-12">
          <FastField
            name="intercorrenciasEnfermagem"
            disabled={formCongelado}
            label="Anotação de enfermagem / intercorrências no intra operatório"
            fullWidth
            margin="none"
            variant="outlined"
            multiline
            rows={6}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
