import React from "react";
import { TextField, Typography, MenuItem } from "@material-ui/core";

import FastField from "../../../components/FastField";
import MmhgFastField from "../../../components/MmhgFastField";

export default function SinaisVitaisTrans(props) {
  const { formCongelado } = props;
  const {
    transNivelConscienciaSinaisVitais,
    transDorSinaisVitais,
  } = props.formik.values;
  const { setFieldValue } = props.formik;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Registro de sinais vitais
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FastField
            name="transHoraSinaisVitais"
            disabled={formCongelado}
            label="Hora"
            type="time"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="transFcSinaisVitais"
            disabled={formCongelado}
            label="FC"
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="transFrSinaisVitais"
            disabled={formCongelado}
            label="FR"
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="transTaxSinaisVitais"
            disabled={formCongelado}
            label="TAX"
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <MmhgFastField
            name="transPaSinaisVitais"
            disabled={formCongelado}
            label="PA"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="transSatSinaisVitais"
            disabled={formCongelado}
            label="SAT"
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <TextField
            select
            label="DOR"
            name="transDorSinaisVitais"
            disabled={formCongelado}
            value={transDorSinaisVitais}
            onChange={event => {
              setFieldValue("transDorSinaisVitais", event.target.value);
            }}
            fullWidth
            margin="normal"
            size="small"
            variant="outlined"
          >
            {Array.from({ length: 11 }, (_, i) => i).map(i => {
              return (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div className="col-md-3">
          <TextField
            select
            label="Nível de consciência"
            name="transNivelConscienciaSinaisVitais"
            disabled={formCongelado}
            value={transNivelConscienciaSinaisVitais}
            onChange={event => {
              setFieldValue(
                "transNivelConscienciaSinaisVitais",
                event.target.value,
              );
            }}
            fullWidth
            margin="normal"
            size="small"
            variant="outlined"
          >
            <MenuItem key={1} value="1">
              Alerta
            </MenuItem>
            <MenuItem key={2} value="2">
              Resposta ao estímulo verbal
            </MenuItem>
            <MenuItem key={3} value="3">
              Resposta ao estímulo doloroso
            </MenuItem>
            <MenuItem key={4} value="4">
              Inconsciente
            </MenuItem>
          </TextField>
        </div>
      </div>
    </div>
  );
}
