import React from "react";
import {
  colors,
  makeStyles,
  IconButton,
  SnackbarContent,
  Snackbar,
} from "@material-ui/core";
import { CheckCircle, Warning, Error, Info, Close } from "@material-ui/icons";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import * as snackbarRedux from "../../redux/snackbarRedux";

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: colors.green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: colors.amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

export default function CustomSnackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { message, open, type } = useSelector(state => state.snackbar);

  const Icon = variantIcon[type];

  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      snackbarRedux.actions.pushSnackbar({
        open: false,
      })
    );
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={clsx(classes[type], classes.margin)}
          aria-describedby="snackbar"
          message={
            <span id="snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <Close className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </>
  );
}
