/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  FormControlLabel,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import moment from "moment";

import FastField from "../../../components/FastField";
import SwitchField from "../../../components/SwitchField";

export default function AcompanhamentoPosAtc(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const {
    posAtcData30,
    posAtcMedicacoesEmUso30,
    posAtcApresentaAlgumSintoma30,
    posAtcBuscouAtendimentoUrgencia30,
    posAtcBuscouAtendimentoUrgenciaComplicacoes30,
    posAtcBuscouAtendimentoUrgenciaPrecisouInternar30,
    posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao30,
    posAtcRetornouComMedicoAssistente30,
    posAtcRealizouExame30,
    posAtcEletrocardiogramaAlteracao30,
    posAtcPacienteTemAlgumaDuvida30,
    posAtcMarcadoRetorno30,

    posAtcData90,
    posAtcMedicacoesEmUso90,
    posAtcApresentaAlgumSintoma90,
    posAtcBuscouAtendimentoUrgencia90,
    posAtcBuscouAtendimentoUrgenciaComplicacoes90,
    posAtcBuscouAtendimentoUrgenciaPrecisouInternar90,
    posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao90,
    posAtcRetornouComMedicoAssistente90,
    posAtcRealizouExame90,
    posAtcEletrocardiogramaAlteracao90,
    posAtcPacienteTemAlgumaDuvida90,
    posAtcMarcadoRetorno90,

    posAtcData180,
    posAtcMedicacoesEmUso180,
    posAtcApresentaAlgumSintoma180,
    posAtcBuscouAtendimentoUrgencia180,
    posAtcBuscouAtendimentoUrgenciaComplicacoes180,
    posAtcBuscouAtendimentoUrgenciaPrecisouInternar180,
    posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao180,
    posAtcRetornouComMedicoAssistente180,
    posAtcRealizouExame180,
    posAtcEletrocardiogramaAlteracao180,
    posAtcPacienteTemAlgumaDuvida180,
    posAtcMarcadoRetorno180,

    posAtcData360,
    posAtcMedicacoesEmUso360,
    posAtcApresentaAlgumSintoma360,
    posAtcBuscouAtendimentoUrgencia360,
    posAtcBuscouAtendimentoUrgenciaComplicacoes360,
    posAtcBuscouAtendimentoUrgenciaPrecisouInternar360,
    posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao360,
    posAtcRetornouComMedicoAssistente360,
    posAtcRealizouExame360,
    posAtcEletrocardiogramaAlteracao360,
    posAtcPacienteTemAlgumaDuvida360,
    posAtcMarcadoRetorno360,
  } = props.formik.values;
  const [activeTab, setActiveTab] = useState(30);

  return (
    <>
      <div className="card-header card-header-tabs-line">
        <ul
          className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          data-remember-tab="tab_id"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 30 ? "active" : ""}`}
              data-toggle="tab"
              onClick={() => {
                setActiveTab(30);
              }}
            >
              Pós 30 dias
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 90 ? "active" : ""}`}
              data-toggle="tab"
              onClick={() => {
                setActiveTab(90);
              }}
            >
              Pós 90 dias
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 180 ? "active" : ""}`}
              data-toggle="tab"
              onClick={() => {
                setActiveTab(180);
              }}
            >
              Pós 180 dias
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 360 ? "active" : ""}`}
              data-toggle="tab"
              onClick={() => {
                setActiveTab(360);
              }}
            >
              Pós 360 dias
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content pt-3">
        <div className={`tab-pane ${activeTab === 30 ? "active" : ""}`}>
          <Content
            posAtcData={posAtcData30}
            formCongelado={formCongelado}
            posAtcMedicacoesEmUso={posAtcMedicacoesEmUso30}
            posAtcApresentaAlgumSintoma={posAtcApresentaAlgumSintoma30}
            posAtcBuscouAtendimentoUrgencia={posAtcBuscouAtendimentoUrgencia30}
            posAtcBuscouAtendimentoUrgenciaComplicacoes={
              posAtcBuscouAtendimentoUrgenciaComplicacoes30
            }
            setFieldValue={setFieldValue}
            posAtcBuscouAtendimentoUrgenciaPrecisouInternar={
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar30
            }
            posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao={
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao30
            }
            posAtcRetornouComMedicoAssistente={
              posAtcRetornouComMedicoAssistente30
            }
            posAtcRealizouExame={posAtcRealizouExame30}
            posAtcEletrocardiogramaAlteracao={
              posAtcEletrocardiogramaAlteracao30
            }
            posAtcPacienteTemAlgumaDuvida={posAtcPacienteTemAlgumaDuvida30}
            posAtcMarcadoRetorno={posAtcMarcadoRetorno30}
            names={{
              posAtcData: "posAtcData30",
              posAtcMedico: "posAtcMedico30",
              posAtcMedicacoesEmUso: "posAtcMedicacoesEmUso30",
              posAtcMedicacoesEmUsoClopdogrel75m:
                "posAtcMedicacoesEmUsoClopdogrel75m30",
              posAtcMedicacoesEmUsoAas: "posAtcMedicacoesEmUsoAas30",
              posAtcMedicacoesEmUsoTicagrelor:
                "posAtcMedicacoesEmUsoTicagrelor30",
              posAtcMedicacoesEmUsoRosuvastatina:
                "posAtcMedicacoesEmUsoRosuvastatina30",
              posAtcMedicacoesEmUsoAtorvastatina:
                "posAtcMedicacoesEmUsoAtorvastatina30",
              posAtcApresentaAlgumSintoma: "posAtcApresentaAlgumSintoma30",
              posAtcApresentaAlgumSintomaQuais:
                "posAtcApresentaAlgumSintomaQuais30",
              posAtcBuscouAtendimentoUrgencia:
                "posAtcBuscouAtendimentoUrgencia30",
              posAtcBuscouAtendimentoUrgenciaComplicacoes:
                "posAtcBuscouAtendimentoUrgenciaComplicacoes30",
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar:
                "posAtcBuscouAtendimentoUrgenciaPrecisouInternar30",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao30",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate30",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc30",
              posAtcRetornouComMedicoAssistente:
                "posAtcRetornouComMedicoAssistente30",
              posAtcRetornouComMedicoAssistenteNomeMedico:
                "posAtcRetornouComMedicoAssistenteNomeMedico30",
              posAtcRealizouExame: "posAtcRealizouExame30",
              posAtcRealizouExameQual: "posAtcRealizouExameQual30",
              posAtcEletrocardiogramaAlteracao:
                "posAtcEletrocardiogramaAlteracao30",
              posAtcEletrocardiogramaAlteracaoQuais:
                "posAtcEletrocardiogramaAlteracaoQuais30",
              posAtcExameFisico: "posAtcExameFisico30",
              posAtcPacienteTemAlgumaDuvida: "posAtcPacienteTemAlgumaDuvida30",
              posAtcPacienteTemAlgumaDuvidaQuais:
                "posAtcPacienteTemAlgumaDuvidaQuais30",
              posAtcExameObservacoesGerais: "posAtcExameObservacoesGerais30",
              posAtcMarcadoRetorno: "posAtcMarcadoRetorno30",
            }}
          />
        </div>
        <div className={`tab-pane ${activeTab === 90 ? "active" : ""}`}>
          <Content
            posAtcData={posAtcData90}
            formCongelado={formCongelado}
            posAtcMedicacoesEmUso={posAtcMedicacoesEmUso90}
            posAtcApresentaAlgumSintoma={posAtcApresentaAlgumSintoma90}
            posAtcBuscouAtendimentoUrgencia={posAtcBuscouAtendimentoUrgencia90}
            posAtcBuscouAtendimentoUrgenciaComplicacoes={
              posAtcBuscouAtendimentoUrgenciaComplicacoes90
            }
            setFieldValue={setFieldValue}
            posAtcBuscouAtendimentoUrgenciaPrecisouInternar={
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar90
            }
            posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao={
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao90
            }
            posAtcRetornouComMedicoAssistente={
              posAtcRetornouComMedicoAssistente90
            }
            posAtcRealizouExame={posAtcRealizouExame90}
            posAtcEletrocardiogramaAlteracao={
              posAtcEletrocardiogramaAlteracao90
            }
            posAtcPacienteTemAlgumaDuvida={posAtcPacienteTemAlgumaDuvida90}
            posAtcMarcadoRetorno={posAtcMarcadoRetorno90}
            names={{
              posAtcData: "posAtcData90",
              posAtcMedico: "posAtcMedico90",
              posAtcMedicacoesEmUso: "posAtcMedicacoesEmUso90",
              posAtcMedicacoesEmUsoClopdogrel75m:
                "posAtcMedicacoesEmUsoClopdogrel75m90",
              posAtcMedicacoesEmUsoAas: "posAtcMedicacoesEmUsoAas90",
              posAtcMedicacoesEmUsoTicagrelor:
                "posAtcMedicacoesEmUsoTicagrelor90",
              posAtcMedicacoesEmUsoRosuvastatina:
                "posAtcMedicacoesEmUsoRosuvastatina90",
              posAtcMedicacoesEmUsoAtorvastatina:
                "posAtcMedicacoesEmUsoAtorvastatina90",
              posAtcApresentaAlgumSintoma: "posAtcApresentaAlgumSintoma90",
              posAtcApresentaAlgumSintomaQuais:
                "posAtcApresentaAlgumSintomaQuais90",
              posAtcBuscouAtendimentoUrgencia:
                "posAtcBuscouAtendimentoUrgencia90",
              posAtcBuscouAtendimentoUrgenciaComplicacoes:
                "posAtcBuscouAtendimentoUrgenciaComplicacoes90",
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar:
                "posAtcBuscouAtendimentoUrgenciaPrecisouInternar90",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao90",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate90",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc90",
              posAtcRetornouComMedicoAssistente:
                "posAtcRetornouComMedicoAssistente90",
              posAtcRetornouComMedicoAssistenteNomeMedico:
                "posAtcRetornouComMedicoAssistenteNomeMedico90",
              posAtcRealizouExame: "posAtcRealizouExame90",
              posAtcRealizouExameQual: "posAtcRealizouExameQual90",
              posAtcEletrocardiogramaAlteracao:
                "posAtcEletrocardiogramaAlteracao90",
              posAtcEletrocardiogramaAlteracaoQuais:
                "posAtcEletrocardiogramaAlteracaoQuais90",
              posAtcExameFisico: "posAtcExameFisico90",
              posAtcPacienteTemAlgumaDuvida: "posAtcPacienteTemAlgumaDuvida90",
              posAtcPacienteTemAlgumaDuvidaQuais:
                "posAtcPacienteTemAlgumaDuvidaQuais90",
              posAtcExameObservacoesGerais: "posAtcExameObservacoesGerais90",
              posAtcMarcadoRetorno: "posAtcMarcadoRetorno90",
            }}
          />
        </div>
        <div className={`tab-pane ${activeTab === 180 ? "active" : ""}`}>
          <Content
            posAtcData={posAtcData180}
            formCongelado={formCongelado}
            posAtcMedicacoesEmUso={posAtcMedicacoesEmUso180}
            posAtcApresentaAlgumSintoma={posAtcApresentaAlgumSintoma180}
            posAtcBuscouAtendimentoUrgencia={posAtcBuscouAtendimentoUrgencia180}
            posAtcBuscouAtendimentoUrgenciaComplicacoes={
              posAtcBuscouAtendimentoUrgenciaComplicacoes180
            }
            setFieldValue={setFieldValue}
            posAtcBuscouAtendimentoUrgenciaPrecisouInternar={
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar180
            }
            posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao={
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao180
            }
            posAtcRetornouComMedicoAssistente={
              posAtcRetornouComMedicoAssistente180
            }
            posAtcRealizouExame={posAtcRealizouExame180}
            posAtcEletrocardiogramaAlteracao={
              posAtcEletrocardiogramaAlteracao180
            }
            posAtcPacienteTemAlgumaDuvida={posAtcPacienteTemAlgumaDuvida180}
            posAtcMarcadoRetorno={posAtcMarcadoRetorno180}
            names={{
              posAtcData: "posAtcData180",
              posAtcMedico: "posAtcMedico180",
              posAtcMedicacoesEmUso: "posAtcMedicacoesEmUso180",
              posAtcMedicacoesEmUsoClopdogrel75m:
                "posAtcMedicacoesEmUsoClopdogrel75m180",
              posAtcMedicacoesEmUsoAas: "posAtcMedicacoesEmUsoAas180",
              posAtcMedicacoesEmUsoTicagrelor:
                "posAtcMedicacoesEmUsoTicagrelor180",
              posAtcMedicacoesEmUsoRosuvastatina:
                "posAtcMedicacoesEmUsoRosuvastatina180",
              posAtcMedicacoesEmUsoAtorvastatina:
                "posAtcMedicacoesEmUsoAtorvastatina180",
              posAtcApresentaAlgumSintoma: "posAtcApresentaAlgumSintoma180",
              posAtcApresentaAlgumSintomaQuais:
                "posAtcApresentaAlgumSintomaQuais180",
              posAtcBuscouAtendimentoUrgencia:
                "posAtcBuscouAtendimentoUrgencia180",
              posAtcBuscouAtendimentoUrgenciaComplicacoes:
                "posAtcBuscouAtendimentoUrgenciaComplicacoes180",
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar:
                "posAtcBuscouAtendimentoUrgenciaPrecisouInternar180",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao180",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate180",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc180",
              posAtcRetornouComMedicoAssistente:
                "posAtcRetornouComMedicoAssistente180",
              posAtcRetornouComMedicoAssistenteNomeMedico:
                "posAtcRetornouComMedicoAssistenteNomeMedico180",
              posAtcRealizouExame: "posAtcRealizouExame180",
              posAtcRealizouExameQual: "posAtcRealizouExameQual180",
              posAtcEletrocardiogramaAlteracao:
                "posAtcEletrocardiogramaAlteracao180",
              posAtcEletrocardiogramaAlteracaoQuais:
                "posAtcEletrocardiogramaAlteracaoQuais180",
              posAtcExameFisico: "posAtcExameFisico180",
              posAtcPacienteTemAlgumaDuvida: "posAtcPacienteTemAlgumaDuvida180",
              posAtcPacienteTemAlgumaDuvidaQuais:
                "posAtcPacienteTemAlgumaDuvidaQuais180",
              posAtcExameObservacoesGerais: "posAtcExameObservacoesGerais180",
              posAtcMarcadoRetorno: "posAtcMarcadoRetorno180",
            }}
          />
        </div>
        <div className={`tab-pane ${activeTab === 360 ? "active" : ""}`}>
          <Content
            posAtcData={posAtcData360}
            formCongelado={formCongelado}
            posAtcMedicacoesEmUso={posAtcMedicacoesEmUso360}
            posAtcApresentaAlgumSintoma={posAtcApresentaAlgumSintoma360}
            posAtcBuscouAtendimentoUrgencia={
              posAtcBuscouAtendimentoUrgencia360
            }
            posAtcBuscouAtendimentoUrgenciaComplicacoes={
              posAtcBuscouAtendimentoUrgenciaComplicacoes360
            }
            setFieldValue={setFieldValue}
            posAtcBuscouAtendimentoUrgenciaPrecisouInternar={
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar360
            }
            posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao={
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao360
            }
            posAtcRetornouComMedicoAssistente={
              posAtcRetornouComMedicoAssistente360
            }
            posAtcRealizouExame={posAtcRealizouExame360}
            posAtcEletrocardiogramaAlteracao={
              posAtcEletrocardiogramaAlteracao360
            }
            posAtcPacienteTemAlgumaDuvida={posAtcPacienteTemAlgumaDuvida360}
            posAtcMarcadoRetorno={posAtcMarcadoRetorno360}
            names={{
              posAtcData: "posAtcData360",
              posAtcMedico: "posAtcMedico360",
              posAtcMedicacoesEmUso: "posAtcMedicacoesEmUso360",
              posAtcMedicacoesEmUsoClopdogrel75m:
                "posAtcMedicacoesEmUsoClopdogrel75m360",
              posAtcMedicacoesEmUsoAas: "posAtcMedicacoesEmUsoAas360",
              posAtcMedicacoesEmUsoTicagrelor:
                "posAtcMedicacoesEmUsoTicagrelor360",
              posAtcMedicacoesEmUsoRosuvastatina:
                "posAtcMedicacoesEmUsoRosuvastatina360",
              posAtcMedicacoesEmUsoAtorvastatina:
                "posAtcMedicacoesEmUsoAtorvastatina360",
              posAtcApresentaAlgumSintoma: "posAtcApresentaAlgumSintoma360",
              posAtcApresentaAlgumSintomaQuais:
                "posAtcApresentaAlgumSintomaQuais360",
              posAtcBuscouAtendimentoUrgencia:
                "posAtcBuscouAtendimentoUrgencia360",
              posAtcBuscouAtendimentoUrgenciaComplicacoes:
                "posAtcBuscouAtendimentoUrgenciaComplicacoes360",
              posAtcBuscouAtendimentoUrgenciaPrecisouInternar:
                "posAtcBuscouAtendimentoUrgenciaPrecisouInternar360",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao360",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate360",
              posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc:
                "posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc360",
              posAtcRetornouComMedicoAssistente:
                "posAtcRetornouComMedicoAssistente360",
              posAtcRetornouComMedicoAssistenteNomeMedico:
                "posAtcRetornouComMedicoAssistenteNomeMedico360",
              posAtcRealizouExame: "posAtcRealizouExame360",
              posAtcRealizouExameQual: "posAtcRealizouExameQual360",
              posAtcEletrocardiogramaAlteracao:
                "posAtcEletrocardiogramaAlteracao360",
              posAtcEletrocardiogramaAlteracaoQuais:
                "posAtcEletrocardiogramaAlteracaoQuais360",
              posAtcExameFisico: "posAtcExameFisico360",
              posAtcPacienteTemAlgumaDuvida: "posAtcPacienteTemAlgumaDuvida360",
              posAtcPacienteTemAlgumaDuvidaQuais:
                "posAtcPacienteTemAlgumaDuvidaQuais360",
              posAtcExameObservacoesGerais: "posAtcExameObservacoesGerais360",
              posAtcMarcadoRetorno: "posAtcMarcadoRetorno360",
            }}
          />
        </div>
      </div>
    </>
  );
}

function Content({
  formCongelado,
  posAtcData,
  posAtcMedicacoesEmUso,
  posAtcApresentaAlgumSintoma,
  posAtcBuscouAtendimentoUrgencia,
  posAtcBuscouAtendimentoUrgenciaComplicacoes,
  setFieldValue,
  posAtcBuscouAtendimentoUrgenciaPrecisouInternar,
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao,
  posAtcRetornouComMedicoAssistente,
  posAtcRealizouExame,
  posAtcEletrocardiogramaAlteracao,
  posAtcPacienteTemAlgumaDuvida,
  posAtcMarcadoRetorno,
  names,
}) {
  const formBloqueado = formCongelado || posAtcData === "";

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <FastField
            name={names.posAtcData}
            label="Data"
            type="date"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            disabled={formCongelado}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { max: moment().format("YYYY-MM-DDThh:mm") },
            }}
          />
        </div>
        <div className="col-md-8">
          <FastField
            name={names.posAtcMedico}
            disabled={formBloqueado}
            label="Médico"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            required
          />
        </div>
      </div>

      <Typography gutterBottom variant="h6">
        Medicações em uso
      </Typography>

      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcMedicacoesEmUso}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Usa medicamento"
          />
        </div>
      </div>
      {posAtcMedicacoesEmUso && (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name={names.posAtcMedicacoesEmUsoClopdogrel75m}
                    disabled={formBloqueado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Clopdogrel 75 M"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name={names.posAtcMedicacoesEmUsoAas}
                    disabled={formBloqueado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="AAS"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name={names.posAtcMedicacoesEmUsoTicagrelor}
                    disabled={formBloqueado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Ticagrelor"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name={names.posAtcMedicacoesEmUsoRosuvastatina}
                    disabled={formBloqueado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Rosuvastatina"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name={names.posAtcMedicacoesEmUsoAtorvastatina}
                    disabled={formBloqueado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Atorvastatina"
              />
            </div>
          </div>
        </>
      )}

      <Typography gutterBottom variant="h6">
        Sintomas
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcApresentaAlgumSintoma}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Apresenta algum sintoma?"
          />
        </div>
      </div>
      {posAtcApresentaAlgumSintoma && (
        <div className="row mb-5">
          <div className="col-md-6">
            <FastField
              name={names.posAtcApresentaAlgumSintomaQuais}
              disabled={formBloqueado}
              label="Quais sintomas?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}

      <Typography gutterBottom variant="h6">
        Atendimento
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcBuscouAtendimentoUrgencia}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Buscou atendimento de urgência (PSC) pós procedimento?"
          />
        </div>
      </div>
      {posAtcBuscouAtendimentoUrgencia && (
        <>
          <div className="row">
            <div className="col-md-4">
              <TextField
                select
                label="Complicações?"
                name={names.posAtcBuscouAtendimentoUrgenciaComplicacoes}
                disabled={formBloqueado}
                value={posAtcBuscouAtendimentoUrgenciaComplicacoes}
                onChange={event => {
                  setFieldValue(
                    names.posAtcBuscouAtendimentoUrgenciaComplicacoes,
                    event.target.value,
                  );
                }}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
              >
                <MenuItem key={1} value="1">
                  Sim
                </MenuItem>
                <MenuItem key={2} value="2">
                  Não
                </MenuItem>
                <MenuItem key={3} value="3">
                  Não se aplica
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-4">
              <TextField
                select
                label="Precisou internar?"
                name={names.posAtcBuscouAtendimentoUrgenciaPrecisouInternar}
                disabled={formBloqueado}
                value={posAtcBuscouAtendimentoUrgenciaPrecisouInternar}
                onChange={event => {
                  setFieldValue(
                    names.posAtcBuscouAtendimentoUrgenciaPrecisouInternar,
                    event.target.value,
                  );
                }}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
              >
                <MenuItem key={1} value="1">
                  Sim
                </MenuItem>
                <MenuItem key={2} value="2">
                  Não
                </MenuItem>
                <MenuItem key={3} value="3">
                  Não se aplica
                </MenuItem>
              </TextField>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <TextField
                select
                label="Precisou nova intervenção?"
                name={
                  names.posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao
                }
                disabled={formBloqueado}
                value={posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao}
                onChange={event => {
                  setFieldValue(
                    names.posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao,
                    event.target.value,
                  );
                }}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
              >
                <MenuItem key={1} value="1">
                  Sim
                </MenuItem>
                <MenuItem key={2} value="2">
                  Não
                </MenuItem>
                <MenuItem key={3} value="3">
                  Não se aplica
                </MenuItem>
              </TextField>
            </div>
            {parseInt(posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao) ===
              1 && (
              <>
                <div className="col-md-4">
                  <FormControlLabel
                    control={
                      <SwitchField
                        name={
                          names.posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate
                        }
                        disabled={formBloqueado}
                        color="primary"
                      />
                    }
                    labelPlacement="end"
                    label="Cate"
                  />
                </div>
                <div className="col-md-4">
                  <FormControlLabel
                    control={
                      <SwitchField
                        name={
                          names.posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc
                        }
                        disabled={formBloqueado}
                        color="primary"
                      />
                    }
                    labelPlacement="end"
                    label="ATC"
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcRetornouComMedicoAssistente}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Retornou com médico (cardiologista) assistente pós procedimento?"
          />
        </div>
      </div>
      {posAtcRetornouComMedicoAssistente && (
        <div className="row mb-5">
          <div className="col-md-6">
            <FastField
              name={names.posAtcRetornouComMedicoAssistenteNomeMedico}
              disabled={formBloqueado}
              label="Nome do médico"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}

      <Typography gutterBottom variant="h6">
        Exames
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcRealizouExame}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Realizou algum exame?"
          />
        </div>
      </div>
      {posAtcRealizouExame && (
        <div className="row mb-5">
          <div className="col-md-6">
            <FastField
              name={names.posAtcRealizouExameQual}
              disabled={formBloqueado}
              label="Qual exame?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcEletrocardiogramaAlteracao}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Eletrocardiograma alteração"
          />
        </div>
      </div>
      {posAtcEletrocardiogramaAlteracao && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <FastField
              name={names.posAtcEletrocardiogramaAlteracaoQuais}
              disabled={formBloqueado}
              label="Quais alteraçõoes?"
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}

      <Typography gutterBottom variant="h6">
        Exame físico
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <FastField
            name={names.posAtcExameFisico}
            disabled={formBloqueado}
            label="Exame físico"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            required
            multiline
            rows={4}
          />
        </div>
      </div>

      <Typography gutterBottom variant="h6">
        Dúvidas
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <FormControlLabel
            control={
              <SwitchField
                name={names.posAtcPacienteTemAlgumaDuvida}
                disabled={formBloqueado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Paciênte tem alguma dúvida?"
          />
        </div>
      </div>
      {posAtcPacienteTemAlgumaDuvida && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <FastField
              name={names.posAtcPacienteTemAlgumaDuvidaQuais}
              disabled={formBloqueado}
              label="Quais dúvidas?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}

      <Typography gutterBottom variant="h6">
        Observações
      </Typography>
      <div className="row mb-5">
        <div className="col-lg-12">
          <FastField
            name={names.posAtcExameObservacoesGerais}
            disabled={formBloqueado}
            label="Observações gerais"
            fullWidth
            margin="none"
            variant="outlined"
            size="small"
            required
            multiline
            rows={4}
          />
        </div>
      </div>

      <Typography gutterBottom variant="h6">
        Retorno
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <TextField
            select
            label="Marcado retorno?"
            name={names.posAtcMarcadoRetorno}
            disabled={formBloqueado}
            value={posAtcMarcadoRetorno}
            onChange={event => {
              setFieldValue(names.posAtcMarcadoRetorno, event.target.value);
            }}
            fullWidth
            size="small"
            variant="outlined"
          >
            <MenuItem key={1} value="1">
              Sim
            </MenuItem>
            <MenuItem key={2} value="2">
              Não
            </MenuItem>
            <MenuItem key={3} value="3">
              Não se aplica
            </MenuItem>
          </TextField>
        </div>
      </div>
    </>
  );
}
