/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef,no-sequences */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { object, string, ref } from "yup";

import { toAbsoluteUrl } from "../../../../_helpers";
import { renewPassword } from "../../../../../app/services/icesApi";
import * as snackbarRedux from "../../../../../redux/snackbarRedux";

export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const [errors, setErrors] = useState(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const validateSchema = object({
    password: string().required(),
    newPassword: string().required(),
    repeatNewPassword: string()
      .oneOf([ref("newPassword")], "Senhas não são iguais")
      .required(),
  });

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const handleRenewPassword = () => {
    const trocarSenha = {
      password: password,
      newPassword: newPassword,
      repeatNewPassword: repeatNewPassword,
    };

    validateSchema
      .validate(trocarSenha, { abortEarly: false })
      .then(() => {
        renewPassword({ password, newPassword })
          .then(() => {
            setPassword("");
            setNewPassword("");
            setRepeatNewPassword("");
            setErrors({});
            dispatch(
              snackbarRedux.actions.pushSnackbar({
                message: `Senha alterada com sucesso.`,
                open: true,
                type: "success",
              }),
            );
          })
          .catch(err => {
            setErrors({
              password: err.response.data.message,
            });
          });
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Perfil do usuário</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <span className="symbol symbol-100 symbol-light-success mr-5">
            <span className="symbol-label font-size-h1 font-weight-bold">
              {user?.name[0].toUpperCase()}
            </span>
          </span>

          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user?.name}
            </a>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg",
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user?.email}
                  </span>
                </span>
              </a>
            </div>

            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sair
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div>
          <h5 className="mb-5">Alterar senha</h5>
          <TextField
            label="Senha atual"
            type="password"
            margin="normal"
            variant="outlined"
            value={password}
            onChange={event => {
              setPassword(event.target.value);
            }}
            error={errors?.password ? true : false}
            helperText={errors?.password}
          />
          <TextField
            label="Nova senha"
            type="password"
            margin="normal"
            variant="outlined"
            value={newPassword}
            onChange={event => {
              setNewPassword(event.target.value);
            }}
            error={errors?.newPassword ? true : false}
            helperText={errors?.newPassword}
          />
          <TextField
            label="Repita a nova senha"
            margin="normal"
            type="password"
            variant="outlined"
            value={repeatNewPassword}
            onChange={event => {
              setRepeatNewPassword(event.target.value);
            }}
            error={errors?.repeatNewPassword ? true : false}
            helperText={errors?.repeatNewPassword}
          />

          <button
            className="btn btn-primary btn-bold"
            onClick={handleRenewPassword}
          >
            Alterar senha
          </button>
        </div>
      </div>
    </div>
  );
}
