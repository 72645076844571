import React from "react";
import { FormControlLabel, Typography } from "@material-ui/core";
import SwitchField from "../../../components/SwitchField";

export default function PreparoDaPele(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Preparo da pele - Antissepsia
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="clorexidineDegermante"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Clorexidine Degermante"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="clorexidineAlcoolico"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Clorexidine Alcóolico"
          />
        </div>
      </div>
    </div>
  );
}
