import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function EvolucaoInternacao(props) {
  const { formCongelado } = props;
  const { setFieldValue, setFieldTouched } = props.formik;
  const {
    houveElevacaoCKMB,
    hematomaPosProcedimentoEvolucaoInternacao,
    insulficienciaRenalEvolucaoInternacao,
    interrupcaoPrecoceAntiagregacaoEvolucaoInternacao,
    complicacaoSitioPuncao,
  } = props.formik.values;

  useEffect(() => {
    if (!houveElevacaoCKMB) {
      setFieldValue("valorElevacaoCKMB", "");
      setFieldTouched("valorElevacaoCKMB", false);
    }
  }, [houveElevacaoCKMB, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!hematomaPosProcedimentoEvolucaoInternacao) {
      setFieldValue("descricaoHematomaPosProcedimentoEvolucaoInternacao", "");
      setFieldTouched(
        "descricaoHematomaPosProcedimentoEvolucaoInternacao",
        false,
      );
    }
  }, [
    hematomaPosProcedimentoEvolucaoInternacao,
    setFieldTouched,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!insulficienciaRenalEvolucaoInternacao) {
      setFieldValue("creatMaisAlta", "");
      setFieldTouched("creatMaisAlta", false);
    }
  }, [insulficienciaRenalEvolucaoInternacao, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!interrupcaoPrecoceAntiagregacaoEvolucaoInternacao) {
      setFieldValue(
        "motivoInterrupcaoPrecoceAntiagregacaoEvolucaoInternacao",
        "",
      );
      setFieldTouched(
        "motivoInterrupcaoPrecoceAntiagregacaoEvolucaoInternacao",
        false,
      );
    }
  }, [
    interrupcaoPrecoceAntiagregacaoEvolucaoInternacao,
    setFieldTouched,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!complicacaoSitioPuncao) {
      setFieldValue("descrevaComplicacaoSitioPuncao", "");
      setFieldTouched("descrevaComplicacaoSitioPuncao", false);
    }
  }, [complicacaoSitioPuncao, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Evolução da internação
      </Typography>
      <div className="row my-4">
        <div className="col-md-4 py-4">
          <FastField
            name="diasInternado"
            disabled={formCongelado}
            label="O paciente permaneceu quantos dias internado?"
            fullWidth
            variant="outlined"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="houveElevacaoCKMB"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Em caso de angioplastia eletiva, houve elevação de CKMB no pós-ATC?"
          />
        </div>
        {houveElevacaoCKMB && (
          <div className="col-md-6 py-2">
            <FastField
              name="valorElevacaoCKMB"
              disabled={formCongelado}
              label="Valor"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-12 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="dorPrecordialEquivalePosATC"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Paciente apresentou dor precordial ou equivalente no pós-ATC?"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="retornoHemodinamicaOuNovoProcedimento"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Foi necessário retornar à sala de hemodinâmica para novo procedimento não programado?"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="hematomaPosProcedimentoEvolucaoInternacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Hematoma após o procedimento?"
          />
        </div>
        {hematomaPosProcedimentoEvolucaoInternacao && (
          <div className="col-md-6 py-2">
            <FastField
              name="descricaoHematomaPosProcedimentoEvolucaoInternacao"
              disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="insulficienciaRenalEvolucaoInternacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Houve insuficiência Renal após o uso do contraste? "
          />
        </div>
        {insulficienciaRenalEvolucaoInternacao && (
          <div className="col-md-6 py-2">
            <FastField
              name="creatMaisAlta"
              disabled={formCongelado}
              label="Creat mais alta"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="hemodialiseEvolucaoInternacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Houve necessidade de hemodiálise?"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="interrupcaoPrecoceAntiagregacaoEvolucaoInternacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Houve interrupção precoce da antiagregação plaquetária?"
          />
        </div>
        {interrupcaoPrecoceAntiagregacaoEvolucaoInternacao && (
          <div className="col-md-6 py-2">
            <FastField
              name="motivoInterrupcaoPrecoceAntiagregacaoEvolucaoInternacao"
              disabled={formCongelado}
              label="Motivo"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="complicacaoSitioPuncao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Complicação no sítio da punção?"
          />
        </div>
        {complicacaoSitioPuncao && (
          <div className="col-md-6 py-2">
            <FastField
              name="descrevaComplicacaoSitioPuncao"
              disabled={formCongelado}
              label="Motivo"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-6 py-2">
          <FormControlLabel
            control={
              <SwitchField
                name="obitoMomentoInternacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Óbito em algum momento da internação?"
          />
        </div>
      </div>
    </div>
  );
}
