/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { object, string, number } from "yup";

import leftDominance from "./../../../assets/img/LeftDominance.png";
import rightDominance from "./../../../assets/img/RightDominance.png";

export default function BalaoDialog({ open, onConfirm, onClose }) {
  const [errors, setErrors] = useState(null);
  const [localOptions, setLocalOptions] = useState([]);
  const [local, setLocal] = useState("");
  const [ladoOptions, setLadoOptions] = useState([]);
  const [lado, setLado] = useState("");
  const [balao, setBalao] = useState("");
  const [diametro, setDiametro] = useState(null);
  const [comprimento, setComprimento] = useState(null);
  const [pressao, setPressao] = useState(null);

  const validateSchema = object({
    lado: string().required(),
    local: string().required(),
    balao: string().required(),
    diametro: number()
      .integer()
      .nullable()
      .required(),
    comprimento: number()
      .integer()
      .nullable()
      .required(),
    pressao: number()
      .integer()
      .nullable()
      .required(),
  });

  const handleConfirm = () => {
    const data = {
      lado: lado,
      local: local,
      balao: balao,
      diametro: diametro,
      comprimento: comprimento,
      pressao: pressao,
    };

    validateSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        onConfirm(data);
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  useEffect(() => {
    setErrors([]);
    setLado("");
    setLocal("");
    setBalao("");
    setDiametro(null);
    setComprimento(null);
    setPressao(null);
  }, [open]);

  useEffect(() => {
    setLocalOptions([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "9a",
      "10",
      "10a",
      "11",
      "12",
      "12a",
      "12b",
      "13",
      "14",
      "14a",
      "14b",
      "15",
      "16",
      "16a",
      "16b",
      "16c",
    ]);

    setLadoOptions([
      { value: "D", label: "Direito" },
      { value: "E", label: "Esquerdo" },
    ]);
  }, []);

  const validateIfHasError = (fieldName, value) => {
    if (errors?.[fieldName]) {
      validateSchema.validateAt(fieldName, { [fieldName]: value }).then(() => {
        const newErrors = errors;
        delete newErrors[fieldName];

        setErrors(newErrors);
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Adicionar balão</DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-6">
            <img
              src={leftDominance}
              alt="Domínio esquerdo"
              className="rounded mx-auto d-block"
            />
          </div>
          <div className="col-md-6">
            <img
              src={rightDominance}
              alt="Domínio direito"
              className="rounded mx-auto d-block"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              select
              required
              label="Lado"
              value={lado}
              margin="normal"
              fullWidth
              size="small"
              onChange={event => {
                validateIfHasError("lado", event.target.value);
                setLado(event.target.value);
              }}
              error={errors?.lado ? true : false}
              helperText={errors?.lado}
            >
              {ladoOptions.map((lado, i) => {
                return (
                  <MenuItem key={i} value={lado.value}>
                    {lado.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="col-md-4">
            <TextField
              select
              required
              label="Vaso"
              value={local}
              fullWidth
              size="small"
              margin="normal"
              onChange={event => {
                validateIfHasError("local", event.target.value);
                setLocal(event.target.value);
              }}
              error={errors?.local ? true : false}
              helperText={errors?.local}
            >
              {localOptions.map((local, i) => {
                return (
                  <MenuItem key={i} value={local}>
                    {local}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="col-md-4">
            <TextField
              required
              label="Balão"
              fullWidth
              size="small"
              margin="normal"
              onChange={event => {
                validateIfHasError("balao", event.target.value);
                setBalao(event.target.value);
              }}
              error={errors?.balao ? true : false}
              helperText={errors?.balao}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              required
              label="Diâmetro"
              fullWidth
              type="number"
              size="small"
              margin="normal"
              onChange={event => {
                validateIfHasError("diametro", event.target.value);
                setDiametro(event.target.value);
              }}
              error={errors?.diametro ? true : false}
              helperText={errors?.diametro}
            />
          </div>
          <div className="col-md-4">
            <TextField
              required
              label="Comprimento"
              fullWidth
              type="number"
              size="small"
              margin="normal"
              onChange={event => {
                validateIfHasError("comprimento", event.target.value);
                setComprimento(event.target.value);
              }}
              error={errors?.comprimento ? true : false}
              helperText={errors?.comprimento}
            />
          </div>
          <div className="col-md-4">
            <TextField
              required
              label="Pressão"
              fullWidth
              type="number"
              size="small"
              margin="normal"
              onChange={event => {
                validateIfHasError("pressao", event.target.value);
                setPressao(event.target.value);
              }}
              error={errors?.pressao ? true : false}
              helperText={errors?.pressao}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
