import { number, array, object, string, boolean } from "yup";
import moment from "moment";

export const initialValues = {
  procedencia: "",
  admissao: moment().format("YYYY-MM-DDTHH:mm"),
  inicioDor: "",
  urgencia: false,
  iamcsst: false,
  iamssst: false,
  jejum: false,
  horasJejum: "",

  roupa: false,
  sapato: false,
  adorno: false,
  proteseDentaria: false,
  aparelhoAuditivo: false,
  oculos: false,
  medicamento: false,
  pertencesOutros: "",

  preHoraSinaisVitais: "",
  preFcSinaisVitais: "",
  preFrSinaisVitais: "",
  preTaxSinaisVitais: "",
  prePaSinaisVitais: "",
  preSatSinaisVitais: "",
  preDorSinaisVitais: "",
  preNivelConscienciaSinaisVitais: "",

  possuiAlergia: false,
  alergiaMedicamentosa: false,
  alergiaMedicamentosaDescricao: "",
  dessensibilizacaoIodo: false,
  diasDessensibilizacaoIodo: "",
  alergiaAlimentar: false,
  alergiaAlimentarDescricao: "",

  usaMedicacao: false,
  salicilicoSomalgimAspirinaAnalgesim: false,
  plaketarTiglidTiclobal: false,
  ticagrelorBrilinta: false,
  prasugrelEffient: false,
  marevamVarfarinaXareltonPradaxaCoumadin: false,
  enoxaparinaClexaneCutenox: false,
  metiforminaGlifageDiaformiGlucoformim: false,
  clopidrogelAtaque8Comp: false,
  clopidrogelAtaque4Comp: false,

  has: false,
  diabetes: false,
  diabetesMedicacaoSuspensaDias: "",
  insuficienciaRenal: false,
  insuficienciaRenalPreparo: false,
  dialitico: false,
  disturbioCoagulacao: false,
  disturbioCoagulacaoMedicacaoSuspensaDias: "",
  tabagismo: false,
  iam: false,
  quandoIam: "",
  avc: false,
  quandoAvc: "",
  embolizacaoCerebral: false,
  quandoEmbolizacaoCerebral: "",
  atc: false,
  quandoAtc: "",
  atp: false,
  quandoAtp: "",
  cirurgiaCardiaca: false,
  cirurgiaCardiacaSafena: false,
  cirurgiaCardiacaMamaria: false,
  cirurgiaCardiacaRadial: false,
  quandoCirurgiaCardiaca: "",
  mastectomizadaDireita: false,
  mastectomizadaEsquerda: false,
  dac: false,
  dislipidemia: false,
  histCardiopatia: false,
  sedentarismo: false,
  antecedenteCancer: false,
  tratamentoCancer: false,
  tipoCancer: [],

  possuiExames: false,
  examesEmergencia: false,
  ureia: false,
  valorUreia: "",
  creatinina: false,
  valorCreatinina: "",
  leucocitos: false,
  valorLeucocitos: "",
  hemoglobina: false,
  valorHemoglobina: "",
  hematocrito: false,
  valorHematocrito: "",
  plaquetas: false,
  valorPlaquetas: "",
  troponina: false,
  valorTroponina: "",
  ckm: false,
  valorCkm: "",
  inr: false,
  valorInr: "",
  eco: false,
  testeErgometrico: false,
  cintilografia: false,
  angiotomografia: false,
  rnmCardiaca: false,
  ecostress: false,
  exameSangue: false,
  examesOutros: "",

  precaucaoPadrao: false,
  precaucaoContato: false,
  precaucaoAerosol: false,
  precaucaoGoticula: false,
  precaucaoReverso: false,
  precaucaoObservacao: "",

  checklistAdmissaoNaoAplicado: false,
  prontuario: false,
  protocoloTransporte: false,
  internadoMais24h: false,
  cuidadosHomeCare: false,
  ecgEco: false,
  proteseEntregue: false,
  internadoUltimos30Dias: false,
  clAdmissaoOutros: "",

  riscosAssistenciaisNaoAplicado: false,
  riscoQueda: false,
  riscoBroncospiracao: false,
  riscoFlebite: false,
  riscoInfeccao: false,
  riscoHematoma: false,
  riscoReacaoAlergica: false,
  riscoNefropatiaInduzidaNic: false,
  riscoEstimuloVasoVagal: false,
  riscoHemorragia: false,
  riscoOutros: "",

  identificacaoPaciente: false,
  confirmacaoProcPaciente: false,
  termoConsentimentoLivre: false,
  termoConsentimentoAnestesico: false,
  historicoAlergia: false,
  kitProcDisponivel: false,
  monitorizacaoInstalada: false,
  usoClopidogrel: false,
  dosagemClopidrogel: "",
  tricotomia: false,
  tricotomiaDone: "",
  tricotomiaLocal: "",
  posicaoCorretaProc: "",

  clorexidineDegermante: false,
  clorexidineAlcoolico: false,

  entradaEmSala: "",
  inicioProcedimento: "",
  terminoProcedimento: "",

  confirmacaoProc: false,
  qualProc: "",
  usoAnestesia: false,
  qualAnestesia: "",
  anestesista: false,
  qualAnestesista: "",
  acessoVenosoProfundo: false,
  qualVenosoProfundo: "",
  acessoVenosoPeriferico: false,
  qualVenosoPeriferico: "",
  disseccao: false,
  localDisseccao: "",
  pam: false,
  localPam: "",

  introdutorArterial: false,
  introdutorVenoso: false,
  introdutorFemoral: false,
  introdutorFemoralLado: "",
  introdutorArterialRadial: false,
  introdutorArterialRadialLado: "",
  introdutorArterialBraquial: false,
  introdutorArterialBraquialLado: "",

  transHoraSinaisVitais: "",
  transFcSinaisVitais: "",
  transFrSinaisVitais: "",
  transTaxSinaisVitais: "",
  transPaSinaisVitais: "",
  transSatSinaisVitais: "",
  transDorSinaisVitais: "",
  transNivelConscienciaSinaisVitais: "",

  angioSeal: false,
  perclose: false,
  sondagem: false,
  qualSondagem: "",
  dreno: false,
  qualDreno: "",

  confirmacaoProcVerbal: false,
  descConfirmacaoProcVerbal: "",
  problemaEquipamento: false,
  descProblemaEquipamentos: "",
  confirmacaoPulseira: false,
  motivoRetiradaPulseira: "",
  contagemCompressaCorreta: false,
  transObservacao: "",

  contraste: false,
  tipoContraste: "",
  volumeContraste: "",

  heparinas: [],
  tcas: [],

  gasometria: false,
  examesOutrosSala: false,
  descExamesOutrosSala: "",

  intercorrenciasEnfermagem: "",

  avaliacaoDeRiscoNaoAplicada: false,

  hematomas: false,
  semHematomas: false,
  localizacaoHematomas: "",

  horarioRetiradaIntrodutor: "",
  tempoCompressaoIntrodutor: "",
  responsavelRetiradaIntrodutor: "",

  totalAvaliacaoRisco: "",

  posHoraSinaisVitais: "",
  posFcSinaisVitais: "",
  posFrSinaisVitais: "",
  posTaxSinaisVitais: "",
  posPaSinaisVitais: "",
  posSatSinaisVitais: "",
  posDorSinaisVitais: "",
  posNivelConscienciaSinaisVitais: "",
  resultadoScorePre: "",
  resultadoScoreTrans: "",
  resultadoScorePos: "",

  kroulikAtividadeMuscular15: "",
  kroulikAtividadeMuscular30: "",
  kroulikAtividadeMuscular60: "",
  kroulikAtividadeMuscular2h: "",
  kroulikRespiracao15: "",
  kroulikRespiracao30: "",
  kroulikRespiracao60: "",
  kroulikRespiracao2h: "",
  kroulikCirculacao15: "",
  kroulikCirculacao30: "",
  kroulikCirculacao60: "",
  kroulikCirculacao2h: "",
  kroulikConsciencia15: "",
  kroulikConsciencia30: "",
  kroulikConsciencia60: "",
  kroulikConsciencia2h: "",
  kroulikSaturacaoOxigenio15: "",
  kroulikSaturacaoOxigenio30: "",
  kroulikSaturacaoOxigenio60: "",
  kroulikSaturacaoOxigenio2h: "",

  transfDestino: "",
  transfProntoSocorroLeito: "",
  transfUtiLeito: "",
  transfUcoLeito: "",
  transfEnfSetor: "",
  transfEnfLeito: "",

  altaMedicaDisponibilizada: false,
  relatorioTransf: false,
  protocoloTransf: false,
  prescMedicaPreparada: false,
  medicacoesChecadas: false,
  examesDevolvidosAcompanhante: false,
  pertencesPacienteDevolvido: false,
  passagemPlantao: false,
  copiaFolhaAnestesia: false,

  horarioAlta: "",
  evolucaoEnfermagem: "",

  horarioSenhaPs: "",
  obsSenhaPs: "",
  horarioEcg: "",
  obsEcg: "",
  horarioEquipeHemodinamica: "",
  obsEquipeHemodinamica: "",
  horarioChegadaTecnicos: "",
  obsChegadaTecnicos: "",
  horarioChegadaMedico: "",
  obsChegadaMedico: "",
  horarioChegadaPaciente: "",
  obsChegadaPaciente: "",
  horarioInsercaoBalao: "",
  obsInsercaoBalao: "",
  horarioTerminoProc: "",
  obsTerminoProc: "",
  horarioSaidaPaciente: "",
  obsSaidaPaciente: "",

  angioplastia: "",

  cateterGuias: [],
  guias0014: [],
  metodosAdjunto: [],
  preDilatacao: false,
  posDilatacao: false,
  baloes: [],
  stents: [],
  cateterismos: [],

  statusProcedimento: "",
  complicacaoDuranteProcedimento: false,
  descricaoComplicacao: "",
  dorPrecordial: false,
  descricaoDorPrecordial: "",
  choqueCardiogenico: false,
  descricaoChoqueCardiogenico: "",
  hematomaProcedimentoMedico: false,
  descricaoHematoma: "",
  hemopericardio: false,
  descricaoHemopericardio: "",
  outrosSucessoProcedimento: "",

  classificacaoQuadroClinico: "",

  diasInternado: "",
  houveElevacaoCKMB: false,
  valorElevacaoCKMB: "",
  dorPrecordialEquivalePosATC: false,
  retornoHemodinamicaOuNovoProcedimento: false,
  hematomaPosProcedimentoEvolucaoInternacao: false,
  descricaoHematomaPosProcedimentoEvolucaoInternacao: "",
  insulficienciaRenalEvolucaoInternacao: false,
  creatMaisAlta: "",
  hemodialiseEvolucaoInternacao: false,
  interrupcaoPrecoceAntiagregacaoEvolucaoInternacao: false,
  motivoInterrupcaoPrecoceAntiagregacaoEvolucaoInternacao: "",
  complicacaoSitioPuncao: false,
  descrevaComplicacaoSitioPuncao: "",
  obitoMomentoInternacao: false,

  posAtcMedicacoesEmUso30: false,
  posAtcMedicacoesEmUsoClopdogrel75m30: false,
  posAtcMedicacoesEmUsoAas30: false,
  posAtcMedicacoesEmUsoTicagrelor30: false,
  posAtcMedicacoesEmUsoRosuvastatina30: false,
  posAtcMedicacoesEmUsoAtorvastatina30: false,
  posAtcApresentaAlgumSintoma30: false,
  posAtcApresentaAlgumSintomaQuais30: "",
  posAtcBuscouAtendimentoUrgencia30: false,
  posAtcBuscouAtendimentoUrgenciaComplicacoes30: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouInternar30: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao30: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate30: false,
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc30: false,
  posAtcRetornouComMedicoAssistente30: false,
  posAtcRetornouComMedicoAssistenteNomeMedico30: "",
  posAtcRealizouExame30: false,
  posAtcRealizouExameQual30: "",
  posAtcEletrocardiogramaAlteracao30: false,
  posAtcEletrocardiogramaAlteracaoQuais30: "",
  posAtcExameFisico30: "",
  posAtcPacienteTemAlgumaDuvida30: false,
  posAtcPacienteTemAlgumaDuvidaQuais30: "",
  posAtcExameObservacoesGerais30: "",
  posAtcMarcadoRetorno30: "",
  posAtcData30: "",
  posAtcMedico30: "",

  posAtcMedicacoesEmUso90: false,
  posAtcMedicacoesEmUsoClopdogrel75m90: false,
  posAtcMedicacoesEmUsoAas90: false,
  posAtcMedicacoesEmUsoTicagrelor90: false,
  posAtcMedicacoesEmUsoRosuvastatina90: false,
  posAtcMedicacoesEmUsoAtorvastatina90: false,
  posAtcApresentaAlgumSintoma90: false,
  posAtcApresentaAlgumSintomaQuais90: "",
  posAtcBuscouAtendimentoUrgencia90: false,
  posAtcBuscouAtendimentoUrgenciaComplicacoes90: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouInternar90: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao90: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate90: false,
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc90: false,
  posAtcRetornouComMedicoAssistente90: false,
  posAtcRetornouComMedicoAssistenteNomeMedico90: "",
  posAtcRealizouExame90: false,
  posAtcRealizouExameQual90: "",
  posAtcEletrocardiogramaAlteracao90: false,
  posAtcEletrocardiogramaAlteracaoQuais90: "",
  posAtcExameFisico90: "",
  posAtcPacienteTemAlgumaDuvida90: false,
  posAtcPacienteTemAlgumaDuvidaQuais90: "",
  posAtcExameObservacoesGerais90: "",
  posAtcMarcadoRetorno90: "",
  posAtcData90: "",
  posAtcMedico90: "",

  posAtcMedicacoesEmUso180: false,
  posAtcMedicacoesEmUsoClopdogrel75m180: false,
  posAtcMedicacoesEmUsoAas180: false,
  posAtcMedicacoesEmUsoTicagrelor180: false,
  posAtcMedicacoesEmUsoRosuvastatina180: false,
  posAtcMedicacoesEmUsoAtorvastatina180: false,
  posAtcApresentaAlgumSintoma180: false,
  posAtcApresentaAlgumSintomaQuais180: "",
  posAtcBuscouAtendimentoUrgencia180: false,
  posAtcBuscouAtendimentoUrgenciaComplicacoes180: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouInternar180: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao180: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate180: false,
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc180: false,
  posAtcRetornouComMedicoAssistente180: false,
  posAtcRetornouComMedicoAssistenteNomeMedico180: "",
  posAtcRealizouExame180: false,
  posAtcRealizouExameQual180: "",
  posAtcEletrocardiogramaAlteracao180: false,
  posAtcEletrocardiogramaAlteracaoQuais180: "",
  posAtcExameFisico180: "",
  posAtcPacienteTemAlgumaDuvida180: false,
  posAtcPacienteTemAlgumaDuvidaQuais180: "",
  posAtcExameObservacoesGerais180: "",
  posAtcMarcadoRetorno180: "",
  posAtcData180: "",
  posAtcMedico180: "",

  posAtcMedicacoesEmUso360: false,
  posAtcMedicacoesEmUsoClopdogrel75m360: false,
  posAtcMedicacoesEmUsoAas360: false,
  posAtcMedicacoesEmUsoTicagrelor360: false,
  posAtcMedicacoesEmUsoRosuvastatina360: false,
  posAtcMedicacoesEmUsoAtorvastatina360: false,
  posAtcApresentaAlgumSintoma360: false,
  posAtcApresentaAlgumSintomaQuais360: "",
  posAtcBuscouAtendimentoUrgencia360: false,
  posAtcBuscouAtendimentoUrgenciaComplicacoes360: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouInternar360: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecao360: "",
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoCate360: false,
  posAtcBuscouAtendimentoUrgenciaPrecisouNovaIntervecaoAtc360: false,
  posAtcRetornouComMedicoAssistente360: false,
  posAtcRetornouComMedicoAssistenteNomeMedico360: "",
  posAtcRealizouExame360: false,
  posAtcRealizouExameQual360: "",
  posAtcEletrocardiogramaAlteracao360: false,
  posAtcEletrocardiogramaAlteracaoQuais360: "",
  posAtcExameFisico360: "",
  posAtcPacienteTemAlgumaDuvida360: false,
  posAtcPacienteTemAlgumaDuvidaQuais360: "",
  posAtcExameObservacoesGerais360: "",
  posAtcMarcadoRetorno360: "",
  posAtcData360: "",
  posAtcMedico360: "",
};

export const validations = object({
  jejum: boolean(),
  horasJejum: number()
    .integer()
    .when("jejum", {
      is: true,
      then: number().required(),
      otherwise: number().nullable(),
    }),
  preFcSinaisVitais: number()
    .nullable()
    .integer(),
  preFrSinaisVitais: number()
    .nullable()
    .integer(),
  prePaSinaisVitais: string()
    .test(
      "prePaSinaisVitais",
      "Pressão diastólica não pode ser maior que pressão sistólica em ${path}", //eslint-disable-line
      value => {
        const sistolica = value?.split("/")[0];
        const diastolica = value?.split("/")[1];
        if (sistolica && diastolica)
          return parseInt(sistolica) > parseInt(diastolica);
        return true;
      },
    )
    .test(
      "prePaSinaisVitais",
      "Formato XX/XX incorreto", //eslint-disable-line
      value => {
        return value ? value?.match(/\d+\/\d+/g) : true;
      },
    ),
  preSatSinaisVitais: number()
    .nullable()
    .integer(),
  transFcSinaisVitais: number()
    .nullable()
    .integer(),
  transFrSinaisVitais: number()
    .nullable()
    .integer(),
  transPaSinaisVitais: string()
    .test(
      "transPaSinaisVitais",
      "Pressão diastólica não pode ser maior que pressão sistólica em ${path}", //eslint-disable-line
      value => {
        const sistolica = value?.split("/")[0];
        const diastolica = value?.split("/")[1];
        if (sistolica && diastolica)
          return parseInt(sistolica) > parseInt(diastolica);
        return true;
      },
    )
    .test(
      "transPaSinaisVitais",
      "Formato XX/XX incorreto", //eslint-disable-line
      value => {
        return value ? value?.match(/\d+\/\d+/g) : true;
      },
    ),
  transSatSinaisVitais: number()
    .nullable()
    .integer(),
  posFcSinaisVitais: number()
    .nullable()
    .integer(),
  posFrSinaisVitais: number()
    .nullable()
    .integer(),
  posPaSinaisVitais: string()
    .test(
      "posPaSinaisVitais",
      "Pressão diastólica não pode ser maior que pressão sistólica em ${path}", //eslint-disable-line
      value => {
        const sistolica = value?.split("/")[0];
        const diastolica = value?.split("/")[1];
        if (sistolica && diastolica)
          return parseInt(sistolica) > parseInt(diastolica);
        return true;
      },
    )
    .test(
      "posPaSinaisVitais",
      "Formato XX/XX incorreto", //eslint-disable-line
      value => {
        return value ? value?.match(/\d+\/\d+/g) : true;
      },
    ),
  posSatSinaisVitais: number()
    .nullable()
    .integer(),
  alergiaMedicamentosa: boolean(),
  alergiaMedicamentosaDescricao: string().when("alergiaMedicamentosa", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  alergiaAlimentar: boolean(),
  alergiaAlimentarDescricao: string().when("alergiaAlimentar", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  dessensibilizacaoIodo: boolean(),
  diasDessensibilizacaoIodo: number()
    .integer()
    .when("dessensibilizacaoIodo", {
      is: true,
      then: number()
        .nullable()
        .required(),
      otherwise: number().nullable(),
    }),
  diabetes: boolean(),
  diabetesMedicacaoSuspensaDias: number()
    .integer()
    .when("diabetes", {
      is: true,
      then: number()
        .nullable()
        .required(),
      otherwise: number().nullable(),
    }),
  disturbioCoagulacao: boolean(),
  disturbioCoagulacaoMedicacaoSuspensaDias: number()
    .integer()
    .when("disturbioCoagulacao", {
      is: true,
      then: number()
        .nullable()
        .required(),
      otherwise: number().nullable(),
    }),
  iam: boolean(),
  quandoIam: string().when("iam", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  avc: boolean(),
  quandoAvc: string().when("avc", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  embolizacaoCerebral: boolean(),
  quandoEmbolizacaoCerebral: string().when("embolizacaoCerebral", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  atc: boolean(),
  quandoAtc: string().when("atc", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  atp: boolean(),
  quandoAtp: string().when("atp", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  cirurgiaCardiaca: boolean(),

  enxerto: object().when("cirurgiaCardiaca", {
    is: true,
    then: object()
      .nullable()
      .test("is-required", "enxerto is a required field", function() {
        return (
          this.parent.cirurgiaCardiacaSafena ||
          this.parent.cirurgiaCardiacaMamaria ||
          this.parent.cirurgiaCardiacaRadial
        );
      }),
    otherwise: object().nullable(),
  }),

  quandoCirurgiaCardiaca: string().when("cirurgiaCardiaca", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  antecedenteCancer: boolean(),
  tipoCancer: array().when("antecedenteCancer", {
    is: true,
    then: array()
      .nullable()
      .required(),
    otherwise: array().nullable(),
  }),
  ureia: boolean(),
  valorUreia: string().when("ureia", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  creatinina: boolean(),
  valorCreatinina: string().when("creatinina", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  leucocitos: boolean(),
  valorLeucocitos: string().when("leucocitos", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  hemoglobina: boolean(),
  valorHemoglobina: string().when("hemoglobina", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  hematocrito: boolean(),
  valorHematocrito: string().when("hematocrito", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  plaquetas: boolean(),
  valorPlaquetas: string().when("plaquetas", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  troponina: boolean(),
  valorTroponina: string().when("troponina", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  ckm: boolean(),
  valorCkm: string().when("ckm", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  inr: boolean(),
  valorInr: string().when("inr", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  usoClopidogrel: boolean(),
  dosagemClopidrogel: string().when("usoClopidogrel", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  tricotomia: boolean(),
  tricotomiaDone: string().when("tricotomia", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  tricotomiaLocal: string().when("tricotomia", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  confirmacaoProc: boolean(),
  qualProc: string().when("confirmacaoProc", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  usoAnestesia: boolean(),
  qualAnestesia: string().when("usoAnestesia", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  anestesista: boolean(),
  qualAnestesista: string().when("anestesista", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  acessoVenosoProfundo: boolean(),
  qualVenosoProfundo: string().when("acessoVenosoProfundo", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  acessoVenosoPeriferico: boolean(),
  qualVenosoPeriferico: string().when("acessoVenosoPeriferico", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  disseccao: boolean(),
  localDisseccao: string().when("disseccao", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  pam: boolean(),
  localPam: string().when("pam", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  dreno: boolean(),
  qualDreno: string().when("dreno", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  sondagem: boolean(),
  qualSondagem: string().when("sondagem", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  introdutorFemoral: boolean(),
  introdutorFemoralLado: string().when("introdutorFemoral", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  introdutorArterialRadial: boolean(),
  introdutorArterialRadialLado: string().when("introdutorArterialRadial", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  introdutorArterialBraquial: boolean(),
  introdutorArterialBraquialLado: string().when("introdutorArterialBraquial", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  confirmacaoProcVerbal: boolean(),
  descConfirmacaoProcVerbal: string().when("confirmacaoProcVerbal", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  problemaEquipamento: boolean(),
  descProblemaEquipamentos: string().when("problemaEquipamento", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  confirmacaoPulseira: boolean(),
  motivoRetiradaPulseira: string().when("confirmacaoPulseira", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  contraste: boolean(),
  tipoContraste: string().when("contraste", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  volumeContraste: number()
    .integer()
    .when("contraste", {
      is: true,
      then: number()
        .nullable()
        .required(),
      otherwise: number().nullable(),
    }),
  examesOutrosSala: boolean(),
  descExamesOutrosSala: string().when("examesOutrosSala", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  hematomas: boolean(),
  localizacaoHematomas: string().when("hematomas", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  valorElevacaoCKMB: string().when("houveElevacaoCKMB", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  descricaoHematomaPosProcedimentoEvolucaoInternacao: string().when(
    "hematomaPosProcedimentoEvolucaoInternacao",
    {
      is: true,
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    },
  ),
  creatMaisAlta: string().when("insulficienciaRenalEvolucaoInternacao", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  motivoInterrupcaoPrecoceAntiagregacaoEvolucaoInternacao: string().when(
    "interrupcaoPrecoceAntiagregacaoEvolucaoInternacao",
    {
      is: true,
      then: string()
        .nullable()
        .required(),
      otherwise: string().nullable(),
    },
  ),
  descrevaComplicacaoSitioPuncao: string().when("complicacaoSitioPuncao", {
    is: true,
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable(),
  }),
  diasInternado: number()
    .nullable()
    .integer(),
});
