/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Avatar,
  Backdrop,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object } from "yup";
import { Alert } from "react-bootstrap";
import moment from "moment";
import { Delete } from "@material-ui/icons";

import "./index.css";
import * as snackbarRedux from "../../../redux/snackbarRedux";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { initialValues, validations } from "./configs";
import {
  getAtendimento,
  storeAtendimento,
  reabrirAtendimento,
} from "../../services/icesApi";
import Header from "./components/Header";
import PertencesDeixados from "./components/PertencesDeixados";
import LoadingBox from "../../../components/utils/LoadingBox";
import Assinatura from "./components/Assinatura";
import Admissao from "./partials/Admissao";
import Alergias from "./partials/Alergias";
import MedicacoesEmUso from "./partials/MedicacoesEmUso";
import HistoriaClinica from "./partials/HistoriaClinica";
import Exames from "./partials/Exames";
import Precaucao from "./partials/Precaucao";
import ChecklistAdmissao from "./partials/ChecklistAdmissao";
import RiscosAssistenciais from "./partials/RiscosAssistenciais";
import ChecklistCirurgiaSegura from "./partials/ChecklistCirurgiaSegura";
import PreparoDaPele from "./partials/PreparoDaPele";
import Atendimento from "./partials/Atendimento";
import ConfirmacaoProcedimento from "./partials/ConfirmacaoProcedimento";
import Saida from "./partials/Saida";
import Contraste from "./partials/Contraste";
import Heparina from "./partials/Heparina";
import Tca from "./partials/Tca";
import ExamesSala from "./partials/ExamesSala";
import Anotacoes from "./partials/Anotacoes";
import AvaliacaoRisco from "./partials/AvaliacaoRisco";
import EscalaAldrette from "./partials/EscalaAldrette";
import Transferencia from "./partials/Transferencia";
import Alta from "./partials/Alta";
import Atividades from "./partials/Atividades";
import SinaisVitaisPre from "./partials/SinaisVitaisPre";
import SinaisVitaisTrans from "./partials/SinaisVitaisTrans";
import SinaisVitaisPos from "./partials/SinaisVitaisPos";
import RecuperacaoPosAnestesica from "./partials/RecuperacaoPosAnestesica";
import ScoreMews from "./partials/ScoreMews";
import CongelarFormulario from "./components/CongelarFormulario";
import Angioplastia from "./partials/Angioplastia";
import Lesao from "./partials/Lesao";
import CateterGuia from "./partials/CateterGuia";
import Guia0014 from "./partials/Guia0014";
import MetodoAdjunto from "./partials/MetodoAdjunto";
import Balao from "./partials/Balao";
import Stents from "./partials/Stents";
import SucessoProcedimento from "./partials/SucessoProcedimento";
import ClassificacaoQuadroClinico from "./partials/ClassificacaoQuadroClinico";
import Cateterismo from "./partials/Cateterismo";
import EvolucaoInternacao from "./partials/EvolucaoInternacao";
import AcompanhamentoPosAtc from "./partials/AcompanhamentoPosAtc";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
  avatar: {
    margin: 10,
  },
}));

function TimelineItem({
  media,
  title,
  date,
  recent,
  onDeleteHistory,
  children,
}) {
  const classes = useStyles();

  return (
    <div className="timeline-item">
      <div className="timeline-media">{media}</div>
      <div className="timeline-content">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="mr-2">
            <span className="text-dark-75 text-hover-primary font-weight-bold">
              {title}
            </span>
            <span className="text-muted ml-2">{date}</span>
          </div>
          {recent && (
            <div className={classes.delete}>
              <IconButton aria-label="delete" onClick={onDeleteHistory}>
                <Delete fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
        <p className="p-0">{children}</p>
      </div>
    </div>
  );
}

export default function Formulario(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const atendimentoId = new URLSearchParams(props.location.search).get("id");
  const { user } = useSelector(state => state.auth);

  const [activeTab, setActiveTab] = useState(0);
  const [etapa, setEtapa] = useState(1);
  const [status, setStatus] = useState(1);
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dadosAtendimento, setDadosAtendimento] = useState();
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [formValidations, setFormValidations] = useState(object());
  const [formErrors, setFormErrors] = useState({});
  const [formSubmiting, setFormSubmiting] = useState(false);

  const formCongelado = status === 2;

  const hasGroup = groupName => {
    return user?.groups?.map(group => group.name)?.indexOf(groupName) !== -1;
  };

  const loadAtendimento = useCallback(() => {
    setLoading(true);
    getAtendimento(atendimentoId)
      .then(({ data: { data } }) => {
        setDadosAtendimento({
          nomePaciente: data.formPaciente.nome,
          dtAtendimentoClinux: data.dtAtendimentoClinux,
          dtFimProcedimento: "",
          protocoloClinux: data.nrControleClinux,
          dtNascimentoPaciente: data.formPaciente.dtNascimento,
          sexoPaciente: data.formPaciente.sexo,
          pesoPaciente: data.formPaciente.peso,
          alturaPaciente: data.formPaciente.altura,
          cdAtendimentoClinux: data.cdAtendimentoClinux,
          crmSolicitante: data.crmSolicitante,
          medicoSolicitante: data.medicoSolicitante,
          procedencia: data.procedencia,
          procedimento: data.procedimento,
        });

        setFormInitialValues(formInitialValues => {
          return {
            ...formInitialValues,
            ...data.formPreAcompanhamentoMultidisciplinar,
            ...data.formTransAcompanhamentoMultidisciplinar,
            ...data.formPosAcompanhamentoMultidisciplinar,
            ...data.formPosMedico,
            ...data.formAcompanhamentoPos,
            ...data.formAcompanhamentoPosAtc,
          };
        });

        setEtapa(data.etapa.id);
        setStatus(data.status.id);
        setHistorico(
          data.historico.map(h => {
            return {
              name: h?.usuario?.name || "Sistema",
              dateTime: moment(h.dtHora),
              text: h.descricao,
            };
          }),
        );
      })
      .catch(() => {
        alert(
          "Ocorreu um erro ao buscar informações do atendimento. Verifique a conexão com a internet e tente novamente.",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [atendimentoId]);

  useEffect(() => {
    loadAtendimento();
  }, [loadAtendimento]);

  const handleSubmit = (values, actions) => {
    setLoading(true);
    storeAtendimento({ idAtendimento: atendimentoId, ...values })
      .then(() => {
        dispatch(
          snackbarRedux.actions.pushSnackbar({
            message: `Atendimento de nº de protocolo ${
              dadosAtendimento.protocoloClinux
            } ${values.send ? `enviado` : `salvo`} com sucesso.`,
            open: true,
            type: "success",
          }),
        );

        history.push("/");
      })
      .catch(() => {
        alert(
          "Ocorreu um erro ao salvar/enviar o atendimento. Verifique a conexão com a internet e tente novamente.",
        );
      })
      .finally(() => {
        setLoading(false);
        actions.setSubmitting(false);
      });
  };

  const handleReabrir = () => {
    setLoading(true);
    reabrirAtendimento({ idAtendimento: atendimentoId })
      .then(() => {
        dispatch(
          snackbarRedux.actions.pushSnackbar({
            message: `Atendimento de nº de protocolo ${dadosAtendimento.protocoloClinux} reaberto com sucesso.`,
            open: true,
            type: "success",
          }),
        );

        loadAtendimento();
      })
      .catch(err => {
        alert(
          "Ocorreu um erro ao reabrir o atendimento. Verifique a conexão com a internet e tente novamente.",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidations}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => {
        setFormValidations(validations);
        setFormErrors(formik.errors);

        return (
          <>
            <Backdrop className={classes.backdrop} open={loading}>
              <LoadingBox />
            </Backdrop>

            {formSubmiting && !formik.isValid && (
              <Alert variant="danger">
                <Alert.Heading>
                  Os campos abaixo devem ser corrigidos:
                </Alert.Heading>
                <ul>
                  {Object.entries(formErrors).map((entry, i) => {
                    return <li key={i}>{entry[1]}</li>;
                  })}
                </ul>
              </Alert>
            )}

            <form>
              <Header
                status={status}
                nomePaciente={dadosAtendimento?.nomePaciente}
                dtAtendimentoClinux={dadosAtendimento?.dtAtendimentoClinux}
                dtFimProcedimento={dadosAtendimento?.dtFimProcedimento}
                protocoloClinux={dadosAtendimento?.protocoloClinux}
                dtNascimentoPaciente={dadosAtendimento?.dtNascimentoPaciente}
                sexoPaciente={dadosAtendimento?.sexoPaciente}
                pesoPaciente={dadosAtendimento?.pesoPaciente}
                alturaPaciente={dadosAtendimento?.alturaPaciente}
                cdAtendimentoClinux={dadosAtendimento?.cdAtendimentoClinux}
                crmSolicitante={dadosAtendimento?.crmSolicitante}
                medicoSolicitante={dadosAtendimento?.medicoSolicitante}
                procedencia={dadosAtendimento?.procedencia}
                procedimento={dadosAtendimento?.procedimento}
                formularioProgresso={status === 2 ? 100 : 25 * (etapa - 1)}
                etapa={etapa}
                onSave={() => {
                  setFormSubmiting(true);
                  formik.values.send = false;
                  formik.handleSubmit(formik.values, formik.actions);
                }}
                onSend={() => {
                  setFormSubmiting(true);
                  formik.values.send = true;
                  formik.handleSubmit(formik.values, formik.actions);
                }}
                onReabrir={handleReabrir}
              />
              <Card>
                <div className="card-header card-header-tabs-line">
                  <ul
                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                    data-remember-tab="tab_id"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        data-toggle="tab"
                        onClick={() => {
                          setActiveTab(0);
                        }}
                      >
                        Formulário enfermagem
                      </a>
                    </li>
                    {etapa > 3 && (hasGroup("medico") || hasGroup("admin")) && (
                      <>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === 1 ? "active" : ""
                            }`}
                            data-toggle="tab"
                            onClick={() => {
                              setActiveTab(1);
                            }}
                          >
                            Formulário médico
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === 3 ? "active" : ""
                            }`}
                            data-toggle="tab"
                            onClick={() => {
                              setActiveTab(3);
                            }}
                          >
                            Acompanhamento pós
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === 4 ? "active" : ""
                            }`}
                            data-toggle="tab"
                            onClick={() => {
                              setActiveTab(4);
                            }}
                          >
                            Acompanhamentos pós ATC
                          </a>
                        </li>
                      </>
                    )}
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        data-toggle="tab"
                        onClick={() => {
                          setActiveTab(2);
                        }}
                      >
                        Histórico
                      </a>
                    </li>
                  </ul>
                </div>
                <CardBody>
                  <CongelarFormulario ativo={formCongelado} />
                  <div className="tab-content pt-3">
                    <div
                      className={`tab-pane ${activeTab === 0 ? "active" : ""}`}
                    >
                      <Typography gutterBottom variant="h4">
                        Pré-operatório
                      </Typography>
                      <Admissao
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <PertencesDeixados
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <SinaisVitaisPre
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Alergias
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <MedicacoesEmUso
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <HistoriaClinica
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Exames
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Precaucao
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <ChecklistAdmissao
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <RiscosAssistenciais
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <ChecklistCirurgiaSegura
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <PreparoDaPele
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />

                      {etapa > 1 && (
                        <>
                          <Divider variant="fullWidth" className="mt-10" />
                          <Typography
                            gutterBottom
                            variant="h4"
                            className="mt-10"
                          >
                            Trans-operatório
                          </Typography>
                          <Atendimento
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <ConfirmacaoProcedimento
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <SinaisVitaisTrans
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Saida
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Contraste
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Heparina
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Tca
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <ExamesSala
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Anotacoes
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <AvaliacaoRisco
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                        </>
                      )}
                      {etapa > 2 && (
                        <>
                          <Divider variant="fullWidth" className="mt-10" />
                          <Typography
                            gutterBottom
                            variant="h4"
                            className="mt-10"
                          >
                            Pós-operatório
                          </Typography>
                          <SinaisVitaisPos
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <ScoreMews
                            formik={formik}
                            showAcao={true}
                            showDor={true}
                            className="mt-10"
                          />
                          <RecuperacaoPosAnestesica
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <EscalaAldrette
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Transferencia
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Alta
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                          <Atividades
                            formik={formik}
                            className="mt-10"
                            formCongelado={formCongelado}
                          />
                        </>
                      )}

                      <Assinatura className="mt-15 assinatura" />
                    </div>
                  </div>
                  <div className="tab-content pt-3">
                    <div
                      className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
                    >
                      <Typography gutterBottom variant="h4">
                        Informações médicas
                      </Typography>
                      <Angioplastia
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Lesao className="mt-10" />
                      <CateterGuia
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Guia0014
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <MetodoAdjunto
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Balao
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Stents
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <SucessoProcedimento
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <ClassificacaoQuadroClinico
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <Cateterismo
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />

                      <Assinatura className="mt-15 assinatura" />
                    </div>
                  </div>
                  <div className="tab-content pt-3">
                    <div
                      className={`tab-pane ${activeTab === 3 ? "active" : ""}`}
                    >
                      <Typography gutterBottom variant="h4">
                        Acompanhamento pós
                      </Typography>
                      <EvolucaoInternacao
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                      <ScoreMews
                        formik={formik}
                        showAcao={true}
                        showDor={false}
                        className="mt-10"
                      />

                      <Assinatura className="mt-15 assinatura" />
                    </div>
                  </div>
                  <div className="tab-content pt-3">
                    <div
                      className={`tab-pane ${activeTab === 4 ? "active" : ""}`}
                    >
                      <Typography gutterBottom variant="h4">
                        Acompanhamentos pós ATC
                      </Typography>
                      <AcompanhamentoPosAtc
                        formik={formik}
                        className="mt-10"
                        formCongelado={formCongelado}
                      />
                    </div>
                  </div>
                  <div className="tab-content pt-3">
                    <div
                      className={`tab-pane ${activeTab === 2 ? "active" : ""}`}
                    >
                      <div className="timeline timeline-3">
                        <div className="timeline-items">
                          {historico.map((h, i) => {
                            return (
                              <TimelineItem
                                key={i}
                                media={
                                  <Avatar className={classes.avatar}>
                                    {h.name[0]}
                                  </Avatar>
                                }
                                title={h.name}
                                date={h.dateTime.format("DD/MM/YYYY HH:mm")}
                              >
                                {h.text}
                              </TimelineItem>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </form>
          </>
        );
      }}
    </Formik>
  );
}
