import React from "react";
import { TextField, Typography, MenuItem } from "@material-ui/core";

import FastField from "../../../components/FastField";
import MmhgFastField from "../../../components/MmhgFastField";

export default function SinaisVitaisPre(props) {
  const { formCongelado } = props;
  const {
    preNivelConscienciaSinaisVitais,
    preDorSinaisVitais,
  } = props.formik.values;
  const { setFieldValue } = props.formik;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Registro de sinais vitais
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FastField
            name="preHoraSinaisVitais"
            label="Hora"
            type="time"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="preFcSinaisVitais"
            label="FC"
            type="number"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="preFrSinaisVitais"
            label="FR"
            type="number"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="preTaxSinaisVitais"
            label="TAX"
            type="number"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <MmhgFastField
            name="prePaSinaisVitais"
            label="PA"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="preSatSinaisVitais"
            label="SAT"
            type="number"
            fullWidth
            disabled={formCongelado}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
        <div className="col-md-3">
          <TextField
            select
            label="DOR"
            name="preDorSinaisVitais"
            value={preDorSinaisVitais}
            onChange={event => {
              setFieldValue("preDorSinaisVitais", event.target.value);
            }}
            fullWidth
            disabled={formCongelado}
            margin="normal"
            size="small"
            variant="outlined"
          >
            {Array.from({ length: 11 }, (_, i) => i).map(i => {
              return (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div className="col-md-3">
          <TextField
            select
            label="Nível de consciência"
            name="preNivelConscienciaSinaisVitais"
            value={preNivelConscienciaSinaisVitais}
            onChange={event => {
              setFieldValue(
                "preNivelConscienciaSinaisVitais",
                event.target.value,
              );
            }}
            fullWidth
            disabled={formCongelado}
            margin="normal"
            size="small"
            variant="outlined"
          >
            <MenuItem key={1} value="1">
              Alerta
            </MenuItem>
            <MenuItem key={2} value="2">
              Resposta ao estímulo verbal
            </MenuItem>
            <MenuItem key={3} value="3">
              Resposta ao estímulo doloroso
            </MenuItem>
            <MenuItem key={4} value="4">
              Inconsciente
            </MenuItem>
          </TextField>
        </div>
      </div>
    </div>
  );
}
