import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import AdicionarLesaoDialog from "../components/AdicionarLesaoDialog";

export default function CateterGuia(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { cateterGuias } = props.formik.values;
  const [cateterGuiaDialogOpen, setCateterGuiaDialogOpen] = useState(false);

  const handleCateterGuiaDialogConfirm = cateterGuia => {
    setFieldValue(
      "cateterGuias",
      cateterGuias.concat({
        id: uuidv4(),
        ...cateterGuia,
      }),
    );
    setCateterGuiaDialogOpen(false);
  };

  const handleCateterGuiaDialogClose = () => {
    setCateterGuiaDialogOpen(false);
  };

  const openCateterGuiaDialog = () => {
    setCateterGuiaDialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "cateterGuias",
      cateterGuias.filter(cateterGuia => {
        return cateterGuia.id !== id;
      }),
    );
  };

  return (
    <>
      <AdicionarLesaoDialog
        title="Adicionar cateter guia"
        objetoTitle="Cateter"
        open={cateterGuiaDialogOpen}
        onConfirm={handleCateterGuiaDialogConfirm}
        onClose={handleCateterGuiaDialogClose}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Cateter guia
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openCateterGuiaDialog}
              disabled={formCongelado}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lado</TableCell>
                    <TableCell>Lesão</TableCell>
                    <TableCell>Cateter</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cateterGuias.map(cateterGuia => (
                    <TableRow key={cateterGuia.id}>
                      <TableCell>{cateterGuia.lado}</TableCell>
                      <TableCell>{cateterGuia.local}</TableCell>
                      <TableCell>{cateterGuia.objeto}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="Delete"
                          disabled={formCongelado}
                          onClick={() => {
                            handleDelete(cateterGuia.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
