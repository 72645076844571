import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function Saida(props) {
  const { formCongelado } = props;
  const {
    confirmacaoProcVerbal,
    problemaEquipamento,
    confirmacaoPulseira,
  } = props.formik.values;

  const { setFieldValue, setFieldTouched } = props.formik;

  useEffect(() => {
    if (!confirmacaoProcVerbal) {
      setFieldValue("descConfirmacaoProcVerbal", "");
      setFieldTouched("descConfirmacaoProcVerbal", false);
    }
  }, [confirmacaoProcVerbal, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!problemaEquipamento) {
      setFieldValue("descProblemaEquipamentos", "");
      setFieldTouched("descProblemaEquipamentos", false);
    }
  }, [problemaEquipamento, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!confirmacaoPulseira) {
      setFieldValue("motivoRetiradaPulseira", "");
      setFieldTouched("motivoRetiradaPulseira", false);
    }
  }, [confirmacaoPulseira, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        {
          "Saída (antes do paciente sair da sala com cirurgião, anestesista e enfermagem)"
        }
      </Typography>
      <div className="row">
        <div className="col-md-6">
          <FormControlLabel
            control={
              <SwitchField
                name="confirmacaoProcVerbal"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Enfermagem confirma verbalmente procedimento realizado."
          />
        </div>
        {confirmacaoProcVerbal && (
          <div className="col-md-6">
            <FastField
              name="descConfirmacaoProcVerbal"
              disabled={formCongelado}
              label="Qual procedimento foi realizado?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormControlLabel
            control={
              <SwitchField
                name="problemaEquipamento"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Equipamentos apresentaram problemas"
          />
        </div>
        {problemaEquipamento && (
          <div className="col-md-6">
            <FastField
              name="descProblemaEquipamentos"
              disabled={formCongelado}
              label="Quais problemas os equipamentos apresentaram?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormControlLabel
            control={
              <SwitchField
                name="confirmacaoPulseira"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Identificação do paciente (paciente permaneceu com a pulseira)"
          />
        </div>
        {confirmacaoPulseira && (
          <div className="col-md-6">
            <FastField
              name="motivoRetiradaPulseira"
              disabled={formCongelado}
              label="Motivo retirada da pulseira"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormControlLabel
            control={
              <SwitchField
                name="contagemCompressaCorreta"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="A contagem das compressas, instrumentais, gazes e agulhas está correta"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <FastField
            name="transObservacao"
            disabled={formCongelado}
            label="Observação"
            fullWidth
            multiline
            rows={4}
            margin="none"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
