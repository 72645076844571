import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  TextField,
  MenuItem,
} from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";

export default function AvaliacaoRisco(props) {
  const { formCongelado } = props;
  const {
    avaliacaoDeRiscoNaoAplicada,
    totalAvaliacaoRisco,
  } = props.formik.values;
  const { setFieldValue, setFieldTouched } = props.formik;

  useEffect(() => {
    if (avaliacaoDeRiscoNaoAplicada) {
      setFieldValue("totalAvaliacaoRisco", "");
      setFieldTouched("totalAvaliacaoRisco", false);
    }
  }, [avaliacaoDeRiscoNaoAplicada, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Avaliação de risco de nefropatia por contraste
      </Typography>
      <div className="row">
        <div className="col-lg-6">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Desc</TableCell>
                  <TableCell align="right">Pontos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Hipotensão arterial (PAS&lt; 90mmHg por pelo menos 1h)
                  </TableCell>
                  <TableCell align="right">5 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Uso de Balão intra-aórtico</TableCell>
                  <TableCell align="right">5 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Insuficiência Cardíaca</TableCell>
                  <TableCell align="right">5 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Creatinina &gt; 1,5mg/dl
                    <br /> Se Clearance &lt; 20
                  </TableCell>
                  <TableCell align="right">
                    4 pontos
                    <br />6 pontos
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Idade &gt; 75a</TableCell>
                  <TableCell align="right">3 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Diabetes Mellitus</TableCell>
                  <TableCell align="right">3 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Volume de contraste (1pt para cada 100 ml)
                  </TableCell>
                  <TableCell align="right">1-4 pontos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <SwitchField
                          name="avaliacaoDeRiscoNaoAplicada"
                          disabled={formCongelado}
                          color="primary"
                        />
                      }
                      labelPlacement="end"
                      label="Não se aplica"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {!avaliacaoDeRiscoNaoAplicada && (
                      <TextField
                        select
                        label="Total"
                        name="totalAvaliacaoRisco"
                        disabled={formCongelado}
                        value={totalAvaliacaoRisco}
                        onChange={event => {
                          setFieldValue(
                            "totalAvaliacaoRisco",
                            event.target.value,
                          );
                        }}
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(i => {
                          return (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
