import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

const DEBOUNCE_DELAY = 250;

export default function Filtros({ onChange }) {
  const [protocolo, setProtocolo] = useState("");
  const [paciente, setPaciente] = useState("");
  const [dataAtendimento, setDataAtendimento] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange({
        protocolo,
        paciente,
        dataAtendimento,
        resetarIndice: dataAtendimento || paciente || protocolo ? true : false,
      });
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeout);
  }, [dataAtendimento, onChange, paciente, protocolo]);

  const handleClickLimparData = () => {
    setDataAtendimento("");
  };

  const handleClickLimparPaciente = () => {
    setPaciente("");
  };

  const handleClickLimparProtocolo = () => {
    setProtocolo("");
  };

  const handleMouseDown = event => {
    event.preventDefault();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <FormControl
            variant="outlined"
            size="small"
            margin="normal"
            style={{
              fontSize: "1.1rem",
              border: "0",
            }}
          >
            <InputLabel htmlFor="protocolo-filter">Protocolo</InputLabel>
            <OutlinedInput
              id="protocolo-filter"
              value={protocolo}
              onChange={event => {
                setProtocolo(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="limpar filtro de protocolo"
                    onClick={handleClickLimparProtocolo}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
              label="Protocolo"
            />
          </FormControl>
        </div>
        <div className="col-md-5">
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            style={{
              fontSize: "1.1rem",
              border: "0",
            }}
          >
            <InputLabel htmlFor="paciente-filter">Paciente</InputLabel>
            <OutlinedInput
              id="paciente-filter"
              value={paciente}
              onChange={event => {
                setPaciente(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="limpar filtro de paciente"
                    onClick={handleClickLimparPaciente}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
              label="Paciente"
            />
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            style={{
              fontSize: "1.1rem",
              border: "0",
            }}
          >
            <InputLabel htmlFor="date-filter" shrink>
              Data
            </InputLabel>
            <OutlinedInput
              id="date-filter"
              label="Data"
              type={"date"}
              value={dataAtendimento}
              onChange={event => {
                setDataAtendimento(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="limpar filtro de data"
                    onClick={handleClickLimparData}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </>
  );
}
