import React from "react";
import { useFastField } from "../hooks/useFastField";
import { TextField } from "@material-ui/core";
import MaskedInput from "react-text-mask";

function MmhgMask(props) {
  const { inputRef, ...other } = props;

  let numbers = props.value.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join("").length;
  }

  let mask = [/\d/, /\d/, "/", /\d/, /\d/, /\d/];
  if (numberLength >= 5) {
    mask = [/\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/];
  }

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
      keepCharPositions={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

export default props => {
  const { label } = props;
  const [field, meta] = useFastField(props);
  const hasError = !!meta.error && !!meta.touched;

  return (
    <TextField
      {...props}
      label={label}
      error={hasError}
      helperText={hasError ? meta.error : null}
      InputProps={{
        ...field,
        inputComponent: MmhgMask,
      }}
    />
  );
};
