import React, { useEffect } from "react";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function ConfirmacaoProcedimento(props) {
  const { formCongelado } = props;
  const {
    confirmacaoProc,
    usoAnestesia,
    anestesista,
    acessoVenosoProfundo,
    acessoVenosoPeriferico,
    disseccao,
    pam,
    sondagem,
    dreno,
    introdutorArterial,
    introdutorVenoso,
    introdutorFemoral,
    introdutorFemoralLado,
    introdutorArterialRadial,
    introdutorArterialRadialLado,
    introdutorArterialBraquial,
    introdutorArterialBraquialLado,
  } = props.formik.values;

  const {
    setFieldValue,
    setTouched,
    setFieldTouched,
    setValues,
    touched,
    values,
  } = props.formik;

  useEffect(() => {
    if (!confirmacaoProc) {
      setFieldValue("qualProc", "");
      setFieldTouched("qualProc", false);
    }
  }, [confirmacaoProc, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (!usoAnestesia) {
      setFieldValue("qualAnestesia", "");
      setFieldTouched("qualAnestesia", false);
    }
  }, [usoAnestesia, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (!anestesista) {
      setFieldValue("qualAnestesista", "");
      setFieldTouched("qualAnestesista", false);
    }
  }, [anestesista, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!acessoVenosoProfundo) {
      setFieldValue("qualVenosoProfundo", "");
      setFieldTouched("qualVenosoProfundo", false);
    }
  }, [acessoVenosoProfundo, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!acessoVenosoPeriferico) {
      setFieldValue("qualVenosoPeriferico", "");
      setFieldTouched("qualVenosoPeriferico", false);
    }
  }, [acessoVenosoPeriferico, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!disseccao) {
      setFieldValue("localDisseccao", "");
      setFieldTouched("localDisseccao", false);
    }
  }, [disseccao, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!pam) {
      setFieldValue("localPam", "");
      setFieldTouched("localPam", false);
    }
  }, [pam, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!dreno) {
      setFieldValue("qualDreno", "");
      setFieldTouched("qualDreno", false);
    }
  }, [dreno, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!sondagem) {
      setFieldValue("qualSondagem", "");
      setFieldTouched("qualSondagem", false);
    }
  }, [sondagem, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (introdutorVenoso) {
      setValues(
        {
          ...values,
          introdutorArterialRadial: false,
          introdutorArterialBraquial: false,
        },
        false,
      );

      setTouched(
        {
          ...touched,
          introdutorArterialRadial: false,
          introdutorArterialBraquial: false,
        },
        false,
      );
    }
    // eslint-disable-next-line
  }, [introdutorVenoso, setTouched, setValues]);

  useEffect(() => {
    if (!introdutorArterial && !introdutorVenoso) {
      setValues(
        {
          ...values,
          introdutorFemoral: false,
        },
        false,
      );

      setTouched(
        {
          ...touched,
          introdutorFemoral: false,
        },
        false,
      );
    }
    // eslint-disable-next-line
  }, [introdutorArterial, introdutorVenoso, setTouched, setValues]);

  useEffect(() => {
    if (!introdutorArterialRadial) {
      setFieldValue("introdutorArterialRadialLado", "");
      setFieldTouched("introdutorArterialRadialLado", false);
    }
  }, [introdutorArterialRadial, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (!introdutorArterialBraquial) {
      setFieldValue("introdutorArterialBraquialLado", "");
      setFieldTouched("introdutorArterialBraquialLado", false);
    }
  }, [introdutorArterialBraquial, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (!introdutorFemoral) {
      setFieldValue("introdutorFemoralLado", "");
      setFieldTouched("introdutorFemoralLado", false);
    }
  }, [introdutorFemoral, setFieldValue, setFieldTouched]);

  const hasIntrodutorFemoralErrors =
    !!props.formik.touched.introdutorFemoralLado &&
    !!props.formik.errors.introdutorFemoralLado;

  const hasIntrodutorArterialRadialLadoErrors =
    !!props.formik.touched.introdutorArterialRadialLado &&
    !!props.formik.errors.introdutorArterialRadialLado;

  const hasIntrodutorArterialBraquialLadoErrors =
    !!props.formik.touched.introdutorArterialBraquialLado &&
    !!props.formik.errors.introdutorArterialBraquialLado;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6" className="pb-2">
        Confirmação procedimento
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="confirmacaoProc"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Confirmação do procedimento realizado"
          />
        </div>
        {confirmacaoProc && (
          <div className="col-md-3">
            <FastField
              name="qualProc"
              disabled={formCongelado}
              label="Qual procedimento foi realizado?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="usoAnestesia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Pré anestésico administrado"
          />
        </div>
        {usoAnestesia && (
          <div className="col-md-3">
            <FastField
              name="qualAnestesia"
              disabled={formCongelado}
              label="Qual pré anestésico foi administrado?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="anestesista"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Anestesia"
          />
        </div>
        {anestesista && (
          <div className="col-md-3">
            <FastField
              name="qualAnestesista"
              disabled={formCongelado}
              label="Qual foi o anestesista?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="acessoVenosoProfundo"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Acesso venoso profundo"
          />
        </div>
        {acessoVenosoProfundo && (
          <div className="col-md-3">
            <FastField
              name="qualVenosoProfundo"
              disabled={formCongelado}
              label="Local do acesso venoso profundo"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="acessoVenosoPeriferico"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Acesso venoso periférico"
          />
        </div>
        {acessoVenosoPeriferico && (
          <div className="col-md-3">
            <FastField
              name="qualVenosoPeriferico"
              disabled={formCongelado}
              label="Local do acesso venoso periférico"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="disseccao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Dissecção"
          />
        </div>
        {disseccao && (
          <div className="col-md-3">
            <FastField
              name="localDisseccao"
              disabled={formCongelado}
              label="Local da dissecção"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="pam"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="PAM"
          />
        </div>
        {pam && (
          <div className="col-md-3">
            <FastField
              name="localPam"
              disabled={formCongelado}
              label="Local da PAM"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="dreno"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Drenos"
          />
        </div>
        {dreno && (
          <div className="col-md-3">
            <FastField
              name="qualDreno"
              disabled={formCongelado}
              label="Qual dreno"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="angioSeal"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="AngioSeal"
          />
        </div>
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="perclose"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Perclose"
          />
        </div>
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="sondagem"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Sondagens"
          />
        </div>
        {sondagem && (
          <div className="col-md-3">
            <FastField
              name="qualSondagem"
              disabled={formCongelado}
              label="Qual sondagem"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <Typography gutterBottom variant="h6" className="pb-2">
        Introdutor
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="introdutorArterial"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Arterial"
          />
        </div>
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="introdutorVenoso"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Venoso"
          />
        </div>
      </div>
      {(introdutorArterial || introdutorVenoso) && (
        <>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="introdutorFemoral"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Femoral"
              />
            </div>
            {introdutorFemoral && (
              <div className="col-md-3">
                <TextField
                  select
                  label="Lado"
                  name="introdutorFemoralLado"
                  disabled={formCongelado}
                  value={introdutorFemoralLado}
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  onChange={event => {
                    setFieldValue("introdutorFemoralLado", event.target.value);
                  }}
                  error={hasIntrodutorFemoralErrors}
                  helperText={
                    hasIntrodutorFemoralErrors
                      ? props.formik.errors.introdutorFemoralLado
                      : null
                  }
                >
                  <MenuItem key={1} value="1">
                    Direito
                  </MenuItem>
                  <MenuItem key={2} value="2">
                    Esquerdo
                  </MenuItem>
                </TextField>
              </div>
            )}
          </div>
        </>
      )}
      {introdutorArterial && (
        <>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="introdutorArterialRadial"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Radial"
              />
            </div>
            {introdutorArterialRadial && (
              <div className="col-md-3">
                <TextField
                  select
                  label="Lado"
                  name="introdutorArterialRadialLado"
                  disabled={formCongelado}
                  value={introdutorArterialRadialLado}
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                  onChange={event => {
                    setFieldValue(
                      "introdutorArterialRadialLado",
                      event.target.value,
                    );
                  }}
                  error={hasIntrodutorArterialRadialLadoErrors}
                  helperText={
                    hasIntrodutorArterialRadialLadoErrors
                      ? props.formik.errors.introdutorArterialRadialLado
                      : null
                  }
                >
                  <MenuItem key={1} value="1">
                    Direito
                  </MenuItem>
                  <MenuItem key={2} value="2">
                    Esquerdo
                  </MenuItem>
                </TextField>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="introdutorArterialBraquial"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Braquial"
              />
            </div>
            {introdutorArterialBraquial && (
              <div className="col-md-3">
                <TextField
                  select
                  label="Lado"
                  name="introdutorArterialBraquialLado"
                  disabled={formCongelado}
                  value={introdutorArterialBraquialLado}
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                  onChange={event => {
                    setFieldValue(
                      "introdutorArterialBraquialLado",
                      event.target.value,
                    );
                  }}
                  error={hasIntrodutorArterialBraquialLadoErrors}
                  helperText={
                    hasIntrodutorArterialBraquialLadoErrors
                      ? props.formik.errors.introdutorArterialBraquialLado
                      : null
                  }
                >
                  <MenuItem key={1} value="1">
                    Direito
                  </MenuItem>
                  <MenuItem key={2} value="2">
                    Esquerdo
                  </MenuItem>
                </TextField>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
