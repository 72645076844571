import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";
import moment from "moment";

import FastField from "../../../components/FastField";
import SwitchField from "../../../components/SwitchField";

export default function Admissao(props) {
  const { formCongelado } = props;
  const { setFieldValue, setFieldTouched } = props.formik;
  const { iamcsst, iamssst, jejum } = props.formik.values;

  useEffect(() => {
    if (iamcsst) {
      setFieldValue("iamssst", false);
      setFieldTouched("iamssst", false);
    } else {
      setFieldValue("inicioDor", "");
    }
  }, [iamcsst, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (iamssst) {
      setFieldValue("iamcsst", false);
      setFieldTouched("iamcsst", false);
    }
  }, [iamssst, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!jejum) {
      setFieldValue("horasJejum", "");
      setFieldTouched("horasJejum", "");
    }
  }, [jejum, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Admissão
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FastField
            name="admissao"
            label="Admissão"
            type="datetime-local"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            disabled={formCongelado}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { max: moment().format("YYYY-MM-DDThh:mm") },
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="urgencia"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Urgência"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="iamcsst"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="IAMCSST"
          />
        </div>
        {iamcsst && (
          <div className="col-md-4">
            <FastField
              name="inicioDor"
              label="Início da dor"
              type="datetime-local"
              fullWidth
              variant="outlined"
              size="small"
              disabled={formCongelado}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM-DDThh:mm") },
              }}
            />
          </div>
        )}
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="iamssst"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="IAMSST"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="jejum"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Jejum"
          />
        </div>
        {jejum && (
          <div className="col-md-4">
            <FastField
              name="horasJejum"
              label="Horas jejum"
              type="number"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              disabled={formCongelado}
            />
          </div>
        )}
      </div>
    </div>
  );
}
