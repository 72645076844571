import React from "react";
import { Typography } from "@material-ui/core";

import FastField from "../../../components/FastField";

export default function Atendimento(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Atendimento
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FastField
            name="entradaEmSala"
            label="Entrada em sala"
            type="time"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            disabled={formCongelado}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-4">
          <FastField
            name="inicioProcedimento"
            label="Início do procedimento"
            type="time"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            disabled={formCongelado}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-4">
          <FastField
            name="terminoProcedimento"
            label="Término do procedimento"
            type="time"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            disabled={formCongelado}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}
