import React, { useEffect } from "react";
import { Typography, FormControlLabel } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function RecuperacaoPosAnestesica(props) {
  const { formCongelado } = props;
  const { hematomas, semHematomas } = props.formik.values;

  const { setFieldValue, setTouched } = props.formik;

  useEffect(() => {
    if (hematomas) {
      setFieldValue("semHematomas", false);
      setTouched({ semHematomas: false });
    }
  }, [hematomas, setFieldValue, setTouched]);

  useEffect(() => {
    if (semHematomas) {
      setFieldValue("hematomas", false);
      setTouched({ hematomas: false });

      setFieldValue("localizacaoHematomas", "");
      setTouched({ localizacaoHematomas: false });
    }
  }, [semHematomas, setFieldValue, setTouched]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Recuperação Pós Anestésica
      </Typography>
      <Typography gutterBottom variant="h6" style={{ fontSize: 12 }}>
        Avaliação da Integridade da Pele
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="semHematomas"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Íntegra e sem hematomas"
          />
        </div>
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="hematomas"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Hematomas"
          />
        </div>
        {hematomas && (
          <div className="col-md-3">
            <FastField
              name="localizacaoHematomas"
              disabled={formCongelado}
              label="Localização do hematoma?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <Typography gutterBottom variant="h6" style={{ fontSize: 12 }}>
        Retirada do introdutor
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FastField
            name="horarioRetiradaIntrodutor"
            disabled={formCongelado}
            label="Horário da retirada"
            type="time"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3">
          <FastField
            name="tempoCompressaoIntrodutor"
            disabled={formCongelado}
            label="Tempo de compressão"
            type="time"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-6">
          <FastField
            name="responsavelRetiradaIntrodutor"
            disabled={formCongelado}
            label="Responsável"
            type="text"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
