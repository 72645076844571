import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function Exames(props) {
  const { formCongelado } = props;
  const {
    possuiExames,
    ureia,
    creatinina,
    leucocitos,
    hemoglobina,
    hematocrito,
    plaquetas,
    troponina,
    ckm,
    inr,
  } = props.formik.values;

  const {
    setFieldValue,
    setTouched,
    setValues,
    values,
    touched,
  } = props.formik;

  useEffect(() => {
    if (!possuiExames) {
      setValues(
        {
          ...values,
          ureia: false,
          creatinina: false,
          leucocitos: false,
          hemoglobina: false,
          hematocrito: false,
          plaquetas: false,
          troponina: false,
          ckm: false,
          inr: false,
          eco: false,
          testeErgometrico: false,
          cintilografia: false,
          angiotomografia: false,
          rnmCardiaca: false,
          ecostress: false,
          exameSangue: false,
          examesOutros: "",
        },
        false,
      );

      setTouched({
        ...touched,
        examesOutros: false,
      });
    }
    // eslint-disable-next-line
  }, [possuiExames, setFieldValue, setTouched, setValues]);

  useEffect(() => {
    setValues(
      {
        ...values,
        ...(!ureia && { valorUreia: "" }),
        ...(!creatinina && { valorCreatinina: "" }),
        ...(!leucocitos && { valorLeucocitos: "" }),
        ...(!hemoglobina && { valorHemoglobina: "" }),
        ...(!hematocrito && { valorHematocrito: "" }),
        ...(!plaquetas && { valorPlaquetas: "" }),
        ...(!troponina && { valorTroponina: "" }),
        ...(!ckm && { valorCkm: "" }),
        ...(!inr && { valorInr: "" }),
      },
      false,
    );

    setTouched({
      ...touched,
      ...(!ureia && { valorUreia: false }),
      ...(!creatinina && { valorCreatinina: false }),
      ...(!leucocitos && { valorLeucocitos: false }),
      ...(!hemoglobina && { valorHemoglobina: false }),
      ...(!hematocrito && { valorHematocrito: false }),
      ...(!plaquetas && { valorPlaquetas: false }),
      ...(!troponina && { valorTroponina: false }),
      ...(!ckm && { valorCkm: false }),
      ...(!inr && { valorInr: false }),
    });
    // eslint-disable-next-line
  }, [
    setValues,
    ureia,
    creatinina,
    leucocitos,
    hemoglobina,
    hematocrito,
    plaquetas,
    troponina,
    ckm,
    inr,
  ]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Exames
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="possuiExames"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Possui exames"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="examesEmergencia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Emergência"
          />
        </div>
      </div>
      {possuiExames && (
        <>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="ureia"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Ureia"
              />
            </div>
            {ureia && (
              <div className="col-md-3">
                <FastField
                  type="number"
                  name="valorUreia"
                  disabled={formCongelado}
                  label="Valor ureia"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="creatinina"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Creatinina"
              />
            </div>
            {creatinina && (
              <div className="col-md-3">
                <FastField
                  type="number"
                  name="valorCreatinina"
                  disabled={formCongelado}
                  label="Valor creatinina"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="leucocitos"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Leucócitos"
              />
            </div>
            {leucocitos && (
              <div className="col-md-3">
                <FastField
                  type="number"
                  name="valorLeucocitos"
                  disabled={formCongelado}
                  label="Valor leucócitos"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="hemoglobina"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Hemoglobina"
              />
            </div>
            {hemoglobina && (
              <div className="col-md-3">
                <FastField
                  name="valorHemoglobina"
                  disabled={formCongelado}
                  label="Valor hemoglobina"
                  fullWidth
                  type="number"
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="hematocrito"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Hematócrito"
              />
            </div>
            {hematocrito && (
              <div className="col-md-3">
                <FastField
                  name="valorHematocrito"
                  disabled={formCongelado}
                  label="Valor hematócrito"
                  type="number"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="plaquetas"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Plaquetas"
              />
            </div>
            {plaquetas && (
              <div className="col-md-3">
                <FastField
                  name="valorPlaquetas"
                  disabled={formCongelado}
                  label="Valor plaquetas"
                  type="number"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="troponina"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Troponina"
              />
            </div>
            {troponina && (
              <div className="col-md-3">
                <FastField
                  name="valorTroponina"
                  disabled={formCongelado}
                  label="Valor troponina"
                  type="number"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="ckm"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="CKM"
              />
            </div>
            {ckm && (
              <div className="col-md-3">
                <FastField
                  name="valorCkm"
                  disabled={formCongelado}
                  type="number"
                  label="Valor CKM"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="inr"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="INR"
              />
            </div>
            {inr && (
              <div className="col-md-3">
                <FastField
                  name="valorInr"
                  disabled={formCongelado}
                  type="number"
                  label="Valor INR"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="eco"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="ECO"
              />
            </div>
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="testeErgometrico"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Teste ergométrico"
              />
            </div>
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="cintilografia"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Cintilografia"
              />
            </div>
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="angiotomografia"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Angiotomografia"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="rnmCardiaca"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="RNM Cardiaca"
              />
            </div>
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="ecostress"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="ECO-Stress"
              />
            </div>
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="exameSangue"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Exame de sangue"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FastField
                name="examesOutros"
                disabled={formCongelado}
                label="Outros"
                fullWidth
                margin="none"
                variant="outlined"
                multiline
                rows={4}
                size="small"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
