import React from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function PertencesDeixados(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Pertences deixados
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="roupa"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Roupas"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="sapato"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Sapatos"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="adorno"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Adornos"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="proteseDentaria"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Prótese dentária"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="aparelhoAuditivo"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Aparelho auditivo"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="oculos"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Óculos"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="medicamento"
                color="primary"
                disabled={formCongelado}
              />
            }
            labelPlacement="end"
            label="Medicamentos"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FastField
            name="pertencesOutros"
            label="Outros"
            fullWidth
            margin="none"
            multiline
            rows={4}
            variant="outlined"
            size="small"
            disabled={formCongelado}
          />
        </div>
      </div>
    </div>
  );
}
