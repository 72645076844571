import { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";

const DEBOUNCE_DELAY = 250;

export function useFastField(propsOrFieldName) {
  const [field, meta, helpers] = useField(propsOrFieldName);
  const [value, setValue] = useState(field.value);
  const [event, setEvent] = useState();
  const { values } = useFormikContext();
  const { onBlur, onChange } = field;

  const contextValue = values[field.name];

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (event && event?.target) {
        onChange(event);
      }
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeout);
  }, [event, onChange]);

  useEffect(() => {
    setValue(contextValue);
  }, [contextValue]);

  field.value = value;

  field.onChange = e => {
    if (e && e.target) {
      setValue(e.currentTarget.value);

      e.persist();
      setEvent(e);
    }
  };

  field.onBlur = e => {
    onChange(e);
    onBlur(e);
  };

  helpers.setValue = value => {
    setValue(value);
  };

  return [field, meta, helpers];
}
