import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import HomePage from "./pages/HomePage";
import Formulario from "./modules/Formulario";

const AdminRoutes = lazy(() => import("./modules/Admin"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/" exact component={HomePage} />
        <ContentRoute path="/atendimentos" component={Formulario} />

        <Route path="/admin" component={AdminRoutes} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
