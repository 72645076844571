import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import moment from "moment";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";
import { getTiposCancer } from "../../../services/icesApi";

export default function HistoriaClinica(props) {
  const { formCongelado } = props;
  const {
    diabetes,
    insuficienciaRenal,
    disturbioCoagulacao,
    iam,
    avc,
    embolizacaoCerebral,
    atc,
    atp,
    cirurgiaCardiaca,
    antecedenteCancer,
    tipoCancer,
    cirurgiaCardiacaSafena,
    cirurgiaCardiacaMamaria,
    cirurgiaCardiacaRadial,
  } = props.formik.values;

  const [enxertoOpcoes] = useState(["Safena", "Mamaria", "Radial"]);
  const [enxertoSelecionado, setEnxertoSelecionado] = useState([]);

  const [tipoCancerOpcoes, setTipoCancerOpcoes] = useState([]);
  const { setFieldValue, setFieldTouched } = props.formik;

  useEffect(() => {
    getTiposCancer().then(({ data: { data } }) => {
      setTipoCancerOpcoes(data);
    });
  }, []);

  useEffect(() => {
    const selecionados = [];
    cirurgiaCardiacaSafena && selecionados.push("Safena");
    cirurgiaCardiacaMamaria && selecionados.push("Mamaria");
    cirurgiaCardiacaRadial && selecionados.push("Radial");

    setEnxertoSelecionado(selecionados);
  }, [cirurgiaCardiacaSafena, cirurgiaCardiacaMamaria, cirurgiaCardiacaRadial]);

  useEffect(() => {
    if (!diabetes) {
      setFieldValue("diabetesMedicacaoSuspensaDias", "");
      setFieldTouched("diabetesMedicacaoSuspensaDias", false);
    }
  }, [diabetes, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!insuficienciaRenal) {
      setFieldValue("insuficienciaRenalPreparo", false);
      setFieldTouched("insuficienciaRenalPreparo", false);
      setFieldValue("dialitico", false);
      setFieldTouched("dialitico", false);
    }
  }, [insuficienciaRenal, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!disturbioCoagulacao) {
      setFieldValue("disturbioCoagulacaoMedicacaoSuspensaDias", "");
      setFieldTouched("disturbioCoagulacaoMedicacaoSuspensaDias", false);
    }
  }, [disturbioCoagulacao, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!iam) {
      setFieldValue("quandoIam", "");
      setFieldTouched("quandoIam", false);
    }
  }, [iam, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!avc) {
      setFieldValue("quandoAvc", "");
      setFieldTouched("quandoAvc", false);
    }
  }, [avc, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!embolizacaoCerebral) {
      setFieldValue("quandoEmbolizacaoCerebral", "");
      setFieldTouched("quandoEmbolizacaoCerebral", false);
    }
  }, [embolizacaoCerebral, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!atc) {
      setFieldValue("quandoAtc", "");
      setFieldTouched("quandoAtc", false);
    }
  }, [atc, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!atp) {
      setFieldValue("quandoAtp", "");
      setFieldTouched("quandoAtp", false);
    }
  }, [atp, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!cirurgiaCardiaca) {
      setFieldValue("quandoCirurgiaCardiaca", "");
      setFieldTouched("quandoCirurgiaCardiaca", false);
      setFieldValue("enxerto", []);
      setEnxertoSelecionado([]);
      setFieldTouched("enxerto", false);
    }
  }, [cirurgiaCardiaca, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!antecedenteCancer) {
      setFieldValue("tipoCancer", []);
      setFieldTouched("tipoCancer", false);

      setFieldValue("tratamentoCancer", false);
      setFieldTouched("tratamentoCancer", false);
    }
  }, [antecedenteCancer, setFieldTouched, setFieldValue]);

  useEffect(() => {
    setFieldValue("cirurgiaCardiacaSafena", false);
    setFieldValue("cirurgiaCardiacaMamaria", false);
    setFieldValue("cirurgiaCardiacaRadial", false);

    if (enxertoSelecionado.includes(enxertoOpcoes[0])) {
      setFieldValue("cirurgiaCardiacaSafena", true);
    }

    if (enxertoSelecionado.includes(enxertoOpcoes[1])) {
      setFieldValue("cirurgiaCardiacaMamaria", true);
    }

    if (enxertoSelecionado.includes(enxertoOpcoes[2])) {
      setFieldValue("cirurgiaCardiacaRadial", true);
    }
  }, [enxertoOpcoes, enxertoSelecionado, setFieldValue]);

  const hasEnxertoErrors =
    (!!props.formik.touched.cirurgiaCardiacaMamaria ||
      !!props.formik.touched.cirurgiaCardiacaRadial ||
      !!props.formik.touched.cirurgiaCardiacaSafena) &&
    !!props.formik.errors.enxerto;

  const hasTipoCancerErrors =
    !!props.formik.touched.tipoCancer && !!props.formik.errors.tipoCancer;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        História clínica
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="has"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Hipertensão arterial sistêmica"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="diabetes"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Diabetes"
          />
        </div>
        {diabetes && (
          <div className="col-md-4">
            <FastField
              name="diabetesMedicacaoSuspensaDias"
              disabled={formCongelado}
              type="number"
              label="Medicação suspensa há quantos dias?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="insuficienciaRenal"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Insuficiencia renal"
          />
        </div>
        {insuficienciaRenal && (
          <>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="insuficienciaRenalPreparo"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Fez preparo?"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="dialitico"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Dialítico"
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="disturbioCoagulacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Disturbio da coagulação"
          />
        </div>
        {disturbioCoagulacao && (
          <div className="col-md-4">
            <FastField
              name="disturbioCoagulacaoMedicacaoSuspensaDias"
              disabled={formCongelado}
              type="number"
              label="Medicação suspensa há quantos dias?"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="tabagismo"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Tabagismo e/ou ex-tabagista"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="iam"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Histórico de IAM (infarto) prévio"
          />
        </div>
        {iam && (
          <div className="col-md-4">
            <FastField
              name="quandoIam"
              disabled={formCongelado}
              label="Quando ocorreu a IAM?"
              type="month"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM") },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="avc"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="AVC"
          />
        </div>
        {avc && (
          <div className="col-md-4">
            <FastField
              name="quandoAvc"
              disabled={formCongelado}
              label="Quando ocorreu o AVC?"
              type="month"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM") },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="embolizacaoCerebral"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Embolização cerebral"
          />
        </div>
        {embolizacaoCerebral && (
          <div className="col-md-4">
            <FastField
              name="quandoEmbolizacaoCerebral"
              disabled={formCongelado}
              label="Quando ocorreu a embolização cerebral?"
              type="month"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM") },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="atc"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="ATC (angioplastia) prévia"
          />
        </div>
        {atc && (
          <div className="col-md-4">
            <FastField
              name="quandoAtc"
              disabled={formCongelado}
              label="Quando ocorreu o ATC?"
              type="month"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM") },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="atp"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="ATP"
          />
        </div>
        {atp && (
          <div className="col-md-4">
            <FastField
              name="quandoAtp"
              disabled={formCongelado}
              label="Quando ocorreu o ATP?"
              type="month"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM") },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="cirurgiaCardiaca"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="RM (cirurgia cardíaca) prévia"
          />
        </div>
        {cirurgiaCardiaca && (
          <>
            <div className="col-md-4">
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                required
                error={hasEnxertoErrors}
              >
                <InputLabel id="enxerto-label">Enxerto retirado da</InputLabel>
                <Select
                  labelId="enxerto-label"
                  size="small"
                  multiple
                  value={enxertoSelecionado}
                  name="enxerto"
                  disabled={formCongelado}
                  onChange={event => {
                    setEnxertoSelecionado(event.target.value);
                  }}
                  renderValue={selected => selected.join(", ")}
                  label="Enxerto retirado da"
                >
                  {enxertoOpcoes.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={enxertoSelecionado.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="enxerto-helper-text">
                  {hasEnxertoErrors ? props.formik.errors.enxerto : null}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-4">
              <FastField
                name="quandoCirurgiaCardiaca"
                disabled={formCongelado}
                label="Quando ocorreu a cirurgia cardíaca?"
                type="month"
                fullWidth
                margin="none"
                variant="outlined"
                size="small"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { max: moment().format("YYYY-MM") },
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="mastectomizadaDireita"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Mastectomizada lado direito"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="mastectomizadaEsquerda"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Mastectomizada lado esquerdo"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="dac"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="DAC"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="dislipidemia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Dislipidemia"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="histCardiopatia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Histórico familiar de cardiopatia"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="sedentarismo"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Sedentarismo"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="antecedenteCancer"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Possui antecedente de câncer"
          />
        </div>
        {antecedenteCancer && (
          <>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="tratamentoCancer"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Tratado com critérios de cura ou em tratamento atual?"
              />
            </div>
            <div className="col-md-4">
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                required
                error={hasTipoCancerErrors}
              >
                <InputLabel id="sistema-cancer-label">
                  De qual sistema é o câncer?
                </InputLabel>
                <Select
                  labelId="sistema-cancer-label"
                  size="small"
                  multiple
                  value={tipoCancer}
                  name="tipoCancer"
                  disabled={formCongelado}
                  label="De qual sistema é o câncer?"
                  onChange={event => {
                    setFieldValue("tipoCancer", event.target.value);
                  }}
                  renderValue={selected => {
                    return selected
                      .map(s => {
                        return tipoCancerOpcoes.find(item => s === item.id)
                          ?.name;
                      })
                      .join(", ");
                  }}
                >
                  {tipoCancerOpcoes.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        checked={
                          tipoCancer.find(s => s === item.id) !== undefined
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="sistema-cancer-helper-text">
                  {hasTipoCancerErrors ? props.formik.errors.tipoCancer : null}
                </FormHelperText>
              </FormControl>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
