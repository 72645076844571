import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  cellBold: {
    fontWeight: "bold",
  },
});

export default function ScoreMews(props) {
  const classes = useStyles();
  const { showDor, showAcao } = props;

  const niveisConsciencia = [
    "A - Alerta",
    "V - Resposta ao estímulo verbal",
    "P - Resposta ao estímulo doloroso",
    "U - Inconsciente",
  ];

  const calculaScoreFR = fr => {
    fr = parseInt(fr);

    if (fr <= 8 || fr >= 25) {
      return 3;
    } else if (fr >= 9 && fr <= 11) {
      return 1;
    } else if (fr >= 21 && fr <= 24) {
      return 2;
    }

    return 0;
  };

  const calculaScoreFC = fc => {
    fc = parseInt(fc);

    if (fc <= 40 || fc >= 131) {
      return 3;
    } else if ((fc >= 41 && fc <= 50) || (fc >= 91 && fc <= 110)) {
      return 1;
    } else if (fc >= 111 && fc <= 130) {
      return 2;
    }

    return 0;
  };

  const calculaScoreTAX = tax => {
    tax = parseFloat(tax);

    if (tax <= 35) {
      return 3;
    } else if ((tax > 35 && tax <= 36) || (tax > 38 && tax <= 39)) {
      return 1;
    } else if (tax > 39) {
      return 2;
    }

    return 0;
  };

  const calculaScorePA = pa => {
    pa = parseInt(pa.split("/")[0]);

    if (pa <= 90 || pa >= 220) {
      return 3;
    } else if ((pa >= 91 && pa <= 100) || pa >= 200) {
      return 2;
    } else if (pa >= 101 && pa <= 110) {
      return 1;
    }

    return 0;
  };

  const calculaScoreSAT = sat => {
    sat = parseInt(sat);

    if (sat <= 91) {
      return 3;
    } else if (sat === 92 || sat === 93) {
      return 2;
    } else if (sat === 94 || sat === 95) {
      return 1;
    }

    return 0;
  };

  const calculaScoreNivelConsciencia = nivelConsciencia => {
    nivelConsciencia = parseInt(nivelConsciencia);

    if (nivelConsciencia > 1) {
      return 3;
    }

    return 0;
  };

  const decidirAcao = score => {
    if (score === 0 || score === 1) return "Reavaliar a cada 6 horas.";
    else if (score === 2)
      return "Reavaliar a cada 4 horas e comunicar ao médico e enfermeira.";
    else if (score === 3 || score === 4)
      return "Reavaliar a cada 2 horas e comunicar ao médico e enfermeiro.";
    else if (score >= 5) return "Reavaliar a cada 30 minutos.";

    return "";
  };

  const {
    preHoraSinaisVitais,
    preFcSinaisVitais,
    preFrSinaisVitais,
    preTaxSinaisVitais,
    prePaSinaisVitais,
    preSatSinaisVitais,
    preDorSinaisVitais,
    preNivelConscienciaSinaisVitais,

    transHoraSinaisVitais,
    transFcSinaisVitais,
    transFrSinaisVitais,
    transTaxSinaisVitais,
    transPaSinaisVitais,
    transSatSinaisVitais,
    transDorSinaisVitais,
    transNivelConscienciaSinaisVitais,

    posHoraSinaisVitais,
    posFcSinaisVitais,
    posFrSinaisVitais,
    posTaxSinaisVitais,
    posPaSinaisVitais,
    posSatSinaisVitais,
    posDorSinaisVitais,
    posNivelConscienciaSinaisVitais,

    resultadoScorePre,
    resultadoScoreTrans,
    resultadoScorePos,
  } = props.formik.values;

  const { setFieldValue } = props.formik;

  useEffect(() => {
    if (
      preFrSinaisVitais !== "" &&
      preFcSinaisVitais !== "" &&
      preTaxSinaisVitais !== "" &&
      prePaSinaisVitais !== "" &&
      preSatSinaisVitais !== "" &&
      preNivelConscienciaSinaisVitais !== ""
    ) {
      setFieldValue(
        "resultadoScorePre",
        calculaScoreFR(preFrSinaisVitais) +
          calculaScoreFC(preFcSinaisVitais) +
          calculaScoreTAX(preTaxSinaisVitais) +
          calculaScorePA(prePaSinaisVitais) +
          calculaScoreSAT(preSatSinaisVitais) +
          calculaScoreNivelConsciencia(preNivelConscienciaSinaisVitais),
      );
    } else {
      setFieldValue("resultadoScorePre", "");
    }
  }, [
    preFcSinaisVitais,
    preFrSinaisVitais,
    preNivelConscienciaSinaisVitais,
    prePaSinaisVitais,
    preSatSinaisVitais,
    preTaxSinaisVitais,
    setFieldValue,
  ]);

  useEffect(() => {
    if (
      transFrSinaisVitais !== "" &&
      transFcSinaisVitais !== "" &&
      transTaxSinaisVitais !== "" &&
      transPaSinaisVitais !== "" &&
      transSatSinaisVitais !== "" &&
      transNivelConscienciaSinaisVitais !== ""
    ) {
      setFieldValue(
        "resultadoScoreTrans",
        calculaScoreFR(transFrSinaisVitais) +
          calculaScoreFC(transFcSinaisVitais) +
          calculaScoreTAX(transTaxSinaisVitais) +
          calculaScorePA(transPaSinaisVitais) +
          calculaScoreSAT(transSatSinaisVitais) +
          calculaScoreNivelConsciencia(transNivelConscienciaSinaisVitais),
      );
    } else {
      setFieldValue("resultadoScoreTrans", "");
    }
  }, [
    setFieldValue,
    transFcSinaisVitais,
    transFrSinaisVitais,
    transNivelConscienciaSinaisVitais,
    transPaSinaisVitais,
    transSatSinaisVitais,
    transTaxSinaisVitais,
  ]);

  useEffect(() => {
    if (
      posFrSinaisVitais !== "" &&
      posFcSinaisVitais !== "" &&
      posTaxSinaisVitais !== "" &&
      posPaSinaisVitais !== "" &&
      posSatSinaisVitais !== "" &&
      posNivelConscienciaSinaisVitais !== ""
    ) {
      setFieldValue(
        "resultadoScorePos",
        calculaScoreFR(posFrSinaisVitais) +
          calculaScoreFC(posFcSinaisVitais) +
          calculaScoreTAX(posTaxSinaisVitais) +
          calculaScorePA(posPaSinaisVitais) +
          calculaScoreSAT(posSatSinaisVitais) +
          calculaScoreNivelConsciencia(posNivelConscienciaSinaisVitais),
      );
    } else {
      setFieldValue("resultadoScorePos", "");
    }
  }, [
    posFcSinaisVitais,
    posFrSinaisVitais,
    posNivelConscienciaSinaisVitais,
    posPaSinaisVitais,
    posSatSinaisVitais,
    posTaxSinaisVitais,
    setFieldValue,
  ]);

  return (
    <>
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Score de Mews
        </Typography>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Pré</TableCell>
                    <TableCell>Trans</TableCell>
                    <TableCell>Pós</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellBold}>Hora</TableCell>
                    <TableCell>{preHoraSinaisVitais}</TableCell>
                    <TableCell>{transHoraSinaisVitais}</TableCell>
                    <TableCell>{posHoraSinaisVitais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>FC</TableCell>
                    <TableCell>{preFcSinaisVitais}</TableCell>
                    <TableCell>{transFcSinaisVitais}</TableCell>
                    <TableCell>{posFcSinaisVitais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>FR</TableCell>
                    <TableCell>{preFrSinaisVitais}</TableCell>
                    <TableCell>{transFrSinaisVitais}</TableCell>
                    <TableCell>{posFrSinaisVitais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>TAX</TableCell>
                    <TableCell>{preTaxSinaisVitais}</TableCell>
                    <TableCell>{transTaxSinaisVitais}</TableCell>
                    <TableCell>{posTaxSinaisVitais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>
                      PA Sistólica
                    </TableCell>
                    <TableCell>{prePaSinaisVitais.split("/")[0]}</TableCell>
                    <TableCell>{transPaSinaisVitais.split("/")[0]}</TableCell>
                    <TableCell>{posPaSinaisVitais.split("/")[0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>SAT</TableCell>
                    <TableCell>{preSatSinaisVitais}</TableCell>
                    <TableCell>{transSatSinaisVitais}</TableCell>
                    <TableCell>{posSatSinaisVitais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>
                      Nível de consciência
                    </TableCell>
                    <TableCell>
                      {
                        niveisConsciencia[
                          parseInt(preNivelConscienciaSinaisVitais) - 1
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {
                        niveisConsciencia[
                          parseInt(transNivelConscienciaSinaisVitais) - 1
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {
                        niveisConsciencia[
                          parseInt(posNivelConscienciaSinaisVitais) - 1
                        ]
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellBold}>
                      Score de Mews
                    </TableCell>
                    <TableCell>{resultadoScorePre}</TableCell>
                    <TableCell>{resultadoScoreTrans}</TableCell>
                    <TableCell>{resultadoScorePos}</TableCell>
                  </TableRow>
                  {showAcao && (
                    <TableRow>
                      <TableCell className={classes.cellBold}>Ação</TableCell>
                      <TableCell className={classes.cellBold}>
                        {decidirAcao(resultadoScorePre)}
                      </TableCell>
                      <TableCell className={classes.cellBold}>
                        {decidirAcao(resultadoScoreTrans)}
                      </TableCell>
                      <TableCell className={classes.cellBold}>
                        {decidirAcao(resultadoScorePos)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {showDor && (
        <div className={props.className}>
          <Typography gutterBottom variant="h6">
            DOR
          </Typography>
          <div className="row">
            <div className="col-lg-12">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Pré</TableCell>
                      <TableCell>Trans</TableCell>
                      <TableCell>Pós</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellBold}>DOR</TableCell>
                      <TableCell>{preDorSinaisVitais}</TableCell>
                      <TableCell>{transDorSinaisVitais}</TableCell>
                      <TableCell>{posDorSinaisVitais}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
