/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BsCalendar, BsPeopleCircle } from "react-icons/bs";
import {
  FaChild,
  FaSortNumericUp,
  FaStethoscope,
  FaSyringe,
  FaStackOverflow,
  FaRedoAlt,
} from "react-icons/fa";
import moment from "moment";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function Header({
  status,
  onSave,
  onSend,
  onReabrir,
  nomePaciente,
  dtAtendimentoClinux,
  dtFimProcedimento,
  protocoloClinux,
  dtNascimentoPaciente,
  sexoPaciente,
  pesoPaciente,
  alturaPaciente,
  cdAtendimentoClinux,
  crmSolicitante,
  medicoSolicitante,
  procedencia,
  procedimento,
  formularioProgresso,
  etapa,
}) {
  const { user } = useSelector(state => state.auth);

  const hasGroup = groupName => {
    return user?.groups?.map(group => group.name)?.indexOf(groupName) !== -1;
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
              <div className="mr-3">
                <a
                  href="#"
                  className="d-flex align-items-center text-dark text-hover-primary font-size-h3 font-weight-bold mr-3"
                >
                  {nomePaciente}
                </a>
              </div>

              <div className="my-lg-0 my-1">
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    window.print();
                  }}
                  className="font-weight-bolder text-uppercase mr-3"
                >
                  Relatório
                </Button>
                {status === 1 &&
                  ((etapa === 4 && (hasGroup("medico") || hasGroup("admin"))) ||
                    etapa < 4) && (
                    <Dropdown as={ButtonGroup}>
                      <Button
                        variant="primary"
                        onClick={onSave}
                        className="font-weight-bolder text-uppercase"
                        size="sm"
                      >
                        Salvar
                      </Button>
                      <Dropdown.Toggle split variant="primary" size="sm" />
                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={onSend}>
                          Enviar
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                {status === 2 && hasGroup("admin") && (
                  <Button
                    variant="warning"
                    onClick={onReabrir}
                    className="font-weight-bolder text-uppercase"
                    size="sm"
                  >
                    Reabrir para edição
                  </Button>
                )}
              </div>
            </div>

            <div className="d-flex d-flex justify-content-between justify-content-between">
              <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                <div className="d-flex flex-wrap align-items-center py-2">
                  <div className="d-flex align-items-center mr-10">
                    {dtAtendimentoClinux && (
                      <>
                        <div className="mr-6">
                          <div className="font-weight-bold mb-2">
                            Dt. Atendimento. Clinux
                          </div>
                          <span className="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">
                            {moment(dtAtendimentoClinux, "YYYY-MM-DD").format(
                              "DD/MM/YYYY",
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {dtFimProcedimento && (
                      <>
                        <div className="mr-6">
                          <div className="font-weight-bold mb-2">
                            Dt. fim procedimento
                          </div>
                          <span className="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold">
                            {moment(dtFimProcedimento, "YYYY-MM-DD").format(
                              "DD/MM/YYYY",
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {protocoloClinux && (
                      <>
                        <div className="">
                          <div className="font-weight-bold mb-2">
                            Protocolo Clinux
                          </div>
                          <span className="btn btn-sm btn-text btn-light text-uppercase font-weight-bold">
                            {protocoloClinux}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                    <span className="font-weight-bold">Progresso</span>
                    <div className="progress progress-xs mt-2 mb-2">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: `${formularioProgresso}%` }}
                        aria-valuenow={formularioProgresso}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span className="font-weight-bolder text-dark">
                      {formularioProgresso}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="separator separator-solid my-7"></div>
        <div className="row d-flex justify-content-around">
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <BsCalendar className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">
                Data Nasc.
              </span>
              <span className="font-weight-bolder font-size-h5">
                {dtNascimentoPaciente
                  ? moment(dtNascimentoPaciente, "YYYY-MM-DD").format(
                      "DD/MM/YYYY",
                    )
                  : "Não informado"}
              </span>
            </div>
          </div>
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <BsPeopleCircle className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Sexo</span>
              <span className="font-weight-bolder font-size-h5">
                {sexoPaciente
                  ? sexoPaciente.trim() === "M"
                    ? "Masculino"
                    : "Feminino"
                  : "Não informado"}
              </span>
            </div>
          </div>
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaChild className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Peso</span>
              <span className="font-weight-bolder font-size-h5">
                {pesoPaciente || "Não informado"}
              </span>
            </div>
          </div>
        </div>

        <div className="separator separator-solid my-7"></div>
        <div className="row d-flex justify-content-around">
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaSortNumericUp className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Altura</span>
              <span className="font-weight-bolder font-size-h5">
                {alturaPaciente || "Não informado"}
              </span>
            </div>
          </div>
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <i className="flaticon-pie-chart icon-3x text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">IMC</span>
              <span className="font-weight-bolder font-size-h5">
                {pesoPaciente && alturaPaciente
                  ? (
                      pesoPaciente /
                      ((alturaPaciente / 100) * (alturaPaciente / 100))
                    ).toFixed(1)
                  : "Não informado"}
              </span>
            </div>
          </div>
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaRedoAlt className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">
                Procedência
              </span>
              <span className="font-weight-bolder font-size-h5">
                {procedencia || "Não informado"}
              </span>
            </div>
          </div>
        </div>

        <div className="separator separator-solid my-7"></div>

        <div className="row d-flex justify-content-around">
          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaSyringe className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">
                Procedimento
              </span>
              <span className="font-weight-bolder font-size-h5">
                {procedimento}
              </span>
            </div>
          </div>

          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaStethoscope className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">
                Médico Ref.
              </span>
              <span className="font-weight-bolder font-size-h5">
                {medicoSolicitante
                  ? `${medicoSolicitante} ${crmSolicitante &&
                      `(${crmSolicitante})`}`
                  : "Não informado"}
              </span>
            </div>
          </div>

          <div className="col-sm-4 d-flex align-items-center flex-lg-fill mb-4 my-1">
            <span className="mr-4">
              <FaStackOverflow className="icon-3x text-muted font-weight-bold" />
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">
                Atend. Clinux
              </span>
              <span className="font-weight-bolder font-size-h5">
                {cdAtendimentoClinux || "Não informado"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
