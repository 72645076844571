import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Delete } from "@material-ui/icons";

import StentsDialog from "../components/StentsDialog";

export default function Stents(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { stents } = props.formik.values;
  const [stentDialogOpen, setStentDialogOpen] = useState(false);

  const handleStentDialogConfirm = stent => {
    setFieldValue(
      "stents",
      stents.concat({
        id: uuidv4(),
        ...stent,
      }),
    );
    setStentDialogOpen(false);
  };

  const handleStentDialogClose = () => {
    setStentDialogOpen(false);
  };

  const openStentDialog = () => {
    setStentDialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "stents",
      stents.filter(stent => {
        return stent.id !== id;
      }),
    );
  };

  return (
    <>
      <StentsDialog
        open={stentDialogOpen}
        onConfirm={handleStentDialogConfirm}
        onClose={handleStentDialogClose}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Stents
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openStentDialog}
              disabled={formCongelado}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lado</TableCell>
                    <TableCell>Lesão</TableCell>
                    <TableCell>Stents</TableCell>
                    <TableCell>Diâmetro</TableCell>
                    <TableCell>Comprimento</TableCell>
                    <TableCell>Pressão</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stents.map(item => (
                    <TableRow key={item.id}>
                      <TableCell>{item.lado}</TableCell>
                      <TableCell>{item.local}</TableCell>
                      <TableCell>{item.stent}</TableCell>
                      <TableCell>{item.diametro}</TableCell>
                      <TableCell>{item.comprimento}</TableCell>
                      <TableCell>{item.pressao}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="Delete"
                          disabled={formCongelado}
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
