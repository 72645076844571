/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector(state => state.auth);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const hasGroup = groupName => {
    return user?.groups?.map(group => group.name)?.indexOf(groupName) !== -1;
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Atendimentos</span>
          </NavLink>
        </li>
        {hasGroup("admin") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin",
              true,
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Painel de controle</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Painel de controle</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/admin/groups")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/groups">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Grupos</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/admin/users")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Usuários</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
