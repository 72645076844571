import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";

export default function MedicacoesEmUso(props) {
  const { formCongelado } = props;
  const { usaMedicacao } = props.formik.values;
  const { setTouched, setValues, values, touched } = props.formik;

  useEffect(() => {
    if (!usaMedicacao) {
      setValues(
        {
          ...values,
          salicilicoSomalgimAspirinaAnalgesim: false,
          plaketarTiglidTiclobal: false,
          ticagrelorBrilinta: false,
          metiforminaGlifageDiaformiGlucoformim: false,
          prasugrelEffient: false,
          marevamVarfarinaXareltonPradaxaCoumadin: false,
          enoxaparinaClexaneCutenox: false,
          clopidrogelAtaque8Comp: false,
          clopidrogelAtaque4Comp: false,
        },
        false,
      );

      setTouched({
        ...touched,
        salicilicoSomalgimAspirinaAnalgesim: false,
        plaketarTiglidTiclobal: false,
        ticagrelorBrilinta: false,
        metiforminaGlifageDiaformiGlucoformim: false,
        prasugrelEffient: false,
        marevamVarfarinaXareltonPradaxaCoumadin: false,
        enoxaparinaClexaneCutenox: false,
        clopidrogelAtaque8Comp: false,
        clopidrogelAtaque4Comp: false,
      });
    }
    // eslint-disable-next-line
  }, [setTouched, setValues, usaMedicacao]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Medicações em uso
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="usaMedicacao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Usa medicamento"
          />
        </div>
      </div>
      {usaMedicacao && (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="salicilicoSomalgimAspirinaAnalgesim"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Ácido acetil Salicílico ( AAS) / Somalgim / Aspirina / Analgesim"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="plaketarTiglidTiclobal"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Plaketar / Tiglid / Ticlobal"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="ticagrelorBrilinta"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Ticagrelor / Brilinta"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="prasugrelEffient"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Prasugrel / Effient"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="marevamVarfarinaXareltonPradaxaCoumadin"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Marevam / Varfarina / Xarelton / Pradaxa / Coumadin"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="enoxaparinaClexaneCutenox"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Enoxaparina / Clexane / Cutenox"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="metiforminaGlifageDiaformiGlucoformim"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Metiformina / Glifage / Diaformi / Glucoformim"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="clopidrogelAtaque8Comp"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Clopidogrel ataque 8 comp"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="clopidrogelAtaque4Comp"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Clopidogrel ataque 4 comp"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
