import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import HeparinaDialog from "../components/HeparinaDialog";

export default function Heparina(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { heparinas } = props.formik.values;
  const [heparinaDialogOpen, setHeparinaDialogOpen] = useState(false);

  const handleHeparinaDialogConfirm = heparina => {
    setFieldValue(
      "heparinas",
      heparinas.concat({
        id: uuidv4(),
        ...heparina,
      }),
    );
    setHeparinaDialogOpen(false);
  };

  const handleHeparinaDialogClose = () => {
    setHeparinaDialogOpen(false);
  };

  const openHeparinaDialog = () => {
    setHeparinaDialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "heparinas",
      heparinas.filter(heparina => {
        return heparina.id !== id;
      }),
    );
  };

  return (
    <>
      <HeparinaDialog
        open={heparinaDialogOpen}
        onConfirm={handleHeparinaDialogConfirm}
        onClose={handleHeparinaDialogClose}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Heparina
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openHeparinaDialog}
              disabled={formCongelado}
            >
              Adicionar heparina
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Quantidade (UI)</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {heparinas.map(heparina => (
                    <TableRow key={heparina.id}>
                      <TableCell>
                        {heparina.tipo === "F"
                          ? `Fracionada`
                          : `Não-fracionada`}
                      </TableCell>
                      <TableCell>{heparina.quantidade}</TableCell>
                      <TableCell>{heparina.hora}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          disabled={formCongelado}
                          aria-label="Delete"
                          onClick={() => {
                            handleDelete(heparina.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
