import React, { useEffect } from "react";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function SucessoProcedimento(props) {
  const { formCongelado } = props;
  const { setFieldValue, setFieldTouched } = props.formik;
  const {
    complicacaoDuranteProcedimento,
    dorPrecordial,
    choqueCardiogenico,
    hematomaProcedimentoMedico,
    hemopericardio,
    outrosProcedimentos,
    statusProcedimento,
  } = props.formik.values;

  useEffect(() => {
    if (!complicacaoDuranteProcedimento) {
      setFieldValue("descricaoComplicacao", "");
      setFieldTouched("descricaoComplicacao", false);
    }
  }, [complicacaoDuranteProcedimento, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!dorPrecordial) {
      setFieldValue("descricaoDorPrecordial", "");
      setFieldTouched("descricaoDorPrecordial", false);
    }
  }, [dorPrecordial, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!choqueCardiogenico) {
      setFieldValue("descricaoChoqueCardiogenico", "");
      setFieldTouched("descricaoChoqueCardiogenico", false);
    }
  }, [choqueCardiogenico, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!hematomaProcedimentoMedico) {
      setFieldValue("descricaoHematoma", "");
      setFieldTouched("descricaoHematoma", false);
    }
  }, [hematomaProcedimentoMedico, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!hemopericardio) {
      setFieldValue("descricaoHemopericardio", "");
      setFieldTouched("descricaoHemopericardio", false);
    }
  }, [hemopericardio, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!outrosProcedimentos) {
      setFieldValue("descricaoOutrosProcedimentos", "");
      setFieldTouched("descricaoOutrosProcedimentos", false);
    }
  }, [outrosProcedimentos, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Sucesso do procedimento
      </Typography>
      <div className="row my-4">
        <div className="col-md-4">
          <TextField
            select
            label="Status do procedimento"
            name="statusProcedimento"
            disabled={formCongelado}
            value={statusProcedimento}
            onChange={event => {
              setFieldValue("statusProcedimento", event.target.value);
            }}
            fullWidth
            size="small"
            variant="outlined"
          >
            <MenuItem key={1} value="1">
              Procedimento obteve sucesso
            </MenuItem>
            <MenuItem key={2} value="2">
              Insucesso
            </MenuItem>
          </TextField>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="complicacaoDuranteProcedimento"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Alguma complicação durante o procedimento?"
          />
        </div>
        {complicacaoDuranteProcedimento && (
          <div className="col-md-8">
            <FastField
              name="descricaoComplicacao"
              disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="dorPrecordial"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Dor precordial?"
          />
        </div>
        {dorPrecordial && (
          <div className="col-md-8">
            <FastField
              name="descricaoDorPrecordial"
              disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="choqueCardiogenico"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Choque cardiogênico"
          />
        </div>
        {choqueCardiogenico && (
          <div className="col-md-8">
            <FastField
              name="descricaoChoqueCardiogenico"
              disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>

      {/*<div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField name="hematomaProcedimentoMedico" disabled={formCongelado} color="primary" />
            }
            labelPlacement="end"
            label="Hematoma"
          />
        </div>
        {hematomaProcedimentoMedico && (
          <div className="col-md-8">
            <FastField
              name="descricaoHematoma" disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
            </div>*/}
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="hemopericardio"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Hemopericardio"
          />
        </div>
        {hemopericardio && (
          <div className="col-md-8">
            <FastField
              name="descricaoHemopericardio"
              disabled={formCongelado}
              label="Descreva"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FastField
            name="outrosSucessoProcedimento"
            disabled={formCongelado}
            label="Outros"
            fullWidth
            margin="none"
            variant="outlined"
            multiline
            rows={4}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
