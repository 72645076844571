import React from "react";
import { Typography, TextField, MenuItem } from "@material-ui/core";

export default function ClassificacaoQuadroClinico(props) {
  const { formCongelado } = props;
  const { classificacaoQuadroClinico } = props.formik.values;
  const { setFieldValue } = props.formik;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Quadro clínico
      </Typography>
      <div className="row">
        <div className="col-md-4 py-4">
          <TextField
            select
            label="Classificação do quadro clínico "
            value={classificacaoQuadroClinico}
            fullWidth
            size="small"
            onChange={event => {
              setFieldValue("classificacaoQuadroClinico", event.target.value);
            }}
            variant="outlined"
            disabled={formCongelado}
          >
            <MenuItem key={1} value="1">
              Angina Estável
            </MenuItem>
            <MenuItem key={2} value="2">
              Equivalente isquêmico
            </MenuItem>
            <MenuItem key={3} value="3">
              Angina
            </MenuItem>
          </TextField>
        </div>
      </div>
    </div>
  );
}
