import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import AdicionarLesaoDialog from "../components/AdicionarLesaoDialog";

export default function Guia0014(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { guias0014 } = props.formik.values;
  const [guia0014DialogOpen, setGuia0014DialogOpen] = useState(false);

  const handleGuia0014DialogConfirm = guia0014 => {
    setFieldValue(
      "guias0014",
      guias0014.concat({
        id: uuidv4(),
        ...guia0014,
      }),
    );
    setGuia0014DialogOpen(false);
  };

  const handleGuia0014DialogClose = () => {
    setGuia0014DialogOpen(false);
  };

  const openGuia0014Dialog = () => {
    setGuia0014DialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "guias0014",
      guias0014.filter(guia0014 => {
        return guia0014.id !== id;
      }),
    );
  };

  return (
    <>
      <AdicionarLesaoDialog
        title="Adicionar guia 0,014"
        objetoTitle="Guia 0,014"
        open={guia0014DialogOpen}
        onConfirm={handleGuia0014DialogConfirm}
        onClose={handleGuia0014DialogClose}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Guia 0,014
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openGuia0014Dialog}
              disabled={formCongelado}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lado</TableCell>
                    <TableCell>Lesão</TableCell>
                    <TableCell>Guia 0,014</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guias0014.map(guia0014 => (
                    <TableRow key={guia0014.id}>
                      <TableCell>{guia0014.lado}</TableCell>
                      <TableCell>{guia0014.local}</TableCell>
                      <TableCell>{guia0014.objeto}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="Delete"
                          disabled={formCongelado}
                          onClick={() => {
                            handleDelete(guia0014.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
