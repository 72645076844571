import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import FastField from "../../../components/FastField";
import SwitchField from "../../../components/SwitchField";

export default function Alergias(props) {
  const { formCongelado } = props;
  const { setFieldValue, setFieldTouched } = props.formik;
  const {
    possuiAlergia,
    alergiaMedicamentosa,
    dessensibilizacaoIodo,
    alergiaAlimentar,
  } = props.formik.values;

  useEffect(() => {
    if (!possuiAlergia) {
      setFieldValue("alergiaMedicamentosa", false);
      setFieldTouched("alergiaMedicamentosa", false);

      setFieldValue("alergiaAlimentar", false);
      setFieldTouched("alergiaAlimentar", false);
    }
  }, [possuiAlergia, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!alergiaMedicamentosa) {
      setFieldValue("alergiaMedicamentosaDescricao", "");
      setFieldTouched("alergiaMedicamentosaDescricao", "");

      setFieldValue("dessensibilizacaoIodo", false);
      setFieldTouched("dessensibilizacaoIodo", false);
    }
  }, [alergiaMedicamentosa, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!dessensibilizacaoIodo) {
      setFieldValue("diasDessensibilizacaoIodo", "");
      setFieldTouched("diasDessensibilizacaoIodo", "");
    }
  }, [dessensibilizacaoIodo, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (!alergiaAlimentar) {
      setFieldValue("alergiaAlimentarDescricao", "");
      setFieldTouched("alergiaAlimentarDescricao", "");
    }
  }, [alergiaAlimentar, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Alergias
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="possuiAlergia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Possui alergia"
          />
        </div>
      </div>
      {possuiAlergia && (
        <>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="alergiaMedicamentosa"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Alergia medicamentosa"
              />
            </div>
            {alergiaMedicamentosa && (
              <>
                <div className="col-md-3">
                  <FastField
                    name="alergiaMedicamentosaDescricao"
                    disabled={formCongelado}
                    label="Alergia medicamentosa descrição"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                    required
                  />
                </div>
                <div className="col-md-3">
                  <FormControlLabel
                    control={
                      <SwitchField
                        name="dessensibilizacaoIodo"
                        disabled={formCongelado}
                        color="primary"
                      />
                    }
                    labelPlacement="end"
                    label="Dessensibilização Iodo"
                  />
                </div>
                {dessensibilizacaoIodo && (
                  <div className="col-md-3">
                    <FastField
                      name="diasDessensibilizacaoIodo"
                      disabled={formCongelado}
                      type="number"
                      label="Dias dessensibilização Iodo"
                      fullWidth
                      margin="none"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormControlLabel
                control={
                  <SwitchField
                    name="alergiaAlimentar"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Alergia alimentar"
              />
            </div>
            {alergiaAlimentar && (
              <div className="col-md-3">
                <FastField
                  name="alergiaAlimentarDescricao"
                  disabled={formCongelado}
                  label="Alergia alimentar descrição"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
