import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";

import CateterismoDialog from "../components/CateterismoDialog";

export default function Cateterismo(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { cateterismos } = props.formik.values;
  const [cateterismoDialogData, setCateterismoDialogData] = useState(null);
  const [cateterismoDialogOpen, setCateterismoDialogOpen] = useState(false);

  const openCateterismoDialog = () => {
    setCateterismoDialogData(null);
    setCateterismoDialogOpen(true);
  };

  const handleCateterismoInsert = cateterismo => {
    setFieldValue("cateterismos", cateterismos.concat(cateterismo));
    setCateterismoDialogOpen(false);
  };

  const handleCateterismoUpdate = newCateterismo => {
    setFieldValue(
      "cateterismos",
      cateterismos.map(cateterismo => {
        if (cateterismo.id === newCateterismo.id) {
          return newCateterismo;
        }

        return cateterismo;
      }),
    );
    setCateterismoDialogOpen(false);
  };

  const handleCateterismoDialogClose = () => {
    setCateterismoDialogOpen(false);
  };

  const handleDelete = id => {
    setFieldValue(
      "cateterismos",
      cateterismos.filter(cateterismo => {
        return cateterismo.id !== id;
      }),
    );
  };

  return (
    <>
      <CateterismoDialog
        open={cateterismoDialogOpen}
        data={cateterismoDialogData}
        onInsert={handleCateterismoInsert}
        onUpdate={handleCateterismoUpdate}
        onClose={handleCateterismoDialogClose}
        congelado={formCongelado}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6" className="mb-2">
          Cateterismo
        </Typography>
        <div className="row">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openCateterismoDialog}
              disabled={formCongelado}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lado</TableCell>
                    <TableCell>Vaso</TableCell>
                    <TableCell>Porcentagem</TableCell>
                    <TableCell>Ângulo ramo lateral</TableCell>
                    <TableCell>Medina</TableCell>
                    <TableCell>Fluxo TIMI</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cateterismos.map(item => (
                    <TableRow key={item.id}>
                      <TableCell>{item.lado}</TableCell>
                      <TableCell>{item.local}</TableCell>
                      <TableCell>{item.porcentagem}</TableCell>
                      <TableCell>{item.anguloRamoLateral}</TableCell>
                      <TableCell>{item.medina}</TableCell>
                      <TableCell>{item.fluxoTimi}</TableCell>
                      <TableCell align="right">
                        <div style={{ width: "100%", display: "inline" }}>
                          <IconButton
                            aria-label="Visibility"
                            onClick={() => {
                              setCateterismoDialogData(
                                cateterismos.find(
                                  cateterismo => cateterismo.id === item.id,
                                ),
                              );
                              setCateterismoDialogOpen(true);
                            }}
                          >
                            <Visibility />
                          </IconButton>
                          <IconButton
                            disabled={formCongelado}
                            aria-label="Delete"
                            onClick={() => {
                              handleDelete(item.id);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
