import React from "react";
import { Typography } from "@material-ui/core";

import FastField from "../../../components/FastField";

export default function Alta(props) {
  const { formCongelado } = props;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Alta
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FastField
            name="horarioAlta"
            disabled={formCongelado}
            type="time"
            label="Horário da alta"
            fullWidth
            margin="none"
            variant="outlined"
            size="small"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FastField
            name="evolucaoEnfermagem"
            disabled={formCongelado}
            label="Evolução de enfermagem"
            placeholder="Descrição de intercorrências, cuidados intra e pós procedimentos e proposta de continuidade de tratamento."
            fullWidth
            multiline
            rows={4}
            margin="normal"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          * Urgência – Dor torácica tempo porta-balão
        </div>
      </div>
    </div>
  );
}
