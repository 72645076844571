import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function ExamesSala(props) {
  const { formCongelado } = props;
  const { examesOutrosSala } = props.formik.values;
  const { setFieldValue, setFieldTouched } = props.formik;

  useEffect(() => {
    if (!examesOutrosSala) {
      setFieldValue("descExamesOutrosSala", "");
      setFieldTouched("descExamesOutrosSala", false);
    }
  }, [examesOutrosSala, setFieldTouched, setFieldValue]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Exames na sala
      </Typography>
      <div className="row">
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="gasometria"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Gasometria"
          />
        </div>
        <div className="col-md-3">
          <FormControlLabel
            control={
              <SwitchField
                name="examesOutrosSala"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Outros"
          />
        </div>
        {examesOutrosSala && (
          <div className="col-md-6">
            <FastField
              name="descExamesOutrosSala"
              disabled={formCongelado}
              label="Descrição dos outros exames"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
    </div>
  );
}
