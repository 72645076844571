import React from "react";
import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

export default function EscalaAldrette(props) {
  const { formCongelado } = props;
  const {
    kroulikAtividadeMuscular15,
    kroulikAtividadeMuscular30,
    kroulikAtividadeMuscular60,
    kroulikAtividadeMuscular2h,
    kroulikRespiracao15,
    kroulikRespiracao30,
    kroulikRespiracao60,
    kroulikRespiracao2h,
    kroulikCirculacao15,
    kroulikCirculacao30,
    kroulikCirculacao60,
    kroulikCirculacao2h,
    kroulikConsciencia15,
    kroulikConsciencia30,
    kroulikConsciencia60,
    kroulikConsciencia2h,
    kroulikSaturacaoOxigenio15,
    kroulikSaturacaoOxigenio30,
    kroulikSaturacaoOxigenio60,
    kroulikSaturacaoOxigenio2h,
  } = props.formik.values;

  const { setFieldValue } = props.formik;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Escala de Aldrette e Kroulik
      </Typography>
      <div className="row">
        <div className="col-lg-12">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    Escala de Aldrette e Kroulik
                  </TableCell>
                  <TableCell>15'</TableCell>
                  <TableCell>30'</TableCell>
                  <TableCell>60'</TableCell>
                  <TableCell>2h</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={3}>Atividade Muscular</TableCell>
                  <TableCell>Movimenta os quatro membros</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikAtividadeMuscular15"
                      disabled={formCongelado}
                      value={kroulikAtividadeMuscular15}
                      onChange={event => {
                        setFieldValue(
                          "kroulikAtividadeMuscular15",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikAtividadeMuscular30"
                      disabled={formCongelado}
                      value={kroulikAtividadeMuscular30}
                      onChange={event => {
                        setFieldValue(
                          "kroulikAtividadeMuscular30",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikAtividadeMuscular60"
                      disabled={formCongelado}
                      value={kroulikAtividadeMuscular60}
                      onChange={event => {
                        setFieldValue(
                          "kroulikAtividadeMuscular60",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikAtividadeMuscular2h"
                      disabled={formCongelado}
                      value={kroulikAtividadeMuscular2h}
                      onChange={event => {
                        setFieldValue(
                          "kroulikAtividadeMuscular2h",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Movimenta dois membros</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Incapaz de mover os membros voluntariamente ou sob pressão
                  </TableCell>
                  <TableCell>0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell rowSpan={3}>Respiração</TableCell>
                  <TableCell>
                    Capaz de respirar profundamente ou tossir livremente
                  </TableCell>
                  <TableCell>2</TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikRespiracao15"
                      disabled={formCongelado}
                      value={kroulikRespiracao15}
                      onChange={event => {
                        setFieldValue(
                          "kroulikRespiracao15",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikRespiracao30"
                      disabled={formCongelado}
                      value={kroulikRespiracao30}
                      onChange={event => {
                        setFieldValue(
                          "kroulikRespiracao30",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikRespiracao60"
                      disabled={formCongelado}
                      value={kroulikRespiracao60}
                      onChange={event => {
                        setFieldValue(
                          "kroulikRespiracao60",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikRespiracao2h"
                      disabled={formCongelado}
                      value={kroulikRespiracao2h}
                      onChange={event => {
                        setFieldValue(
                          "kroulikRespiracao2h",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dispnéia ou limitação da respiração</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Apnéia</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell rowSpan={3}>Circulação</TableCell>
                  <TableCell>PA 20% do nível pré-anestésico</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikCirculacao15"
                      disabled={formCongelado}
                      value={kroulikCirculacao15}
                      onChange={event => {
                        setFieldValue(
                          "kroulikCirculacao15",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikCirculacao30"
                      disabled={formCongelado}
                      value={kroulikCirculacao30}
                      onChange={event => {
                        setFieldValue(
                          "kroulikCirculacao30",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikCirculacao60"
                      disabled={formCongelado}
                      value={kroulikCirculacao60}
                      onChange={event => {
                        setFieldValue(
                          "kroulikCirculacao60",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikCirculacao2h"
                      disabled={formCongelado}
                      value={kroulikCirculacao2h}
                      onChange={event => {
                        setFieldValue(
                          "kroulikCirculacao2h",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PA 20% A 49% do nível pré-anestésico</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PA 50% do nível pré-anestésico</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell rowSpan={3}>Consciência</TableCell>
                  <TableCell>Lúcido e orientado no tempo e no espaço</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikConsciencia15"
                      disabled={formCongelado}
                      value={kroulikConsciencia15}
                      onChange={event => {
                        setFieldValue(
                          "kroulikConsciencia15",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikConsciencia30"
                      disabled={formCongelado}
                      value={kroulikConsciencia30}
                      onChange={event => {
                        setFieldValue(
                          "kroulikConsciencia30",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikConsciencia60"
                      disabled={formCongelado}
                      value={kroulikConsciencia60}
                      onChange={event => {
                        setFieldValue(
                          "kroulikConsciencia60",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikConsciencia2h"
                      disabled={formCongelado}
                      value={kroulikConsciencia2h}
                      onChange={event => {
                        setFieldValue(
                          "kroulikConsciencia2h",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Desperta se solicitado</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Não responde</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell rowSpan={3}>Saturação de oxigênio</TableCell>
                  <TableCell>
                    Capaz de manter SO² &gt; 92%, respirando em ar ambiente
                  </TableCell>
                  <TableCell>2</TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikSaturacaoOxigenio15"
                      disabled={formCongelado}
                      value={kroulikSaturacaoOxigenio15}
                      onChange={event => {
                        setFieldValue(
                          "kroulikSaturacaoOxigenio15",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikSaturacaoOxigenio30"
                      disabled={formCongelado}
                      value={kroulikSaturacaoOxigenio30}
                      onChange={event => {
                        setFieldValue(
                          "kroulikSaturacaoOxigenio30",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikSaturacaoOxigenio60"
                      disabled={formCongelado}
                      value={kroulikSaturacaoOxigenio60}
                      onChange={event => {
                        setFieldValue(
                          "kroulikSaturacaoOxigenio60",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <TextField
                      select
                      label="Total"
                      name="kroulikSaturacaoOxigenio2h"
                      disabled={formCongelado}
                      value={kroulikSaturacaoOxigenio2h}
                      onChange={event => {
                        setFieldValue(
                          "kroulikSaturacaoOxigenio2h",
                          event.target.value,
                        );
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem key={0} value="0">
                        0
                      </MenuItem>
                      <MenuItem key={1} value="1">
                        1
                      </MenuItem>
                      <MenuItem key={2} value="2">
                        2
                      </MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Necessita de 0² para manter a saturação &gt; 90 %
                  </TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SO² &lt; 90% com suplementação de 0²</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3} align="right">
                    <b>Somatório:</b>
                  </TableCell>
                  <TableCell>
                    {parseInt(kroulikAtividadeMuscular15 || 0) +
                      parseInt(kroulikRespiracao15 || 0) +
                      parseInt(kroulikCirculacao15 || 0) +
                      parseInt(kroulikConsciencia15 || 0) +
                      parseInt(kroulikSaturacaoOxigenio15 || 0)}
                  </TableCell>
                  <TableCell>
                    {parseInt(kroulikAtividadeMuscular30 || 0) +
                      parseInt(kroulikRespiracao30 || 0) +
                      parseInt(kroulikCirculacao30 || 0) +
                      parseInt(kroulikConsciencia30 || 0) +
                      parseInt(kroulikSaturacaoOxigenio30 || 0)}
                  </TableCell>
                  <TableCell>
                    {parseInt(kroulikAtividadeMuscular60 || 0) +
                      parseInt(kroulikRespiracao60 || 0) +
                      parseInt(kroulikCirculacao60 || 0) +
                      parseInt(kroulikConsciencia60 || 0) +
                      parseInt(kroulikSaturacaoOxigenio60 || 0)}
                  </TableCell>
                  <TableCell>
                    {parseInt(kroulikAtividadeMuscular2h || 0) +
                      parseInt(kroulikRespiracao2h || 0) +
                      parseInt(kroulikCirculacao2h || 0) +
                      parseInt(kroulikConsciencia2h || 0) +
                      parseInt(kroulikSaturacaoOxigenio2h || 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="subtitle1" gutterBottom className="ml-3">
              {
                "IMPORTANTE: Condição para alta clínica do paciente de URPA: Somatório da Escala de Aldrette e Kroulik deverá estar entre de 8 a 10."
              }
            </Typography>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
