import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_BASE_URI}/oauth/token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = `${process.env.REACT_APP_API_BASE_URI}/personal-info`;

export function login(email, password) {
  const headers = {
    Accept: "application/json",
  };
  const loginRequisition = axios({
    method: "post",
    url: LOGIN_URL,
    headers: headers,
    data: {
      grant_type: "password",
      username: email,
      password: password,
      client_id: parseInt(process.env.REACT_APP_API_CLIENT_ID),
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    },
  });
  return loginRequisition;
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getAuthenticatedApi() {
  return axios;
}
