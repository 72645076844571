import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import SwitchField from "../../../components/SwitchField";
import AdicionarLesaoDialog from "../components/AdicionarLesaoDialog";

export default function MetodoAdjunto(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { metodosAdjunto } = props.formik.values;
  const [metodoAdjuntoDialogOpen, setMetodoAdjuntoDialogOpen] = useState(false);

  const optionsLabel = [
    { value: "1", label: "IVUS" },
    { value: "2", label: "OCT" },
    { value: "3", label: "FFR/iFR" },
    { value: "4", label: "Cutting" },
    { value: "5", label: "Rotablator" },
    { value: "6", label: "Microcateter" },
    { value: "7", label: "Mother in child" },
    { value: "8", label: "Aspirador de trombo" },
  ];

  const handleMetodoAdjuntoDialogConfirm = metodoAdjunto => {
    setFieldValue(
      "metodosAdjunto",
      metodosAdjunto.concat({
        id: uuidv4(),
        ...metodoAdjunto,
      }),
    );
    setMetodoAdjuntoDialogOpen(false);
  };

  const handleMetodoAdjuntoDialogClose = () => {
    setMetodoAdjuntoDialogOpen(false);
  };

  const openMetodoAdjuntoDialog = () => {
    setMetodoAdjuntoDialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "metodosAdjunto",
      metodosAdjunto.filter(metodoAdjunto => {
        return metodoAdjunto.id !== id;
      }),
    );
  };

  return (
    <>
      <AdicionarLesaoDialog
        title="Adicionar método adjunto"
        objetoTitle="Método adjunto"
        open={metodoAdjuntoDialogOpen}
        onConfirm={handleMetodoAdjuntoDialogConfirm}
        onClose={handleMetodoAdjuntoDialogClose}
        objetoOptions={optionsLabel}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          Método adjunto
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openMetodoAdjuntoDialog}
              disabled={formCongelado}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lado</TableCell>
                    <TableCell>Lesão</TableCell>
                    <TableCell>Método Adjunto</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metodosAdjunto.map(metodoAdjunto => (
                    <TableRow key={metodoAdjunto.id}>
                      <TableCell>{metodoAdjunto.lado}</TableCell>
                      <TableCell>{metodoAdjunto.local}</TableCell>
                      <TableCell>
                        {
                          optionsLabel.find(option => {
                            return option.value === metodoAdjunto.objeto;
                          }).label
                        }
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          disabled={formCongelado}
                          aria-label="Delete"
                          onClick={() => {
                            handleDelete(metodoAdjunto.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="row  mt-2">
          <div className="col-md-4">
            <FormControlLabel
              control={
                <SwitchField
                  name="preDilatacao"
                  color="primary"
                  disabled={formCongelado}
                />
              }
              labelPlacement="end"
              label="Pré-dilatação?"
            />
          </div>
          <div className="col-md-4">
            <FormControlLabel
              control={
                <SwitchField
                  name="posDilatacao"
                  color="primary"
                  disabled={formCongelado}
                />
              }
              labelPlacement="end"
              label="Pós-dilatação?"
            />
          </div>
        </div>
      </div>
    </>
  );
}
