import React from "react";
import { Typography } from "@material-ui/core";

import leftDominance from "./../../../assets/img/LeftDominance.png";
import rightDominance from "./../../../assets/img/RightDominance.png";

export default function Lesao(props) {
  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6" className="mb-2">
        Lesão
      </Typography>
      <div className="row">
        <div className="col-md-6">
          <img
            src={leftDominance}
            alt="Domínio esquerdo"
            className="rounded mx-auto d-block"
          />
        </div>
        <div className="col-md-6">
          <img
            src={rightDominance}
            alt="Domínio direito"
            className="rounded mx-auto d-block"
          />
        </div>
      </div>
    </div>
  );
}
