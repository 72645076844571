/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from "react";
import {
  Backdrop,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import moment from "moment";

import LoadingBox from "../../components/utils/LoadingBox";
import { getAtendimentos } from "../services/icesApi";
import Filtros from "./components/Filtros";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

function shortText(text, count) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

export default function HomePage() {
  const classes = useStyles();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [atendimentos, setAtendimentos] = useState([]);
  const [totalAtendimentos, setTotalAtendimentos] = useState(0);
  const [atendimentosTablePageCount, setAtendimentosTablePageCount] = useState(
    0,
  );
  const [atendimentosTablePageIndex, setAtendimentosTablePageIndex] = useState(
    0,
  );
  const [atendimentosTablePageSize, setAtendimentosTablePageSize] = useState(
    15,
  );

  const fetchAtendimentos = useCallback(
    (status, protocolo, paciente, dataAtendimento, pageIndex, pageSize) => {
      setLoading(true);
      getAtendimentos({
        status,
        protocolo,
        paciente,
        dataAtendimento,
        pageIndex,
        pageSize,
      })
        .then(({ data }) => {
          setTotalAtendimentos(data.meta.total);
          setAtendimentosTablePageCount(Math.ceil(data.meta.total / pageSize));

          setAtendimentos(
            data.data.map(atendimento => {
              return {
                id: atendimento.id,
                protocolo: atendimento.nrControleClinux,
                dtAtendimento: moment(atendimento.dtAtendimentoClinux).format(
                  "DD/MM/YYYY",
                ),
                paciente: atendimento.formPaciente.nome,
                procedimento: shortText(atendimento.procedimento, 40),
                etapa: atendimento.etapa,
                status: atendimento.status,
              };
            }),
          );
        })
        .catch(() => {
          // TODO: Message error
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  const handleFetchAtendimentos = useCallback(
    ({ protocolo, paciente, dataAtendimento, resetarIndice }) => {
      fetchAtendimentos(
        currentTab === 1 ? 1 : 2,
        protocolo,
        paciente,
        dataAtendimento,
        atendimentosTablePageIndex + 1,
        atendimentosTablePageSize,
      );

      if (resetarIndice) {
        setAtendimentosTablePageIndex(0);
      }
    },
    [
      atendimentosTablePageIndex,
      atendimentosTablePageSize,
      currentTab,
      fetchAtendimentos,
    ],
  );

  const handleChangePage = (_, newPage) => {
    setAtendimentosTablePageIndex(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setAtendimentosTablePageSize(Number(event.target.value));
  };

  const handleChangeTab = tab => {
    setCurrentTab(tab);
    setAtendimentosTablePageIndex(0);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Atendimento",
        accessor: "id",
      },
      {
        Header: "Protocolo",
        accessor: "protocolo",
      },
      {
        Header: "Dt. Atendimento",
        accessor: "dtAtendimento",
      },
      {
        Header: "Paciente",
        accessor: "paciente",
      },
      {
        Header: "Procedimento",
        accessor: "procedimento",
      },
      {
        Header: "Etapa",
        accessor: "etapa",
        Cell: ({ row: { values } }) => {
          return (
            <Badge variant={values.etapa.cor}>{values.etapa.descricao}</Badge>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { values } }) => {
          return (
            <Badge variant={values.status.cor}>{values.status.descricao}</Badge>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: atendimentos,
      initialState: {
        pageIndex: atendimentosTablePageIndex,
        pageSize: atendimentosTablePageSize,
      },
      manualPagination: true,
      pageCount: atendimentosTablePageCount,
    },
    usePagination,
  );

  const getCellProps = cellInfo => ({
    onClick: () => {
      if (cellInfo.column.id !== "selection") {
        history.push(`/atendimentos?id=${cellInfo.row.original.id}`);
      }
    },
    style: {
      cursor: cellInfo.column.id !== "selection" ? "pointer" : undefined,
    },
  });

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <LoadingBox />
      </Backdrop>

      <div className="card card-custom">
        <div className="card-header card-header-tabs-line">
          <ul
            className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
            data-remember-tab="tab_id"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={`nav-link ${currentTab === 1 && `active`}`}
                data-toggle="tab"
                onClick={() => handleChangeTab(1)}
              >
                Em aberto
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${currentTab === 2 && `active`}`}
                data-toggle="tab"
                onClick={() => handleChangeTab(2)}
              >
                Finalizados
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <Typography variant="h6">Filtros:</Typography>
          <Filtros onChange={handleFetchAtendimentos} />
          <Typography variant="h6" className="mt-5">
            Atendimentos:
          </Typography>
          <div className="tab-content pt-3">
            <div className={`tab-pane active`}>
              <TableContainer>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map(headerGroup => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <TableCell {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return (
                              <TableCell
                                {...cell.getCellProps([getCellProps(cell)])}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[15, 30, 50]}
                  component="div"
                  count={totalAtendimentos}
                  rowsPerPage={atendimentosTablePageSize}
                  page={atendimentosTablePageIndex}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
