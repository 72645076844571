import axios from "axios";

const BPM_URL = `${process.env.REACT_APP_API_BASE_URI}`;

export function getUsers({ query, pageIndex, pageSize }) {
  return axios.get(`${BPM_URL}/users`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getUser(id) {
  return axios.get(`${BPM_URL}/users/${id}`);
}

export function storeUser(name, email, password, active, groups) {
  return axios.post(`${BPM_URL}/users`, {
    name,
    email,
    password,
    active,
    groups,
  });
}

export function updateUser(id, { name, email, password, active, groups }) {
  return axios.put(`${BPM_URL}/users/${id}`, {
    name,
    email,
    password,
    active,
    groups,
  });
}

export function renewPassword({ password, newPassword }) {
  return axios.put(`${BPM_URL}/users/renewPassword`, {
    password,
    newPassword,
  });
}

export function getGroups({ query, pageIndex, pageSize }) {
  return axios.get(`${BPM_URL}/groups`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getGroup(id) {
  return axios.get(`${BPM_URL}/groups/${id}`);
}

export function storeGroup(name, description) {
  return axios.post(`${BPM_URL}/groups`, {
    name,
    description,
  });
}

export function updateGroup(id, { name, description }) {
  return axios.put(`${BPM_URL}/groups/${id}`, {
    name,
    description,
  });
}

export function getAtendimentos({
  status,
  protocolo,
  paciente,
  dataAtendimento: data,
  pageIndex,
  pageSize,
}) {
  return axios.get(`${BPM_URL}/atendimentos`, {
    params: {
      status,
      protocolo,
      paciente,
      data,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getAtendimento(id) {
  return axios.get(`${BPM_URL}/atendimentos/${id}`);
}

export function storeAtendimento(data) {
  return axios.post(`${BPM_URL}/atendimentos`, data);
}
export function reabrirAtendimento(idAtendimento) {
  return axios.post(`${BPM_URL}/atendimentos/reabrir`, {
    idAtendimento: idAtendimento,
  });
}

export function getTiposCancer() {
  return axios.get(`${BPM_URL}/tipos-cancer`);
}
