import React, { useEffect } from "react";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function ChecklistCirurgiaSegura(props) {
  const { formCongelado } = props;
  const {
    usoClopidogrel,
    jejum,
    tricotomia,
    tricotomiaDone,
    tricotomiaLocal,
    posicaoCorretaProc,
  } = props.formik.values;

  const { setFieldValue, setFieldTouched } = props.formik;

  useEffect(() => {
    if (!usoClopidogrel) {
      setFieldValue("dosagemClopidrogel", "");
      setFieldTouched("dosagemClopidrogel", false);
    }
  }, [usoClopidogrel, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (!tricotomia) {
      setFieldValue("tricotomiaDone", "");
      setFieldValue("tricotomiaLocal", "");
      setFieldTouched("tricotomiaDone", false);
      setFieldTouched("tricotomiaLocal", false);
    }
  }, [tricotomia, setFieldValue, setFieldTouched]);

  const hasTricotomiaDoneErrors =
    !!props.formik.touched.tricotomiaDone &&
    !!props.formik.errors.tricotomiaDone;

  const hasTricotomiaLocalErrors =
    !!props.formik.touched.tricotomiaLocal &&
    !!props.formik.errors.tricotomiaLocal;

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Checklist cirurgia segura
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="identificacaoPaciente"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Identificação do paciente (providenciar imediatamente caso não esteja de pulseira)"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="confirmacaoProcPaciente"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Confirmação do procedimento pelo paciente"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="termoConsentimentoLivre"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Termo de consentimento livre e esclarecido do procedimento"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="termoConsentimentoAnestesico"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Termo de consentimento anestésico"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="historicoAlergia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Histórico de alergia (se sim, paciente devidamente identificado)"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="kitProcDisponivel"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Kit de procedimento cirurgico disponível"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="monitorizacaoInstalada"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Monitorização instalada (poligrafo, oxímetro, pressão invasiva ou não invasiva)"
          />
        </div>
        <div className="col-md-4">
          <TextField
            select
            label="Posição correta para o procedimento"
            name="posicaoCorretaProc"
            disabled={formCongelado}
            value={posicaoCorretaProc}
            onChange={event => {
              setFieldValue("posicaoCorretaProc", event.target.value);
            }}
            fullWidth
            size="small"
            variant="outlined"
          >
            <MenuItem key={1} value="1">
              Dorsal
            </MenuItem>
            <MenuItem key={2} value="2">
              Prona
            </MenuItem>
            <MenuItem key={3} value="3">
              Outras
            </MenuItem>
          </TextField>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="usoClopidogrel"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Em uso de clopidogrel"
          />
        </div>
        {usoClopidogrel && (
          <div className="col-md-4">
            <FastField
              name="dosagemClopidrogel"
              disabled={formCongelado}
              label="Dosagem clopidrogel"
              type="number"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="jejum"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Jejum"
          />
        </div>
        {jejum && (
          <div className="col-md-4">
            <FastField
              name="horasJejum"
              disabled={formCongelado}
              label="Horas jejum"
              type="number"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="tricotomia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Tricotomia"
          />
        </div>
        {tricotomia && (
          <>
            <div className="col-md-4">
              <TextField
                select
                label="Tricotomia feita a"
                name="tricotomiaDone"
                disabled={formCongelado}
                value={tricotomiaDone}
                fullWidth
                size="small"
                variant="outlined"
                onChange={event => {
                  setFieldValue("tricotomiaDone", event.target.value);
                }}
                required
                error={hasTricotomiaDoneErrors}
                helperText={
                  hasTricotomiaDoneErrors
                    ? props.formik.errors.tricotomiaDone
                    : null
                }
              >
                <MenuItem key={1} value="1">
                  {"a mais de 2h"}
                </MenuItem>
                <MenuItem key={2} value="2">
                  {"a menos de 2h"}
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-4">
              <TextField
                select
                label="Tricotomia feita em"
                name="tricotomiaLocal"
                disabled={formCongelado}
                value={tricotomiaLocal}
                fullWidth
                size="small"
                variant="outlined"
                onChange={event => {
                  setFieldValue("tricotomiaLocal", event.target.value);
                }}
                required
                error={hasTricotomiaLocalErrors}
                helperText={
                  hasTricotomiaLocalErrors
                    ? props.formik.errors.tricotomiaLocal
                    : null
                }
              >
                <MenuItem key={1} value="1">
                  Sala cirúrgica
                </MenuItem>
                <MenuItem key={2} value="2">
                  Pré – procedimento repouso
                </MenuItem>
              </TextField>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
