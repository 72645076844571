import React from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function Precaucao(props) {
  const { formCongelado } = props;
  
  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Precaução
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="precaucaoPadrao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Padrão"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="precaucaoContato"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Contato"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="precaucaoAerosol"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Aerosol"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="precaucaoGoticula"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Gotícula"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="precaucaoReverso"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Reverso"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FastField
            name="precaucaoObservacao"
            disabled={formCongelado}
            label="Observações"
            fullWidth
            margin="none"
            multiline
            rows={4}
            variant="outlined"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
