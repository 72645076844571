import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export default function Angioplastia(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { angioplastia } = props.formik.values;

  const angioplastiaOptions = [
    { label: "Simples", value: 1 },
    { label: "Complexa (bifurcação/tortuosidade/calcificação)", value: 2 },
    { label: "Oclusão crônica", value: 3 },
  ];

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6" className="mb-2">
        Angioplastia
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <Autocomplete
            options={angioplastiaOptions}
            disabled={formCongelado}
            value={
              angioplastiaOptions.find(x => x.value === angioplastia) || null
            }
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            onChange={(_, value) => {
              setFieldValue("angioplastia", value?.value || "");
            }}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                label="Tipo angioplastia"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
