export const actionTypes = {
  PushSnackbar: "[PushSnackbar] Action",
};

const initialState = {
  open: false,
  message: "",
  type: "success",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PushSnackbar: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  pushSnackbar: snackbar => ({
    type: actionTypes.PushSnackbar,
    payload: snackbar,
  }),
};
