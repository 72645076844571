/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { object, string, number } from "yup";
import moment from "moment";

export default function HeparinaDialog({ open, onConfirm, onClose }) {
  const [errors, setErrors] = useState(null);
  const [tipo, setTipo] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [hora, setHora] = useState("");

  const validateSchema = object({
    tipo: string().required(),
    quantidade: number()
      .integer()
      .required(),
    hora: string()
      .required()
      .test("is-time", "o valor não corresponde a uma hora válida", value => {
        return moment(value, "HH:mm", true).isValid();
      }),
  });

  const handleConfirm = () => {
    const heparina = {
      tipo: tipo,
      quantidade: quantidade,
      hora: hora,
    };

    validateSchema
      .validate(heparina, { abortEarly: false })
      .then(() => {
        onConfirm(heparina);
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  useEffect(() => {
    setErrors([]);
    setTipo("");
    setQuantidade("");
    setHora("");
  }, [open]);

  const validateIfHasError = (fieldName, value) => {
    if (errors?.[fieldName]) {
      validateSchema.validateAt(fieldName, { [fieldName]: value }).then(() => {
        const newErrors = errors;
        delete newErrors[fieldName];

        setErrors(newErrors);
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Adicionar heparina</DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-4">
            <TextField
              select
              required
              label="Tipo heparina"
              margin="normal"
              fullWidth
              size="small"
              onChange={event => {
                validateIfHasError("tipo", event.target.value);
                setTipo(event.target.value);
              }}
              error={errors?.lado ? true : false}
              helperText={errors?.lado}
            >
              <MenuItem key={1} value={"F"}>
                Fracionada
              </MenuItem>
              <MenuItem key={2} value={"NF"}>
                Não-fracionada
              </MenuItem>
            </TextField>
          </div>
          <div className="col-md-4">
            <TextField
              required
              type="number"
              label="Quantidade (UI)"
              margin="normal"
              fullWidth
              onChange={event => {
                validateIfHasError("quantidade", event.target.value);
                setQuantidade(event.target.value);
              }}
              error={errors?.quantidade ? true : false}
              helperText={errors?.quantidade}
            />
          </div>
          <div className="col-md-4">
            <TextField
              required
              type="time"
              label="Hora"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => {
                validateIfHasError("hora", event.target.value);
                setHora(event.target.value);
              }}
              error={errors?.hora ? true : false}
              helperText={errors?.hora}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
