import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function ChecklistAdmissao(props) {
  const { formCongelado } = props;
  const { setFieldValue, setTouched, setValues, values } = props.formik;
  const { checklistAdmissaoNaoAplicado } = props.formik.values;

  useEffect(() => {
    if (checklistAdmissaoNaoAplicado) {
      setValues(
        {
          ...values,
          prontuario: false,
          protocoloTransporte: false,
          internadoMais24h: false,
          cuidadosHomeCare: false,
          ecgEco: false,
          proteseEntregue: false,
          clAdmissaoOutros: "",
        },
        false,
      );

      setTouched(
        {
          ...values,
          prontuario: false,
          protocoloTransporte: false,
          internadoMais24h: false,
          cuidadosHomeCare: false,
          ecgEco: false,
          proteseEntregue: false,
          clAdmissaoOutros: false,
        },
        false,
      );
    }
    // eslint-disable-next-line
  }, [checklistAdmissaoNaoAplicado, setFieldValue, setTouched, setValues]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Checklist admissão
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="checklistAdmissaoNaoAplicado"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Não se aplica"
          />
        </div>
      </div>
      {!checklistAdmissaoNaoAplicado && (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="prontuario"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Prontuário"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="protocoloTransporte"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Protocolo de transporte"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="internadoMais24h"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Paciente encontra-se internado há mais de 24h"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="cuidadosHomeCare"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Paciente recebe cuidados de Home Care"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="ecgEco"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="ECG / ECO"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="proteseEntregue"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Prótese entregue"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="internadoUltimos30Dias"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Internado em algum Hospital nos últimos 30 dias"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FastField
                name="clAdmissaoOutros"
                disabled={formCongelado}
                label="Outros"
                fullWidth
                margin="none"
                multiline
                rows={4}
                variant="outlined"
                size="small"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
