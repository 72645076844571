import React, { useEffect } from "react";
import {
  FormControlLabel,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";

import FastField from "../../../components/FastField";
import SwitchField from "../../../components/SwitchField";

export default function Contraste(props) {
  const { formCongelado } = props;
  const { contraste, tipoContraste } = props.formik.values;

  const { setFieldValue, setTouched } = props.formik;

  const hasTipoContrasteErrors =
    !!props.formik.touched.tipoContraste && !!props.formik.errors.tipoContraste;

  useEffect(() => {
    if (!contraste) {
      setFieldValue("tipoContraste", "");
      setTouched({ tipoContraste: false });

      setFieldValue("volumeContraste", "");
      setTouched({ volumeContraste: false });
    }
  }, [contraste, setFieldValue, setTouched]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Contraste / Medicações em uso
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="contraste"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Contraste"
          />
        </div>
      </div>
      {contraste && (
        <div className="row">
          <div className="col-md-4">
            <TextField
              select
              label="Tipo de contraste"
              name="tipoContraste"
              disabled={formCongelado}
              value={tipoContraste}
              fullWidth
              size="small"
              variant="outlined"
              onChange={event => {
                setFieldValue("tipoContraste", event.target.value);
              }}
              required
              error={hasTipoContrasteErrors}
              helperText={
                hasTipoContrasteErrors
                  ? props.formik.errors.tipoContraste
                  : null
              }
            >
              <MenuItem key={1} value="1">
                Iônico
              </MenuItem>
              <MenuItem key={2} value="2">
                Não iônico
              </MenuItem>
            </TextField>
          </div>
          <div className="col-md-4">
            <FastField
              name="volumeContraste"
              disabled={formCongelado}
              type="number"
              label="Volume contraste (ml)"
              fullWidth
              margin="none"
              variant="outlined"
              size="small"
              required
            />
          </div>
        </div>
      )}
    </div>
  );
}
