import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import TcaDialog from "../components/TcaDialog";
import { v4 as uuidv4 } from "uuid";

export default function Tca(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { tcas } = props.formik.values;
  const [tcaDialogOpen, setTcaDialogOpen] = useState(false);

  const handleTcaDialogConfirm = tca => {
    setFieldValue(
      "tcas",
      tcas.concat({
        id: uuidv4(),
        ...tca,
      }),
    );
    setTcaDialogOpen(false);
  };

  const handleTcaDialogClose = () => {
    setTcaDialogOpen(false);
  };

  const openTcaDialog = () => {
    setTcaDialogOpen(true);
  };

  const handleDelete = id => {
    setFieldValue(
      "tcas",
      tcas.filter(tca => {
        return tca.id !== id;
      }),
    );
  };

  return (
    <>
      <TcaDialog
        open={tcaDialogOpen}
        onConfirm={handleTcaDialogConfirm}
        onClose={handleTcaDialogClose}
      />
      <div className={props.className}>
        <Typography gutterBottom variant="h6">
          TCA
        </Typography>
        <div className="row mb-5">
          <div className="col-lg-12">
            <Button
              variant="contained"
              color="default"
              onClick={openTcaDialog}
              disabled={formCongelado}
            >
              Adicionar TCA
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Resultado</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tcas.map(tca => (
                    <TableRow key={tca.id}>
                      <TableCell>{tca.resultado}</TableCell>
                      <TableCell>{tca.hora}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="Delete"
                          disabled={formCongelado}
                          onClick={() => {
                            handleDelete(tca.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
