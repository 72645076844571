import React, { useEffect } from "react";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function Transferencia(props) {
  const { formCongelado } = props;
  const { setFieldValue } = props.formik;
  const { transfDestino } = props.formik.values;

  useEffect(() => {
    if (transfDestino !== 1) {
      setFieldValue("transfProntoSocorroLeito", "");
    }

    if (transfDestino !== 2) {
      setFieldValue("transfUtiLeito", "");
    }

    if (transfDestino !== 3) {
      setFieldValue("transfUcoLeito", "");
    }

    if (transfDestino !== 4) {
      setFieldValue("transfEnfSetor", "");
      setFieldValue("transfEnfLeito", "");
    }
  }, [setFieldValue, transfDestino]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Transferência
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <TextField
            select
            label="Destino"
            name="transfDestino"
            disabled={formCongelado}
            value={transfDestino}
            onChange={event => {
              setFieldValue("transfDestino", event.target.value);
            }}
            fullWidth
            size="small"
            variant="outlined"
            margin="normal"
          >
            <MenuItem key={1} value={1}>
              Pronto socorro
            </MenuItem>
            <MenuItem key={2} value={2}>
              UTI
            </MenuItem>
            <MenuItem key={3} value={3}>
              UCO
            </MenuItem>
            <MenuItem key={4} value={4}>
              Enfermaria / apartamento
            </MenuItem>
            <MenuItem key={5} value={5}>
              Outro hospital
            </MenuItem>
          </TextField>
        </div>
        {transfDestino === 1 && (
          <div className="col-md-4">
            <FastField
              name="transfProntoSocorroLeito"
              disabled={formCongelado}
              label="Leito"
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
            />
          </div>
        )}
        {transfDestino === 2 && (
          <div className="col-md-4">
            <FastField
              name="transfUtiLeito"
              disabled={formCongelado}
              label="Leito"
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
            />
          </div>
        )}
        {transfDestino === 3 && (
          <div className="col-md-4">
            <FastField
              name="transfUcoLeito"
              disabled={formCongelado}
              label="Leito"
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
            />
          </div>
        )}
        {transfDestino === 4 && (
          <>
            <div className="col-md-4">
              <FastField
                name="transfEnfSetor"
                disabled={formCongelado}
                label="Setor"
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
              />
            </div>
            <div className="col-md-4">
              <FastField
                name="transfEnfLeito"
                disabled={formCongelado}
                label="Leito"
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="altaMedicaDisponibilizada"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Alta médica disponibilizada"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="relatorioTransf"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Relatório de transferência devidamente preparado e disponível"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="protocoloTransf"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Protocolo de Transporte devidamente preparado e disponível"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="prescMedicaPreparada"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Prescrição médica devidamente preparada e disponível"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="medicacoesChecadas"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Medicações checadas e trocadas para a transferência, se necessário"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="examesDevolvidosAcompanhante"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Exames do paciente devidamente devolvidos ao acompanhante, com registro em protocolo"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="pertencesPacienteDevolvido"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Pertences do paciente devidamente devolvidos ao acompanhante, com registro em
            protocolo"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="passagemPlantao"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Passagem de plantão pela equipe médica e de enfermagem"
          />
        </div>
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="copiaFolhaAnestesia"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Cópia da folha de anestesia"
          />
        </div>
      </div>
    </div>
  );
}
