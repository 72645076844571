import React from "react";
import { useFastField } from "../hooks/useFastField";
import { TextField } from "@material-ui/core";

export default props => {
  const { label, InputProps } = props;
  const [field, meta] = useFastField(props);
  const hasError = !!meta.error && !!meta.touched;

  return (
    <TextField
      {...props}
      InputProps={{
        ...field,
        ...InputProps,
      }}
      label={label}
      error={hasError}
      helperText={hasError ? meta.error : null}
    />
  );
};
