import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

export default ({ ativo }) => {
  return ativo ? (
    <Alert severity="warning">
      <AlertTitle>Modo de visualização</AlertTitle>
      Você se encontra no modo de visualização. Para editar, reabra o
      formulário.
    </Alert>
  ) : (
    <></>
  );
};
