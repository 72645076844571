import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@material-ui/core";

import SwitchField from "../../../components/SwitchField";
import FastField from "../../../components/FastField";

export default function RiscosAssistenciais(props) {
  const { formCongelado } = props;
  const { setTouched, setValues, values } = props.formik;
  const { riscosAssistenciaisNaoAplicado } = props.formik.values;

  useEffect(() => {
    if (riscosAssistenciaisNaoAplicado) {
      setValues(
        {
          ...values,
          riscoQueda: false,
          riscoBroncospiracao: false,
          riscoFlebite: false,
          riscoInfeccao: false,
          riscoHematoma: false,
          riscoReacaoAlergica: false,
          riscoNefropatiaInduzidaNic: false,
          riscoEstimuloVasoVagal: false,
          riscoHemorragia: false,
          riscoOutros: "",
        },
        false,
      );

      setTouched({
        riscoQueda: false,
        riscoBroncospiracao: false,
        riscoFlebite: false,
        riscoInfeccao: false,
        riscoHematoma: false,
        riscoReacaoAlergica: false,
        riscoNefropatiaInduzidaNic: false,
        riscoEstimuloVasoVagal: false,
        riscoHemorragia: false,
        riscoOutros: false,
      });
    }
    // eslint-disable-next-line
  }, [riscosAssistenciaisNaoAplicado, setTouched, setValues]);

  return (
    <div className={props.className}>
      <Typography gutterBottom variant="h6">
        Riscos Assistenciais
      </Typography>
      <div className="row">
        <div className="col-md-4">
          <FormControlLabel
            control={
              <SwitchField
                name="riscosAssistenciaisNaoAplicado"
                disabled={formCongelado}
                color="primary"
              />
            }
            labelPlacement="end"
            label="Não se aplica"
          />
        </div>
      </div>
      {!riscosAssistenciaisNaoAplicado && (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoQueda"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Quedas"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoBroncospiracao"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Broncospiração"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoFlebite"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Flebite"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoInfeccao"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Infecção"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoHematoma"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Hematoma"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoReacaoAlergica"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Reação Alérgica"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoNefropatiaInduzidaNic"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Nefropatia induzida por Contraste NIC"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoEstimuloVasoVagal"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Estímulo Vaso-Vagal"
              />
            </div>
            <div className="col-md-4">
              <FormControlLabel
                control={
                  <SwitchField
                    name="riscoHemorragia"
                    disabled={formCongelado}
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Hemorragia"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FastField
                name="riscoOutros"
                disabled={formCongelado}
                label="Outros"
                fullWidth
                margin="none"
                multiline
                rows={4}
                variant="outlined"
                size="small"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
